export const {
    REACT_APP_NAME,
    REACT_APP_API_URL,
    REACT_APP_IMAGE_PATH,
    REACT_APP_BACKEND_URL,
    REACT_APP_PDF_PATH,
    REACT_APP_SITE_KEY,
    REACT_APP_SECRET_KEY,
    REACT_APP_SHEETS_PATH,
    // Add other environment variables as needed
} = process.env
