export const language = "ar";
export const staticMessages: {
  [key: string]: {
    Home: string;
    About_Us: string;
    Blog: string;
    Contact_Us: string;
    place_where: string;
    everything: string;
    special: string;
    header: string;
    collection: string;
    shiiping_available: string;
    money_back: string;
    Payment: string;
    payment_process: string;
    online_support: string;
    heading_section: string;
    about_description: string;
    sign_in: string;
    account_details: string;
    personal_details: string;
    total_earning: string;
    total_product: string;
    Total_Sent_Quotes: string;
    General_Report: string;
    Dashboard: string;
    Store: string;
    Cms_pages: string;
    Supplier: string;
    Products: string;
    Categories: string;
    Products_List: string;
    Unit_of_measurement: string;
    Orders: string;
    Support_Ticket: string;
    Logout: string;
    Sales_Report: string;
    Weekly_Top_Seller_Stores: string;
    Transactions: string;
    Store_Info: string;
    CMS_Pages: string;
    Data_Source: string;
    Supplier_Info: string;
    Measurement: string;
    search: string;
    Search: string;
    SR_NO: string;
    Name: string;
    Email: string;
    Company_Name: string;
    Phone_Number: string;
    Status: string;
    Actions: string;
    Title: string;
    Slug: string;
    Cards: string;
    count: string;
    No_of_Products: string;
    Product_name: string;
    Image: string;
    Unit_Name: string;
    Unit_Short_Name: string;
    Order_Id: string;
    Order_ID: string;
    Supplier_Name: string;
    Store_Name: string;
    Amount: string;
    Date: string;
    Sending_Date: string;
    Subject: string;
    Ticket_ID: string;
    View_more: string;
    Edit: string;
    View: string;
    Delete: string;
    Manage_Profile: string;
    Purchase_Request: string;
    Staff: string;
    All_staff: string;
    All_roles: string;
    Branches: string;
    chat: string;
    Chat: string;
    Notification: string;
    Purchase_History: string;
    Recent_Orders: string;
    Purchase_Requests: string;
    Edit_Purchase_Requests: string;
    ID: string;
    Payment_status: string;
    Request_ID: string;
    Requested_Quantity: string;
    Requested_Price: string;
    Options: string;
    Username: string;
    CR_Number: string;
    Phone: string;
    Tax_Number: string;
    IBAN_number: string;
    Photo: string;
    Saved_Address: string;
    Security: string;
    UPDATE_DETAILS: string;
    Password: string;
    Your_password: string;
    Add_New_Address: string;
    Update_Password: string;
    Add_Product: string;
    Showing: string;
    entries: string;
    to: string;
    of: string;
    Add_Staff_Member: string;
    Create_Staff: string;
    Edit_Staff: string;
    Add_new_Permission: string;
    All_Roles: string;
    Cancel: string;
    Close: string;
    Are_you_sure: string;
    DeleteMsg: string;
    Alternate_product: string;
    Offered_Quantity: string;
    Offered_Price: string;
    Offered_deal_at: string;
    Role: string;
    Select: string;
    Save: string;
    Role_Name: string;
    Edit_Products: string;
    View_All_Products: string;
    Delete_Products: string;
    Add_Products: string;
    Manage_Products_Req: string;
    Send_Quotes: string;
    View_Orders: string;
    Assigned_Orders: string;
    Manage_Status: string;
    Manage_Staff: string;
    Add_staff: string;
    View_All_staff: string;
    Delete_staff: string;
    Edit_staff: string;
    Add_Roles: string;
    Edit_Roles: string;
    Delete_Roles: string;
    View_Roles: string;
    Request_Purchase: string;
    Manage_Branches: string;
    Add_Branches: string;
    View_All_Branches: string;
    Edit_Branches: string;
    Delete_Branches: string;
    Ticket: string;
    Add_Ticket: string;
    View_All_support_tickets: string;
    Cancel_Order: string;
    create_Orders: string;
    Create_Role: string;
    Update_Role: string;
    Create_Branch: string;
    Branch_Name: string;
    Address: string;
    Postal_Code: string;
    Country: string;
    State_Province: string;
    State: string;
    City: string;
    Assign_users: string;
    Choose: string;
    Delete_Purchase_Request: string;
    Track_Purchase_Request: string;
    Create_Purchase_Request: string;
    Checkout_Quote: string;
    Permissions: string;
    All_Branches: string;
    Add_Branch: string;
    Edit_Branch: string;
    Upload_File: string;
    CREATE_A_TICKET: string;
    Create_A_Ticket: string;
    Create_Ticket: string;
    choose_file: string;
    View_Supplier_Info: string;
    Edit_Supplier_Info: string;
    Add_Supplier_Info: string;
    Profile_Image: string;
    Submit: string;
    Go_to_supplier_list: string;
    Page: string;
    Blog_Page_Banner: string;
    Heading: string;
    Blog_Heading: string;
    Blog_Description: string;
    Remove: string;
    Blogs_Section: string;
    Save_Changes: string;
    Email_address: string;
    Select_Category_Image: string;
    Category: string;
    Product_Name: string;
    Product_Overview: string;
    Product_Description: string;
    Product_Specification: string;
    Featured_image: string;
    Add_Product_Image: string;
    Go_to_Product_List: string;
    Add_Measurement: string;
    Pending_Purchase_Requests: string;
    Total_Products_Ordered: string;
    Total_Available_Offers: string;
    Default_Shipping_Address: string;
    Shipping_Address: string;
    Shipping: string;
    Created_Date: string;
    Number_of_products: string;
    Branch: string;
    Staff_Member: string;
    Expiry_Date: string;
    Total: string;
    chatMsg: string;
    Requests_Expiry: string;
    Receive_offers_for_the_entire_request: string;
    another_brand: string;
    Quantity: string;
    Subtotal: string;
    Description: string;
    Product_Image: string;
    Product_Images: string;
    Offer_Duration: string;
    Units: string;
    Unit_Property: string;
    Offer_Valid_Till: string;
    My_Account: string;
    Privacy_Policy: string;
    Terms_Conditions: string;
    Popular_Categories: string;
    View_all: string;
    Enter_unit_name: string;
    Enter_unit_short_name: string;
    Enter_category_name: string;
    Enter_product_name: string;
    Enter_CompanyName: string;
    Enter_CR_Number: string;
    Enter_Tax_Number: string;
    Enter_IBAN_number: string;
    Product_over: string;
    Product_desc: string;
    Product_spec: string;
    Faq_heading: string;
    Faq_subHeading: string;
    Page_banner: string;
    About_heading: string;
    About_subHeading: string;
    About_buttonText: string;
    About_butttonLink: string;
    About_collectionValue: string;
    About_collectionText: string;
    About_choose_heading: string;
    About_choose_desc: string;
    About_card_heading: string;
    About_card_position: string;
    About_card_description: string;
    About_card_name: string;
    Enter_Heading: string;
    Enter_description: string;
    Page_title: string;
    Subheading: string;
    Support_title: string;
    Enter_title: string;
    Enter_subTitle: string;
    Footer_text: string;
    Product_count: string;
    Community_title: string;
    community_description: string;
    Enter_date: string;
    Price: string;
    Rating: string;
    Author_name: string;
    Blog_title: string;
    Enter_Slug: string;
    Blog_content: string;
    Designation: string;
    Enter_Customer_name: string;
    Customer_name: string;
    Enter_reviews: string;
    Enter_question: string;
    Enter_answer: string;
    Go_to_store_list: string;
    Add_new_Product: string;
    Add_new_Category: string;
    Remember_me: string;
    Forgot_Password: string;
    Enter_Specification: string;
    Enter_Status: string;
    Enter_Baseprice: string;
    Enter_Price: string;
    Enter_Unitproperty: string;
    Enter_Units: string;
    Enter_Overview: string;
    Type_your_reply: string;
    Enter_Subject: string;
    Enter_Description: string;
    Gram: string;
    Enter_Date: string;
    Enter_Address: string;
    Enter_Branch: string;
    Enter_Rolename: string;
    Enter_Password: string;
    Enter_Phone: string;
    Enter_Email: string;
    Enter_Username: string;
    Measurements: string;
    Edit_Measurement: string;
    Edit_Store_Info: string;
    English: string;
    Urdu: string;
    Arabic: string;
    Sub_heading: string;
    Button_Text: string;
    Button_Link: string;
    Card_Heading: string;
    Card_Description: string;
    Card_Name: string;
    Card_Position: string;
    Go_to_Blogs_Section: string;
    Go_to_Reviews_Section: string;
    Go_to_Faq_Section: string;
    subHeading: string;
    Faq: string;
    Contact_Us_Section: string;
    PageTitle: string;
    Title_For_Support: string;
    Card_Title: string;
    Card_SubTitle: string;
    Review_Section: string;
    Review_Heading: string;
    Review_Description: string;
    Footer_Icon_Text: string;
    Product_Count: string;
    Category_Section: string;
    Category_Image_Text: string;
    Add_Category: string;
    Community_Network_Section: string;
    Network_Heading: string;
    Network_SubHeading: string;
    Community_Title: string;
    Community_Description: string;
    Purchase_Request_Section: string;
    Dynamic_Image_Section: string;
    Image_Heading: string;
    Image_SubHeading: string;
    Featured_Product_Section: string;
    SubTitle: string;
    Add_Featured_Product_Section: string;
    Offer_Section: string;
    About_Blog_Section: string;
    Faq_Section: string;
    Review_Card: string;
    Manage_Reviews: string;
    Add_Review: string;
    Edit_Review: string;
    Customer_Name: string;
    Create_Question: string;
    Manage_Questions: string;
    Edit_Question: string;
    Question: string;
    Answer: string;
    About_Blogs_Section: string;
    Reviews_Section: string;
    FAQ_Section: string;
    Our_Team: string;
    Why_to_Choose_Us: string;
    Collection_Text: string;
    Collection_Value: string;
    Banner_Section: string;
    Edit_Category: string;
    View_Category: string;
    Go_to_Category_List: string;
    View_Product: string;
    Manage_Blogs: string;
    Create_Blog: string;
    No_Blogs_Found: string;
    Customer_Designation: string;
    Author_Name: string;
    Blog_Title: string;
    Blog_Content: string;
    Blog_Cover_Photo: string;
    Blog_Author_Photo: string;
    Edit_Blog: string;
    Password_Recovery: string;
    notice: string;
    admin_notice: string;
    Back: string;
    Ranking_Level: string;
    Subtotal_Charges: string;
    Offered_Count: string;
    Supplier_ID: string;
    Request_Summary: string;
    Requested_Date: string;
    Products_Request: string;
    Total_Quotes: string;
    Requested_Status: string;
    Requested_Expiry: string;
    closed_req: string;
    expired_req: string;
    checkout_msg: string;
    close_msg: string;
    no_quotes: string;
    Checkout: string;
    Close_Request: string;
    EXPIRED: string;
    COMPLETED: string;
    NEW: string;
    QUOTED: string;
    ACTIVE: string;
    REJECTED: string;
    Update: string;
    Selected_Product: string;
    Shipping_Info: string;
    Confirmation: string;
    Return_To_Shop: string;
    Payable_amount: string;
    Continue_To_Payment: string;
    Continue_To_Shipping: string;
    Set_as_Default: string;
    Edit_Address: string;
    placed: string;
    payment_fail: string;
    Order_Summary: string;
    Item: string;
    Card_payment: string;
    Thank: string;
    copy: string;
    Order_Details: string;
    Order_date: string;
    Order_Status: string;
    Total_order_amount: string;
    issue: string;
    Flat_shipping_rate: string;
    Return_To_Payment: string;
    BACK_TO_HOMEPAGE: string;
    Purchase_History_Of_payment: string;
    Payment_method: string;
    Allow_Purchase_Request: string;
    Overview: string;
    Base_Price: string;
    Specification: string;
    CREATE_PRODUCT: string;
    Add_PRODUCT: string;
    Open: string;
    Quoted: string;
    Closed: string;
    Product: string;
    Other_brand_product: string;
    Qty: string;
    subtotal: string;
    charges: string;
    Update_quotes: string;
    Order_Code: string;
    final: string;
    Enter_quantity: string;
    Enter_requested_price: string;
    Send_Request: string;
    Share_this_product: string;
    other_offer: string;
    approx: string;
    View_Details: string;
    Quantity_Needed: string;
    Request_Price: string;
    Subtotal_Price: string;
    Quotes_Accepted_Upto: string;
    No_Data_Found: string;
    Type_Your_Reply: string;
    Send_Reply: string;
    Accepted: string;
    Packed: string;
    In_Transit: string;
    Delivered: string;
    Delivery_Date: string;
    Additional_Notes: string;
    Invoice: string;
    Download_Invoice: string;
    Number: string;
    Total_Price: string;
    Back_To_HomePage: string;
    email: string;
    Registration_Date: string;
    Rank: string;
    Tax: string;
    Delivery_Type: string;
    price: string;
    Enter_Postal_Code: string;
    Enter_Phone_Number: string;
    Review: string;
    Buy_Now: string;
    currency: string;
    Useful_links: string;
    Help: string;
    S_S: string;
    Days: string;
    Hours: string;
    Minutes: string;
    Seconds: string;
    Supplier_Details: string;
    Store_Details: string;
    Create: string;
    Catgory_No_Product: string;
    All_Quotes_Required: string;
    One_Quote_Required: string;
    Mada: string;
    Visa: string;
    Mastercard: string;
    Total_Amount_Received: string;
    Amount_Received_From: string;
    Vat_Percentage: string;
    Text: string;
    Category_Heading: string;
    Enter_Subheading: string;
    Footer_Text: string;
    Enter_Category_Heading: string;
    Enter_product_count: string;
    Enter_Category_SubHeading: string;
    Enter_Heading_Network: string;
    Enter_SubHeading_Network: string;
    Enter_SubHeading_Community: string;
    Enter_Heading_Image: string;
    Enter_SubHeading_Image: string;
    Alert: string;
    Not_Found: string;
    Page_Not_Found: string;
    Sign_Up: string;
    Confirm_Password: string;
    Have_Account: string;
    Email_Username: string;
    Enter_Email_Username: string;
    Not_Have_Account: string;
    Type_Your_Message: string;
    Category_English_Name: string;
    Category_Ar_Name: string;
    Category_Ur_Name: string;
    Select_Category: string;
    SEE_MORE: string;
    Saved_Card_Details: string;
    Add_New_Card_Detail: string;
    Direct_Bank_Transfer: string;
    No_Product_Found: string;
    Bank_Transfer: string;
    View_All_Blogs: string;
    User: string;
    Collection: string;
    Read_More: string;
    Blog_Details: string;
    Share_Post: string;
    Follow_Me: string;
    Enter_Firstname: string;
    Enter_Lastname: string;
    Enter_Your_Email: string;
    Enter_Your_Phone: string;
    Enter_Message: string;
    Close_Ticket: string;
    Success: string;
    Failed: string;
    Rating_And_Review: string;
    Reviews: string;
    Bank_transfer: string;
    completed_req: string;
    Add_Review_And_Rating: string;
    Edit_Review_And_Rating: string;
    Upload_Transfer_Receipt: string;
    Transfer_Payment_Amount: string;
    Order_Remain_Pending: string;
    Successful_Payment: string;
    Make_Transfer: string;
    Vat_Tax: string;
    Add_Card: string;
    Add_Card_Details: string;
    First_Name: string;
    Payment_Id: string;
    Purchase_Request_Id: string;
    Vat_Charges: string;
    requested_amount: string;
    No_Order_Found: string;
    No_Category_Found: string;
    Review_Rating: string;
    Bank_Transfer_Request: string;
    Please_verify_document: string;
    Supplier_Company: string;
    Store_Company: string;
    Created_By: string;
    Store_Id: string;
    Go_with_new_card: string;
    Payment_with_saved_card: string;
    Get_In_Touch: string;
    Firstname: string;
    Lastname: string;
    Message: string;
    Get_In_Touch_Details: string;
    Make_Address_Default: string;
    you_want_to_logout: string;
    Assigned_Role: string;
    Approx: string;
    Delete_Notification_Alert: string;
    Edited: string;
    Is_Active: string;
    Check_email: string;
    Please_Check_Email: string;
    Reset_Link: string;
    Dont_receive_Email: string;
    Resend_Email: string;
    back_to_sign_in: string;
    Set_New_Password: string;
    Your_New_Password: string;
    Reset_Password: string;
    Tooltip_Refresh: string;
    Username_Error: string;
    Email_Error: string;
    Valid_Email: string;
    Cr_Number_Error: string;
    Comapny_Name_Error: string;
    Phone_Error: string;
    Phone_Number_Error: string;
    Phone_Positive: string;
    Phone_Length: string;
    Iban_Number_Error: string;
    Iban_Alphanumeric: string;
    Iban_Positive: string;
    Iban_Length: string;
    Password_Error: string;
    Password_Length: string;
    Password_Creds: string;
    Address_Data: string;
    Address_Country: string;
    Address_State: string;
    Address_City: string;
    Address_Postal_code: string;
    Staff_Role: string;
    Staff_Status: string;
    Role_Error: string;
    Role_Status: string;
    Branch_Error: string;
    Brach_User: string;
    Reviews_Error: string;
    Pur_Req_Expiry: string;
    Pur_Req_Category: string;
    Pur_Req_Product: string;
    Pur_Req_Quantity: string;
    Quantity_Positive: string;
    Pur_Req_Price: string;
    Price_Number: string;
    Price_Positive: string;
    Pur_Req_Description: string;
    Bank_Transfer_Error: string;
    Support_Subject: string;
    Cr_Alphanumeric: string;
    Cr_Positive: string;
    Cr_Length: string;
    Tax_Number_Error: string;
    Tax_Positive: string;
    Tax_Length: string;
    Image_Size_Error: string;
    Image_Format_Error: string;
    Vat_Req_Error: string;
    Vat_Positive_Error: string;
    Image_Unsupported_Format: string;
    Company_Status: string;
    Image_Require_Error: string;
    English_Name_Required: string;
    Arabic_Name_Required: string;
    Urdu_Name_Required: string;
    Category_Appropriate_Error: string;
    Measurement_Appropriate_Error: string;
    Name_Error: string;
    Overview_Error: string;
    Descripton_Error: string;
    Specification_Error: string;
    Featured_Image_Error: string;
    Unit_Name_Required_Error: string;
    Unit_Short_Name_Required: string;
    Banner_Heading_Required_Error: string;
    Banner_Sub_Heading_Required_Error: string;
    Button_Text_Required_Error: string;
    Collection_Required_Error: string;
    Footer_Icon_Required_Error: string;
    Product_Count_Required_Error: string;
    Category_Heading_Required_Error: string;
    Category_Sub_Heading_Required_Error: string;
    Category_Required_Error: string;
    Community_Heading_Required_Error: string;
    Community_Sub_Heading_Required_Error: string;
    Title_Required_Error: string;
    Purchase_Heading_Required_Error: string;
    Purchase_Sub_Heading_Required_Error: string;
    Image_Heading_Required_Error: string;
    Feature_Heading_Required_Error: string;
    Date_Required_Error: string;
    Product_Title_Required_Error: string;
    Product_Sub_Title_Required_Error: string;
    Price_Required_Error: string;
    Price_Number_Error: string;
    Price_Positive_Error: string;
    Rating_Required_Error: string;
    Product_Button_Text_Required_Error: string;
    Product_Button_Link_Required_Error: string;
    Offer_Heading_Required_Error: string;
    Offer_Sub_Heading_Required_Error: string;
    Description_Required_Error: string;
    Feature_Sub_Heading_Required_Error: string;
    File_Size_Error: string;
    Postal_Code_Alphanumeric: string;
    Postal_Code_Number: string;
    Postal_Code_Length: string;
    Date_Error: string;
    Date_Invalid_Error: string;
    Date_Passed_Error: string;
    Units_Error: string;
    Unit_Number_Error: string;
    Unit_Positive_Error: string;
    Base_Price_Error: string;
    Base_Price_Number_Error: string;
    Base_Price_Positive_Error: string;
    Image_Error: string;
    Offered_Price_Error: string;
    Offered_Price_Positive_Error: string;
    Charges_Number_Error: string;
    Charges_Positive_Error: string;
    Subtotal_Error: string;
    Review_Rating_Details: string;
    StoreName: string;
    Ratings: string;
    Author_Name_Error: string;
    Title_Name_Error: string;
    Blog_Content_Error: string;
    Slug_Error: string;
    Cover_Image_Error: string;
    Author_Image_Error: string;
    Page_Banner_Error: string;
    Page_Error: string;
    Heading_Error: string;
    Question_Error: string;
    Answer_Error: string;
    Page_Banner_Reuired_Error: string;
    Collection_Value_Required_Error: string;
    Collecion_Text_Required_Error: string;
    Choose_Header_Required_Error: string;
    Choose_Description_Required_Error: string;
    Team_Position_Required_Error: string;
    Team_Name_Required_Error: string;
    Team_Header_Required_Error: string;
    Team_Description_Required_Error: string;
    Customer_Name_Required_Error: string;
    Customer_Designation_Required_Error: string;
    Heading_Required_Error: string;
    Descripton_Required_Error: string;
    Card_Descripton_Required_Error: string;
    Page_Required_Error: string;
    Page_Title_Required_Error: string;
    Sub_Heading_Required_Error: string;
    Sub_Title_Required_Error: string;
    Firstname_Required_Error: string;
    Lastname_Required_Error: string;
    Message_Required_Error: string;
    Confirm_Password_Error: string;
    Confirm_Password_Req_Error: string;
    Email_Username_Error: string;
    Card_Name_Error: string;
    Password_Updated_Toast: string;
    User_Create_Toast: string;
    Staff_Create_Toast: string;
    Create_Error_Toast: string;
    Update_Toast: string;
    Delete_Error_Toast: string;
    Get_Toast: string;
    Get_All_Toast: string;
    Unique_Error_Toast: string;
    Unique_Email_Error_Toast: string;
    Unique_Username_Error_Toast: string;
    Not_Found_Toast: string;
    Not_Found_User_Toast: string;
    Catch_Response_Toast: string;
    Login_Toast: string;
    Username_Or_Password_Does_Not_Match_Toast: string;
    Store_Exists_Toast: string;
    Supplier_Exists_Toast: string;
    Mail_Sent_Toast: string;
    Profile_Updated_Toast: string;
    Default_Address_Updated_Toast: string;
    Address_Deleted_Toast: string;
    Address_Delete_Failed_Toast: string;
    Address_Is_Default_Toast: string;
    Address_Update_Failed_Taost: string;
    Profile_Update_Success_Toast: string;
    Address_Fetched_Toast: string;
    Address_Created_Toast: string;
    User_Deleted_Toast: string;
    User_Assigned_To_Branch_Toast: string;
    Wrong_User_Or_Password_Toast: string;
    User_Does_Not_Exists_Toast: string;
    Password_Does_Not_Match_Toast: string;
    Someone_Already_Registered_Toast: string;
    User_Inactive_Toast: string;
    Admin_Details_Fetched_Toast: string;
    Store_Data_Fetched_Toast: string;
    Store_Data_Updated_Toast: string;
    Store_Data_Deleted_Toast: string;
    Supplier_Data_Fetched_Toast: string;
    Cms_Pages_Fetched_Toast: string;
    Cms_Specific_Page_Fetched_Toast: string;
    Cms_Page_Updated_Toast: string;
    Cms_Blogs_Fetched_Toast: string;
    Cms_Specific_Page_Updated_Toast: string;
    Cms_Unauthorize_Update_Request: string;
    Cms_Review_Fetched_Toast: string;
    Slug_Exists_Toast: string;
    Cms_Datasource_Count_Toast: string;
    Category_Fetched_Toast: string;
    Error_Toast: string;
    Wrong_Token_Issue_Toast: string;
    Token_Invalid_Toast: string;
    Cms_Blog_Not_Found_Toast: string;
    Message_Sent_Toast: string;
    Fetch_Data_Toast: string;
    Contact_Deleted_Toast: string;
    Faq_Added_Toast: string;
    Faq_Data_Toast: string;
    Faq_Fetched_Toast: string;
    Faq_Updated_Toast: string;
    Faq_Deleted_Toast: string;
    Unit_Added_Toast: string;
    Unit_Already_Exist_Toast: string;
    All_Units_Fetched_Toast: string;
    Unit_Fetched_Toast: string;
    Unit_Unauthorize_Update_Request_Toast: string;
    Unit_Updated_Toast: string;
    Unit_Deleted_Toast: string;
    Unit_Already_Assigned_Toast: string;
    Category_Added_Toast: string;
    Category_Data_Toast: string;
    Category_Updated_Toast: string;
    Category_Deleted_Toast: string;
    Category_Could_Not_Find_Toast: string;
    Category_Duplicated_Toast: string;
    Category_Already_Exist_Toast: string;
    Category_Wise_Product_Toast: string;
    Update_Category_Inactive_Toast: string;
    Category_Product_Inactive_Toast: string;
    Category_Product_Deleted_Toast: string;
    Category_Can_Not_Deleted_Toast: string;
    Product_Added_Toast: string;
    Product_Data_Toast: string;
    Products_Fetched_Toast: string;
    Products_Fetched_Failed_Toast: string;
    Product_Updated_Toast: string;
    Products_Updated_Failed_Toast: string;
    Product_Deleted_Toast: string;
    Product_Deleted_Failed_Toast: string;
    Product_Could_Not_Find_Toast: string;
    Product_Duplicated_Toast: string;
    Product_Already_Exist_Toast: string;
    Products_Add_Failed_Toast: string;
    Product_Category_Inactive_Toast: string;
    Product_Measurement_Inactive_Taost: string;
    Single_Supplier_Toast: string;
    Failed_Single_Supplier_Toast: string;
    Unauthorize_Delete_Request_Taost: string;
    Supplier_Deleted_Toast: string;
    Supplier_Updated_Toast: string;
    Supplier_Product_Already_Exisit_Toast: string;
    Supplier_Product_Fetched_Toast: string;
    Supplier_Product_Added_Toast: string;
    Cat_Wise_Product_Fetched_Toast: string;
    Supplier_Product_Deleted_Toast: string;
    Single_Supplier_Product_Fetched_Toast: string;
    Supplier_Product_Updated_Toast: string;
    Supplier_Product_Update_Failed_Toast: string;
    Suppier_Product_Fetched_Failed_Toast: string;
    Supplier_Product_Not_Found_Toast: string;
    Request_Created_Toast: string;
    Purchase_Request_Fetched_Toast: string;
    Purchase_Request_Not_Fetched_Toast: string;
    Purchase_Request_Deleted_Toast: string;
    Purchase_Request_Delete_Failed_Toast: string;
    Purchase_Request_Status_Update_Failed_Toast: string;
    Purchase_Request_Status_Updated_Toast: string;
    Purchase_Request_Quote_Deleted_Toast: string;
    Purchase_Request_Quote_Rejected_Toast: string;
    Purchase_Request_Quote_Not_Found_Toast: string;
    Purchase_Request_Fetch_Failed_Toast: string;
    Purchase_Request_Not_Found_Toast: string;
    Purchase_Request_Quote_Deleted_Failed_Toast: string;
    Supplier_Quote_Not_Found_Toast: string;
    Purchase_Request_Quote_Accepted_Toast: string;
    Purchase_Request_Created_Toast: string;
    Purchase_Request_Updated_Toast: string;
    Quotes_Created_Toast: string;
    Quotes_Updated_Toast: string;
    Product_Fetched_Toast: string;
    Quote_Does_Not_Found_Toast: string;
    Permission_Fetched_Toast: string;
    Order_Created_Toast: string;
    Order_Updated_Toast: string;
    Order_Deleted_Toast: string;
    Order_Fetched_Toast: string;
    Support_Ticket_Created_Toast: string;
    Support_Ticket_Updated_Toast: string;
    Support_Ticket_Deleted: string;
    Support_Ticket_Fetched_Toast: string;
    Support_Ticket_Closed_Toast: string;
    Support_Ticket_Send_Reply_Toast: string;
    Chat_Created_Toast: string;
    Chat_Fetched_Toast: string;
    PaymentCharge_Created_Toast: string;
    PaymentCharge_Updated_Toast: string;
    PaymentCharge_Deleted_Toast: string;
    PaymentCharge_Fetched_Toast: string;
    Payement_Failed_Toast: string;
    Payment_Details_Not_Found_Toast: string;
    General_Count_Report_Fetch_Toast: string;
    Store_Not_Exisit_Toast: string;
    Reviews_Fetched_Toast: string;
    Reviews_Added_Toast: string;
    Reviews_Updated_Toast: string;
    Role_Added_Toast: string;
    Role_Updated_Toast: string;
    Staff_Member_Added_Toast: string;
    Staff_Member_Updated_Toast: string;
    Staff_Member_Deleted_Toast: string;
    Role_Deleted_Toast: string;
    Role_Already_Exists_Toast: string;
    Role_Already_Assigned_Toast: string;
    IBAN_Number_Toast: string;
    Card_Added_Toast: string;
    Card_Fetched_Toast: string;
    Card_Updated_Toast: string;
    Card_Deleted_Toast: string;
    Unique_Card_Error_Toast: string;
    Card_Does_Not_Belongs_To_Customer_Toast: string;
    Failed_Card_Delete_Toast: string;
    Notification_Deleted_Toast: string;
    Product_Cannot_Be_Repeated_Toast: string;
    Cms_Updated_Toast: string;
    Cms_Deleted_Toast: string;
    Cms_Created_Toast: string;
    Company_Inactive_Toast: string;
    Branch_Fetched_Toast: string;
    Branch_Created_Toast: string;
    Branch_Updated_Toast: string;
    Branch_Deleted_Toast: string;
    Vat_Upadated_Toast: string;
    Vat_Get_Toast: string;
    Correct_Mail_Toast: string;
    Transaction_Fetched_Toast: string;
    Weekly_Graph_Detail_Toast: string;
    Category_Graph_Detail_Toast: string;
    Mail_Send_Toast: string;
    Success_Toast: string;
    Blog_Image_Upload_Toast: string;
    Purchase_Request_Detail_Not_Found_Toast: string;
    Order_Added_Toast: string;
    Data_Fetched_Toast: string;
    Unable_To_Delete_User_Toast: string;
    User_Exist_Toast: string;
    Record_Exist_Toast: string;
    Not_Find_Product_Toast: string;
    Enter_New_Password: string;
    ReEnter_New_Password: string;
    Password_And_Security: string;
    UOM: string;
    Latest_Products: string;
    My_Profile: string;
    Manage_Password: string;
    Bank_Transfer_Initiated_Alert: string;
    Add_Ranking: string;
    Edit_Ranking: string;
    Bank_Transfer_Instruction: string;
    Branch_Delete_Text: string;
    Select_Card: string;
    Enter_Offer_Price: string;
    Quantity_Error_Message: string;
    Quantity_Number_Error: string;
    Quantity_Positive_Error: string;
    Requested_Price_Required_Error: string;
    Requested_Price_Number_Error: string;
    Requested_Price_Positive_Error: string;
    Quotes_Accepted_Required_Eror: string;
    Quantity_Required_Error: string;
    Payment_Mode: string;
    Decline: string;
    Approve: string;
    Approved: string;
    Thank_You: string;
    Received_Your_Message: string;
    Low_To_High: string;
    High_To_Low: string;
    Recently_Added: string;
    All_Prices: string;
    Sort_By: string;
    Price_Range: string;
    Verification_Status: string;
    Activation_Status: string;
    Passed_Date: string;
    Role_First_Error: string;
    See_All_Notification: string;
    Offer_Price_Message: string;
    Branch_Exists_Toast: string;
    Store_Already_Exists_Toast: string;
    Choose_Category: string;
    Choose_Status: string;
    Choose_Country: string;
    Choose_State: string;
    Choose_City: string;
    Choose_Role: string;
    Select_Product: string;
    Same_Category_Error: string;
    Enter_Vat_Charges_Error: string;
    Total_Transactions: string;
    Reject_Msg: string;
    Reject: string;
    Total_Suppliers: string;
    Total_Store_Owners: string;
    Total_Products: string;
    Total_Categories: string;
    Total_BankTransfers: string;
    Enter_Current_Password: string;
    Future_Date: string;
    Enter_Payment_Id: string;
    Orders_By_Date: string;
    Product_Details: string;
    View_OrderDetail: string;
    Less_than_Expiry_Date: string;
    Enter_Request_ID: string;
    Purchase_Request_After_Date: string;
    Search_Filter: string;
    Apply: string;
    Reset: string;
    Enter_Order_Id: string;
    Enter_Amount: string;
    Enter_Ticket_Id: string;
    Tickets_By_Date: string;
    Enter_Purchase_Req_Id: string;
    Enter_Company_Name: string;
    Category_Name: string;
    Enter_Supplier_Company_Name: string;
    Enter_Store_Company_Name: string;
    Product_Title: string;
    Grand_Total: string;
    Download_Quotation: string;
    Enter_Quantity: string;
    Has_Offers: string;
    Enter_Charges: string;
    tooltip_another_req: string;
    tooltip_req: string;
    Reason_for_Cancel_Order: string;
    Enter_Reason: string;
    Replacement_Of_Order_Product: string;
    Replace_Product: string;
    Regular_Orders: string;
    Replacement_Orders: string;
    Reason_Error: string;
    Send_Replacement_Request: string;
    Product_status: string;
    Replacement_Status: string;
    Can_not_select_same_product: string;
    Send_Refund_Request: string;
    Refund_Of_Order_Product: string;
    Refund_Orders: string;
    Refund_order_detail: string;
    Replacement_order_detail: string;
    Supplier_Status: string;
    Admin_Status: string;
    cancel_tooltip: string;
    delivered_tooltip: string;
    order_tooltip: string;
    Payment_Assurance: string;
    Refundable_Amount: string;
    Refund_Order_Id: string;
    Select_All: string;
    Refund_Order_Details: string;
    Refund_Status: string;
    Accepted_for_refund: string;
    Pending_for_refund: string;
    Rejected_for_refund: string;
    Replacement_Accepted_Upto: string;
    Replace_Days_Updated: string;
    Supplier_Refund_Status: string;
    Admin_Refund_Status: string;
    Replacement_Order_Details: string;
    Replacement_Order_Tracking: string;
    Replacemnt_Req_Error: string;
    Refundable_Req_Error: string;
    Replacemnt_Positive_Error: string;
    Refundable_Positive_Error: string;
    Refund_Accepted_Upto: string;
    Refund_Order_Summary: string;
    Tooltip_order_refunded: string;
    ord_commission: string;
    bank_commission: string;
    Enter_Ord_Commission: string;
    Enter_Bank_Commission: string;
    Ord_Commission: string;
    Bank_Commission: string;
    Bank_Commission_Number: string;
    Ord_Commission_Number: string;
    Admin_Refund: string;
    Finance_Assurance: string;
    Finance_Info: string;
    View_Pdf: string;
    View_Excel: string;
    Order_Pdf: string;
    Order_Excel: string;
    Choose_Branch: string;
    Select_Branch_for_Shipping: string;
    Purchase_for_branch: string;
    associated_delivery_address: string;
    Default_Bank_Commmission_Updated: string;
    Default_Ord_Commmission_Updated: string;
    go_to_bank_transfer: string;
    Charges: string;
    General_Settings: string;
    Orders_After_Date: string;
    Role_Error_ar: string;
    Role_Error_ur: string;
    Role_Name_Arabic: string;
    Role_Name_Urdu: string;
  };
} = {
  ar: {
    Home: "الرئيسية",
    About_Us: "من نحن",
    Blog: "المدونة",
    Contact_Us: "اتصل بنا",
    place_where: "مكان حيث",
    everything: "كل شيء",
    special: "خاص",
    header:
      "لوريم إيبسوم هو نص افتراضي يستخدم في صناعة الطباعة والتنضيد. كان لوريم إيبسوم النص الافتراضي القياسي للصناعة منذ القرن الخامس عشر، عندما أخذت طابعة غير معروفة",
    collection: "مجموعة",
    shiiping_available: "الشحن متاح",
    money_back: "ضمان استعادة الأموال بنسبة 100%",
    Payment: "الدفع",
    payment_process: "عملية دفع آمنة",
    online_support: "دعم عبر الإنترنت على مدار الساعة",
    heading_section: "قسم العنوان",
    sign_in: "تسجيل الدخول",
    account_details: "يرجى ملء التفاصيل للوصول إلى حسابك.",
    personal_details: "التفاصيل الشخصية",
    total_earning: "إجمالي الأرباح",
    total_product: "إجمالي المنتجات",
    Total_Sent_Quotes: "إجمالي العروض المرسلة",
    General_Report: "تقرير عام",
    Dashboard: "لوحة التحكم",
    Store: "المتجر",
    Cms_pages: "صفحات إدارة المحتوى",
    Supplier: "المورد",
    Products: "عروضي",
    Categories: "التصنيفات",
    Products_List: "قائمة المنتجات",
    Unit_of_measurement: "وحدة القياس",
    Orders: "الطلبات",
    Support_Ticket: "تذكرة الدعم",
    Logout: "تسجيل الخروج",
    Sales_Report: "تقرير المبيعات",
    Weekly_Top_Seller_Stores: "أفضل المتاجر مبيعاً أسبوعياً",
    Transactions: "المعاملات",
    Store_Info: "معلومات المتجر",
    CMS_Pages: "صفحات إدارة المحتوى",
    Data_Source: "مصدر البيانات",
    Supplier_Info: "معلومات المورد",
    Measurement: "القياس",
    search: "بحث",
    Search: "ابحث هنا...",
    SR_NO: "الرقم التسلسلي",
    Name: "الاسم",
    Email: "البريد الإلكتروني",
    Company_Name: "اسم الشركة",
    Phone_Number: "رقم الهاتف",
    Status: "الحالة",
    Actions: "الإجراء",
    Title: "العنوان",
    Slug: "الرابط المختصر",
    Cards: "البطاقات",
    count: "العدد",
    No_of_Products: "عدد المنتجات",
    Product_name: "اسم المنتج",
    Image: "الصورة",
    Unit_Name: "اسم الوحدة",
    Unit_Short_Name: "اسم الوحدة المختصر",
    Order_Id: "معرف الطلب",
    Order_ID: "معرف الطلب",
    Supplier_Name: "شركة المورد",
    Store_Name: "شركة المتجر",
    Amount: "المبلغ",
    Date: "التاريخ",
    Sending_Date: "تاريخ الإرسال",
    Subject: "الموضوع",
    Ticket_ID: "معرف التذكرة",
    View_more: "عرض المزيد",
    Edit: "تعديل",
    View: "عرض",
    Delete: "حذف",
    Manage_Profile: "إدارة الملف الشخصي",
    Purchase_Request: "طلب شراء",
    Staff: "الموظفون",
    All_staff: "جميع الموظفين",
    All_roles: "جميع الأدوار",
    Branches: "الفروع",
    chat: "الدردشة",
    Chat: "الدردشة",
    Notification: "الإشعار",
    Purchase_History: "طلباتي",
    Recent_Orders: "الطلبات الأخيرة",
    Purchase_Requests: "طلبات الشراء",
    Edit_Purchase_Requests: "تعديل طلب الشراء",
    ID: "معرف",
    Payment_status: "حالة الدفع",
    Request_ID: "معرف الطلب",
    Requested_Quantity: "الكمية المطلوبة",
    Requested_Price: "السعر المطلوب",
    Options: "الخيارات",
    Username: "اسم المستخدم",
    CR_Number: "رقم السجل التجاري",
    Phone: "رقم الهاتف",
    Tax_Number: "الرقم الضريبي",
    IBAN_number: "رقم الآيبان",
    Photo: "الصورة",
    Saved_Address: "العنوان المحفوظ",
    Security: "الأمان",
    UPDATE_DETAILS: "تحديث التفاصيل",
    Your_password: "كلمة المرور الخاصة بك",
    Add_New_Address: "إضافة عنوان جديد",
    Update_Password: "تحديث كلمة المرور",
    Add_Product: "إضافة منتج",
    Showing: "عرض",
    entries: "إدخالات",
    to: "إلى",
    of: "من",
    Add_Staff_Member: "إضافة عضو فريق",
    about_description: "وصف من نحن",
    Create_Staff: "إنشاء فريق",
    Edit_Staff: "تعديل الفريق",
    Add_new_Permission: "إضافة إذن جديد",
    All_Roles: "جميع الأدوار",
    Cancel: "إلغاء",
    Close: "إغلاق",
    Are_you_sure: "هل أنت متأكد",
    DeleteMsg: "هل تريد حقًا حذف هذه السجلات؟",
    Alternate_product: "منتج بديل",
    Offered_Quantity: "الكمية المقدمة",
    Offered_Price: "السعر المقدم",
    Offered_deal_at: "عرض في",
    Select: "اختر",
    Role: "الدور",
    Save: "حفظ",
    Role_Name: "اسم الدور",
    Edit_Products: "تعديل المنتج",
    View_All_Products: "عرض جميع المنتجات",
    Delete_Products: "حذف المنتجات",
    Add_Products: "إضافة منتجات",
    Manage_Products_Req: "إدارة طلبات المنتجات",
    Send_Quotes: "إرسال عرض",
    View_Orders: "عرض الطلبات",
    Assigned_Orders: "الطلبات المسندة",
    Manage_Status: "إدارة الحالة",
    Manage_Staff: "إدارة الموظفين",
    Add_staff: "إضافة موظف",
    View_All_staff: "عرض جميع الموظفين",
    Delete_staff: "حذف الموظفين",
    Edit_staff: "تعديل الموظفين",
    Add_Roles: "إضافة أدوار",
    Edit_Roles: "تعديل الأدوار",
    Delete_Roles: "حذف الأدوار",
    View_Roles: "عرض جميع الأدوار",
    Request_Purchase: "طلب الشراء",
    Manage_Branches: "إدارة الفروع",
    Add_Branches: "إضافة فروع",
    View_All_Branches: "عرض جميع الفروع",
    Edit_Branches: "تعديل الفروع",
    Delete_Branches: "حذف الفروع",
    Ticket: "التذكرة",
    Add_Ticket: "إضافة تذكرة",
    View_All_support_tickets: "عرض جميع تذاكر الدعم",
    Cancel_Order: "إلغاء الطلب",
    create_Orders: "إنشاء طلبات",
    Create_Role: "إنشاء دور",
    Update_Role: "تحديث الدور",
    Create_Branch: "إنشاء فرع",
    Branch_Name: "اسم الفرع",
    Address: "العنوان",
    Postal_Code: "الرمز البريدي",
    Country: "البلد",
    State: "الولاية",
    State_Province: "المقاطعة",
    City: "المدينة",
    Assign_users: "تعيين المستخدمين",
    Choose: "اختر",
    Delete_Purchase_Request: "حذف طلب الشراء",
    Track_Purchase_Request: "تتبع طلب الشراء",
    Create_Purchase_Request: "إنشاء طلب الشراء",
    Checkout_Quote: "إتمام العرض",
    Permissions: "الأذونات",
    All_Branches: "جميع الفروع",
    Add_Branch: "إضافة فرع",
    Edit_Branch: "تعديل الفرع",
    Upload_File: "رفع الملف",
    CREATE_A_TICKET: "إنشاء تذكرة",
    Create_A_Ticket: "إنشاء تذكرة",
    Create_Ticket: "إنشاء تذكرة",
    choose_file: "اختر ملف",
    View_Supplier_Info: "عرض معلومات المورد",
    Edit_Supplier_Info: "تعديل معلومات المورد",
    Add_Supplier_Info: "إضافة معلومات المورد",
    Profile_Image: "صورة الملف الشخصي",
    Submit: "إرسال",
    Go_to_supplier_list: "الذهاب إلى قائمة الموردين",
    Page: "الصفحة",
    Blog_Page_Banner: "بانر صفحة المدونة",
    Heading: "العنوان",
    Blog_Heading: "عنوان المدونة",
    Blog_Description: "وصف المدونة",
    Remove: "إزالة",
    Blogs_Section: "قسم المدونات",
    Save_Changes: "حفظ التغييرات",
    Email_address: "عنوان البريد الإلكتروني",
    Select_Category_Image: "صورة التصنيف",
    Category: "التصنيف",
    Product_Name: "اسم المنتج",
    Product_Overview: "نظرة عامة على المنتج",
    Product_Description: "وصف المنتج",
    Product_Specification: "مواصفات المنتج",
    Featured_image: "صورة مميزة",
    Add_Product_Image: "إضافة صورة المنتج",
    Go_to_Product_List: "الذهاب إلى قائمة المنتجات",
    Add_Measurement: "إضافة قياس",
    Pending_Purchase_Requests: "طلبات الشراء المعلقة",
    Total_Products_Ordered: "إجمالي المنتجات المطلوبة",
    Total_Available_Offers: "إجمالي العروض المتاحة",
    Default_Shipping_Address: "العنوان الافتراضي للشحن",
    Shipping_Address: "عنوان الشحن",
    Shipping: "الشحن",
    Created_Date: "تاريخ الإنشاء",
    Number_of_products: "عدد المنتجات",
    Branch: "الفرع",
    Staff_Member: "عضو الفريق",
    Expiry_Date: "تاريخ انتهاء الصلاحية",
    Total: "المجموع",
    chatMsg: "يرجى النقر على أي دردشة لبدء المحادثة...",
    Requests_Expiry: "انتهاء الطلبات",
    Receive_offers_for_the_entire_request: "تلقي العروض للطلب بأكمله",
    another_brand:
      "فعل هذا الخيار إذا كنت على ما يرام مع الحصول على علامة تجارية أخرى لعرض أفضل",
    Quantity: "الكمية",
    Subtotal: "المجموع الفرعي",
    Description: "الوصف",
    Product_Image: "صورة المنتج",
    Product_Images: "صور المنتجات",
    Offer_Duration: "مدة العرض",
    Units: "الوحدات",
    Unit_Property: "خاصية الوحدة",
    Offer_Valid_Till: "العرض صالح حتى",
    Password: "كلمة المرور",
    My_Account: "حسابي",
    Privacy_Policy: "سياسة الخصوصية",
    Terms_Conditions: "الشروط والأحكام",
    Popular_Categories: "التصنيفات الأكثر شيوعا",
    View_all: "عرض الكل",
    Enter_unit_name: "أدخل اسم الوحدة",
    Enter_unit_short_name: "أدخل الاسم المختصر للوحدة",
    Enter_category_name: "أدخل اسم التصنيف",
    Enter_product_name: "أدخل اسم المنتج",
    Enter_CompanyName: "أدخل اسم الشركة",
    Enter_CR_Number: "أدخل رقم السجل التجاري",
    Enter_Tax_Number: "أدخل الرقم الضريبي",
    Enter_IBAN_number: "أدخل رقم الآيبان",
    Product_over: "أدخل نظرة عامة على المنتج",
    Product_desc: "أدخل وصف المنتج",
    Product_spec: "أدخل مواصفات المنتج",
    Faq_heading: "أدخل العنوان",
    Faq_subHeading: "أدخل العنوان الفرعي",
    Page_banner: "أدخل بانر الصفحة",
    About_heading: "أدخل العنوان",
    About_subHeading: "أدخل العنوان الفرعي",
    About_buttonText: "أدخل نص الزر",
    About_butttonLink: "أدخل رابط الزر",
    About_collectionValue: "أدخل قيمة المجموعة",
    About_collectionText: "أدخل نص المجموعة",
    About_choose_heading: "أدخل العنوان",
    About_choose_desc: "أدخل الوصف",
    About_card_heading: "أدخل العنوان",
    About_card_position: "أدخل نص الموضع",
    About_card_description: "أدخل الوصف",
    About_card_name: "أدخل نص الاسم",
    Enter_Heading: "أدخل العنوان",
    Enter_description: "أدخل الوصف",
    Page_title: "أدخل عنوان الصفحة",
    Subheading: "أدخل العنوان الفرعي",
    Support_title: "أدخل عنوان الدعم",
    Enter_title: "أدخل العنوان",
    Enter_subTitle: "أدخل العنوان الفرعي",
    Footer_text: "أدخل نص تذييل الصفحة",
    Product_count: "أدخل عدد المنتجات",
    community_description: "أدخل وصف المجتمع",
    Community_title: "أدخل عنوان المجتمع",
    Enter_date: "أدخل التاريخ",
    Price: "سعر المنتج",
    Rating: "التقييم",
    Author_name: "أدخل اسم المؤلف",
    Blog_title: "أدخل عنوان المدونة",
    Enter_Slug: "أدخل الرابط المختصر",
    Blog_content: "أدخل محتوى المدونة",
    Designation: "أدخل وظيفتك",
    Enter_Customer_name: "أدخل اسم العميل",
    Customer_name: "اسم العميل",
    Enter_reviews: "أدخل مراجعاتك",
    Enter_question: "أدخل السؤال",
    Enter_answer: "أدخل الإجابة",
    Go_to_store_list: "الذهاب إلى قائمة المتاجر",
    Enter_Username: "أدخل اسم المستخدم",
    Enter_Email: "أدخل البريد الإلكتروني",
    Enter_Phone: "أدخل رقم الهاتف",
    Enter_Password: "أدخل كلمة المرور",
    Enter_Rolename: "أدخل اسم الدور",
    Enter_Branch: "أدخل الفرع",
    Enter_Address: "أدخل العنوان",
    Enter_Date: "أدخل التاريخ",
    Gram: "جرام",
    Enter_Description: "أدخل الوصف",
    Enter_Subject: "أدخل الموضوع",
    Type_your_reply: "اكتب ردك",
    Enter_Overview: "أدخل نظرة عامة",
    Enter_Units: "أدخل الوحدات",
    Enter_Unitproperty: "أدخل خاصية الوحدة",
    Enter_Price: "أدخل السعر",
    Enter_Baseprice: "أدخل السعر الأساسي",
    Enter_Status: "أدخل الحالة",
    Enter_Specification: "أدخل المواصفات",
    Forgot_Password: "نسيت كلمة المرور",
    Add_new_Product: "إضافة منتج جديد",
    Add_new_Category: "إضافة تصنيف جديدة",
    Remember_me: "تذكرني",
    Measurements: "القياسات",
    Edit_Measurement: "تعديل القياس",
    Edit_Store_Info: "تعديل معلومات المتجر",
    English: "الإنجليزية",
    Urdu: "الأردو",
    Arabic: "العربية",
    Sub_heading: "العنوان الفرعي",
    Button_Text: "نص الزر",
    Button_Link: "رابط الزر",
    Card_Heading: "عنوان البطاقة",
    Card_Description: "وصف البطاقة",
    Card_Name: "اسم البطاقة",
    Card_Position: "موقع البطاقة",
    Go_to_Blogs_Section: "الذهاب إلى قسم المدونات",
    Go_to_Reviews_Section: "الذهاب إلى قسم المراجعات",
    Go_to_Faq_Section: "الذهاب إلى قسم الأسئلة الشائعة",
    subHeading: "العنوان الفرعي",
    Faq: "الأسئلة الشائعة",
    Contact_Us_Section: "اتصل بنا",
    PageTitle: "عنوان الصفحة",
    Title_For_Support: "عنوان الدعم",
    Card_Title: "عنوان البطاقة",
    Card_SubTitle: "العنوان الفرعي للبطاقة",
    Review_Section: "قسم المراجعة",
    Review_Heading: "عنوان المراجعة",
    Review_Description: "وصف المراجعة",
    Banner_Section: "قسم البانر",
    Collection_Text: "نص المجموعة",
    Footer_Icon_Text: "نص أيقونة التذييل",
    Product_Count: "عدد المنتجات",
    Category_Section: "قسم التصنيف",
    Category_Image_Text: "نص صورة التصنيف",
    Add_Category: "إضافة تصنيف",
    Community_Network_Section: "قسم شبكة المجتمع",
    Network_Heading: "عنوان الشبكة",
    Network_SubHeading: "العنوان الفرعي للشبكة",
    Community_Title: "عنوان المجتمع",
    Community_Description: "وصف المجتمع",
    Purchase_Request_Section: "قسم طلب الشراء",
    Dynamic_Image_Section: "قسم الصورة المتغيرة",
    Image_Heading: "عنوان الصورة",
    Image_SubHeading: "العنوان الفرعي للصورة",
    Featured_Product_Section: "قسم المنتج المميز",
    SubTitle: "العنوان الفرعي",
    Add_Featured_Product_Section: "إضافة قسم المنتج المميز",
    Offer_Section: "قسم العرض",
    About_Blog_Section: "قسم المدونة",
    Faq_Section: "قسم الأسئلة الشائعة",
    Review_Card: "إضافة بطاقة مراجعة",
    Manage_Reviews: "إدارة المراجعات",
    Add_Review: "إضافة مراجعة",
    Edit_Review: "تعديل المراجعة",
    Customer_Name: "اسم العميل",
    Create_Question: "إنشاء سؤال",
    Manage_Questions: "إدارة الأسئلة",
    Edit_Question: "تعديل السؤال",
    Question: "السؤال",
    Answer: "الإجابة",
    About_Blogs_Section: "قسم المدونات",
    Reviews_Section: "قسم المراجعات",
    FAQ_Section: "قسم الأسئلة الشائعة",
    Our_Team: "فريقنا",
    Why_to_Choose_Us: "لماذا نحن",
    Collection_Value: "قيمة المجموعة",
    Edit_Category: "تعديل التصنيف",
    View_Category: "عرض التصنيف",
    Go_to_Category_List: "الذهاب إلى قائمة التصنيفات",
    View_Product: "عرض المنتج",
    Manage_Blogs: "إدارة المدونات",
    Create_Blog: "إنشاء مدونة",
    No_Blogs_Found: "لم يتم العثور على مدونات",
    Customer_Designation: "وظيفة العميل",
    Author_Name: "اسم المؤلف",
    Blog_Title: "عنوان المدونة",
    Blog_Content: "محتوى المدونة",
    Blog_Cover_Photo: "صورة غلاف المدونة",
    Blog_Author_Photo: "صورة المؤلف",
    Edit_Blog: "تعديل المدونة",
    Password_Recovery: "استعادة كلمة المرور",
    notice:
      "يرجى إدخال عنوان بريدك الإلكتروني أدناه. ستتلقى رابطًا لإعادة تعيين كلمة المرور الخاصة بك",
    admin_notice: "لا تقلق، سنرسل لك رمز التحقق في بريدك الإلكتروني",
    Back: "العودة",
    Ranking_Level: "مستوى التصنيف",
    Subtotal_Charges: "المجموع الفرعي + الرسوم",
    Offered_Count: "عدد العروض المقدم",
    Supplier_ID: "معرف المورد",
    Request_Summary: "ملخص الطلب",
    Requested_Date: "تاريخ الطلب",
    Products_Request: "طلبات المنتجات",
    Total_Quotes: "إجمالي العروض",
    Requested_Status: "حالة الطلب",
    Requested_Expiry: "انتهاء الطلب",
    closed_req: "تم إغلاق طلب الشراء هذا!",
    expired_req: "انتهت صلاحية طلب الشراء هذا!",
    checkout_msg: "هل أنت متأكد أنك تريد إتمام الدفع؟",
    close_msg: "هل أنت متأكد أنك تريد إغلاق هذا الطلب؟",
    no_quotes: "لا يوجد مورد قدم عرضًا لهذا الطلب",
    Checkout: "إتمام الدفع",
    Close_Request: "إغلاق الطلب",
    EXPIRED: "منتهي الصلاحية",
    COMPLETED: "مكتمل",
    NEW: "جديد",
    QUOTED: "تم تقديم عرض",
    ACTIVE: "نشط",
    REJECTED: "مرفوض",
    Update: "تحديث",
    Selected_Product: "المنتج المختار",
    Shipping_Info: "معلومات الشحن",
    Confirmation: "تأكيد",
    Return_To_Shop: "العودة إلى المتجر",
    Payable_amount: "المبلغ المستحق",
    Continue_To_Shipping: "تابع إلى الشحن",
    Continue_To_Payment: "تابع إلى الدفع",
    Set_as_Default: "تعيين كافتراضي",
    Edit_Address: "تعديل العنوان",
    placed: "تم تقديم طلبك",
    payment_fail: "عذرًا، فشلت عملية الدفع الخاصة بك، يمكنك المحاولة مرة أخرى!",
    Order_Summary: "ملخص الطلب",
    Item: "المنتج",
    Card_payment: "الدفع بالبطاقة",
    Thank: "شكرًا لطلبك",
    copy: "تم إرسال نسخة من ملخص طلبك إلى",
    Order_Details: "تفاصيل الطلب",
    Order_date: "تاريخ الطلب",
    Order_Status: "حالة الطلب",
    Total_order_amount: "المجموع النهائي",
    issue: "عذرًا، حدثت مشكلة ما",
    Flat_shipping_rate: "معدل الشحن الثابت",
    Return_To_Payment: "العودة إلى الدفع",
    BACK_TO_HOMEPAGE: "العودة إلى الصفحة الرئيسية",
    Purchase_History_Of_payment: "سجل المشتريات",
    Payment_method: "طريقة الدفع",
    Allow_Purchase_Request: "السماح بطلب الشراء",
    Overview: "نظرة عامة",
    Base_Price: "السعر الأساسي",
    Specification: "المواصفات",
    CREATE_PRODUCT: "إنشاء منتج",
    Add_PRODUCT: "إضافة منتج",
    Open: "مفتوح",
    Quoted: "تم تقديم عرض",
    Closed: "مغلق",
    Product: "منتج",
    Other_brand_product: "منتج من علامة تجارية أخرى",
    Qty: "الكمية",
    subtotal: "المجموع الفرعي",
    charges: "الرسوم (إن وجدت)",
    Update_quotes: "تحديث العرض",
    Order_Code: "رمز الطلب",
    final: "يرجى التحقق من الكمية النهائية",
    Enter_quantity: "أدخل الكمية",
    Enter_requested_price: "أدخل السعر المطلوب:",
    Send_Request: "إرسال الطلب",
    Share_this_product: "شارك هذا المنتج",
    other_offer: "عروض الموردين الأخرى لهذا المنتج",
    approx: "تقريبًا",
    View_Details: "عرض التفاصيل",
    Quantity_Needed: "الكمية المطلوبة",
    Request_Price: "سعر الطلب لكل وحدة",
    Subtotal_Price: "السعر الفرعي",
    Quotes_Accepted_Upto: "العروض المقبولة حتى",
    No_Data_Found: "لم يتم العثور على بيانات",
    Type_Your_Reply: "اكتب ردك",
    Send_Reply: "إرسال الرد",
    Accepted: "مقبول",
    Packed: "معبأ",
    In_Transit: "في الطريق",
    Delivered: "تم التسليم",
    Delivery_Date: "تاريخ التسليم",
    Additional_Notes: "ملاحظات إضافية",
    Invoice: "فاتورة",
    Download_Invoice: "تنزيل الفاتورة",
    Number: "الرقم",
    Total_Price: "إجمالي السعر",
    Back_To_HomePage: "العودة إلى الصفحة الرئيسية",
    email: "البريد الإلكتروني",
    Registration_Date: "تاريخ التسجيل",
    Rank: "التصنيف",
    Tax: "الضريبة",
    Delivery_Type: "نوع التسليم",
    price: "السعر",
    Enter_Postal_Code: "أدخل الرمز البريدي",
    Enter_Phone_Number: "أدخل رقم الهاتف",
    Review: "مراجعة",
    Buy_Now: "اشتري الآن",
    currency: "ريال سعودي",
    Useful_links: "روابط مفيدة",
    Help: "مساعدة",
    S_S: "شركة س&س",
    Days: "أيام",
    Hours: "ساعات",
    Minutes: "دقائق",
    Seconds: "ثواني",
    Supplier_Details: "تفاصيل المورد",
    Store_Details: "تفاصيل المتجر",
    Create: "إنشاء",
    Catgory_No_Product:
      "هذه التصنيف لا يحتوي على منتجات أخرى، يرجى الاتصال بالمسؤول",
    All_Quotes_Required:
      "يجب عليك تقديم عروض لجميع المنتجات المطلوبة لهذا الطلب",
    One_Quote_Required:
      "يجب عليك تقديم عروض لمنتج واحد على الأقل من المنتجات المطلوبة لهذا الطلب",
    Mada: "مدى",
    Visa: "فيزا",
    Mastercard: "ماستر كارد",
    Total_Amount_Received: "إجمالي المبلغ المستلم",
    Amount_Received_From: "إجمالي المبلغ المستلم من",
    Vat_Percentage: "نسبة ضريبة القيمة المضافة",
    Text: "نص",
    Category_Heading: "عنوان التصنيف",
    Enter_Subheading: "أدخل العنوان الفرعي...",
    Footer_Text: "أدخل نص أيقونة التذييل",
    Enter_Category_Heading: "أدخل عنوان التصنيف",
    Enter_product_count: "أدخل عدد المنتجات",
    Enter_Category_SubHeading: "أدخل العنوان الفرعي للتصنيف",
    Enter_Heading_Network: "أدخل عنوان الشبكة",
    Enter_SubHeading_Network: "أدخل العنوان الفرعي للشبكة",
    Enter_SubHeading_Community: "أدخل العنوان الفرعي للمجتمع",
    Enter_Heading_Image: "أدخل عنوان الصورة",
    Enter_SubHeading_Image: "أدخل العنوان الفرعي للصورة",
    Alert: "تنبيه",
    Not_Found: "لم نتمكن من العثور على الصفحة التي تبحث عنها",
    Page_Not_Found: "الصفحة غير موجودة!",
    Sign_Up: "التسجيل",
    Confirm_Password: "تأكيد كلمة المرور",
    Have_Account: "هل لديك حساب بالفعل؟",
    Email_Username: "البريد الإلكتروني أو اسم المستخدم",
    Enter_Email_Username: "أدخل البريد الإلكتروني أو اسم المستخدم",
    Not_Have_Account: "لا تملك حسابًا؟",
    Type_Your_Message: "اكتب رسالتك...",
    Category_English_Name: "الاسم الإنجليزي",
    Category_Ar_Name: "الاسم العربي",
    Category_Ur_Name: "الاسم الأردي",
    Select_Category: "اختر التصنيف",
    SEE_MORE: "عرض المزيد",
    Saved_Card_Details: "تفاصيل البطاقة المحفوظة",
    Add_New_Card_Detail: "إضافة تفاصيل بطاقة جديدة",
    Direct_Bank_Transfer: "التحويل البنكي المباشر",
    No_Product_Found: "لم يتم العثور على منتج",
    Bank_Transfer: "التحويل البنكي",
    View_All_Blogs: "عرض جميع المدونات",
    User: "المستخدمون",
    Collection: "المجموعات",
    Read_More: "قراءة المزيد",
    Blog_Details: "تفاصيل المدونة",
    Share_Post: "مشاركة المنشور",
    Follow_Me: "تابعني",
    Enter_Firstname: "أدخل اسمك الأول",
    Enter_Lastname: "أدخل اسمك الأخير",
    Enter_Your_Email: "أدخل بريدك الإلكتروني",
    Enter_Your_Phone: "أدخل هاتفك",
    Enter_Message: "أدخل الرسالة",
    Close_Ticket: "إغلاق التذكرة",
    Success: "نجح",
    Failed: "فشل",
    Rating_And_Review: "المراجعة والتقييم",
    Reviews: "المراجعات",
    Bank_transfer: "التحويل البنكي",
    completed_req: "تم اكتمال طلب الشراء هذا!",
    Add_Review_And_Rating: "إضافة مراجعة وتقييم",
    Edit_Review_And_Rating: "تعديل المراجعة والتقييم",
    Upload_Transfer_Receipt: "رفع إيصال التحويل",
    Transfer_Payment_Amount:
      "قم بتحويل مبلغ الدفع إلى رقم الآيبان التالي (بنك الرياض)",
    Order_Remain_Pending:
      "يرجى ملاحظة أن طلبك سيظل معلقًا حتى يتم استلام الدفع. يرجى رفع إيصال التحويل. سيكون هذا دليلاً على الدفع الناجح.",
    Successful_Payment: "بمجرد إكمال التحويل البنكي",
    Make_Transfer: "قم بإجراء التحويل البنكي",
    Vat_Tax: "ضريبة القيمة المضافة",
    Add_Card: "إضافة بطاقة",
    Add_Card_Details: "إضافة تفاصيل البطاقة",
    First_Name: "الاسم الأول",
    Payment_Id: "معرف الدفع",
    Purchase_Request_Id: "معرف طلب الشراء",
    Vat_Charges: "رسوم ضريبة القيمة المضافة",
    requested_amount: "المبلغ المطلوب",
    No_Order_Found: "لم يتم العثور على طلب",
    No_Category_Found: "لم يتم العثور على تصنيف",
    Review_Rating: "ملاحظات",
    Bank_Transfer_Request: "طلب التحويل البنكي",
    Please_verify_document: "التحقق من مستند التحويل البنكي",
    Store_Company: "شركة المتجر",
    Supplier_Company: "شركة المورد",
    Created_By: "تم الإنشاء بواسطة",
    Store_Id: "معرف المتجر",
    Go_with_new_card: "الدفع ببطاقة جديدة",
    Payment_with_saved_card: "الدفع بالبطاقة المحفوظة",
    Get_In_Touch: "تواصل معنا",
    Firstname: "الاسم الأول",
    Lastname: "الاسم الأخير",
    Message: "الرسالة",
    Get_In_Touch_Details: "تفاصيل التواصل معنا",
    Make_Address_Default: "تعيين كافتراضي",
    you_want_to_logout: "تريد تسجيل الخروج",
    Assigned_Role: "الدور المعين",
    Approx: "تقريبًا.",
    Delete_Notification_Alert: "يرجى تحديد إشعار واحد على الأقل.",
    Edited: "(معدل)",
    Is_Active: "نشط",
    Check_email: "تحقق من بريدك الإلكتروني",
    Please_Check_Email:
      "يرجى التحقق من عنوان البريد الإلكتروني المرتبط باسم المستخدم الخاص بك",
    Reset_Link: "للحصول على الرابط لإعادة تعيين كلمة المرور الخاصة بك",
    Dont_receive_Email: "لم تتلق بريدًا إلكترونيًا؟",
    Resend_Email: "إعادة إرسال البريد الإلكتروني",
    back_to_sign_in: "العودة إلى تسجيل الدخول",
    Set_New_Password: "تعيين كلمة مرور جديدة",
    Your_New_Password:
      "يجب أن تكون كلمة المرور الجديدة مختلفة عن كلمات المرور التي تم استخدامها سابقًا.",
    Reset_Password: "إعادة تعيين كلمة المرور",
    Tooltip_Refresh: "انقر هنا للتحديث",
    Username_Error: "يرجى إدخال اسم المستخدم الخاص بك",
    Email_Error: "يرجى إدخال عنوان بريد إلكتروني",
    Valid_Email: "يرجى إدخال عنوان بريد إلكتروني صالح",
    Cr_Number_Error: "يرجى إدخال رقم السجل التجاري",
    Cr_Alphanumeric: "يجب أن يكون رقم السجل التجاري أبجديًا رقميًا",
    Cr_Positive: "يجب أن يكون رقم السجل التجاري موجبًا",
    Cr_Length: "يجب أن يتكون رقم السجل التجاري من 10 أرقام بالضبط",
    Tax_Number_Error: "يجب أن يكون الرقم الضريبي رقمًا",
    Tax_Positive: "يجب أن يكون الرقم الضريبي موجبًا",
    Tax_Length: "يجب أن يتكون الرقم الضريبي من 9 أرقام بالضبط.",
    Comapny_Name_Error: "يرجى إدخال اسم الشركة",
    Phone_Error: "يرجى إدخال رقم الهاتف",
    Phone_Number_Error: "يجب أن يكون رقم الهاتف رقمًا",
    Phone_Positive: "يجب أن يكون رقم الهاتف موجبًا",
    Phone_Length: "يجب أن يتراوح طول أرقام الهاتف من 6 إلى 14 حرفًا.",
    Iban_Number_Error: "يرجى إدخال رقم الآيبان",
    Iban_Alphanumeric: "يجب أن يكون رقم الآيبان أبجديًا رقميًا",
    Iban_Positive: "يجب أن يكون رقم الآيبان موجبًا",
    Iban_Length: "يجب أن يتكون رقم الآيبان من 22 رقم بالضبط",
    Password_Error: "يرجى إدخال كلمة المرور الخاصة بك",
    Password_Length: "يجب أن تتكون كلمة المرور من 8 على الأقل",
    Password_Creds:
      "يجب أن تتضمن كلمة المرور حرفًا كبيرًا واحدًا على الأقل، وحرفًا خاصًا، ورقمًا",
    Address_Data: "العنوان مطلوب",
    Address_Country: "البلد مطلوب",
    Address_State: "الولاية مطلوبة",
    Address_City: "المدينة مطلوبة",
    Address_Postal_code: "الرمز البريدي مطلوب",
    Staff_Role: "يرجى إدخال الدور",
    Staff_Status: "الحالة مطلوبة",
    Role_Error: "يرجى إدخال اسم الدور",
    Role_Status: "يرجى إدخال الحالة",
    Branch_Error: "يرجى إدخال اسم الفرع",
    Brach_User: "يجب تعيين مستخدم واحد على الأقل للفرع",
    Reviews_Error: "المراجعات مطلوبة",
    Pur_Req_Expiry: "يرجى إدخال تاريخ الانتهاء",
    Pur_Req_Category: "يرجى إدخال التصنيف",
    Pur_Req_Product: "يرجى إدخال المنتج",
    Pur_Req_Quantity: "يرجى إدخال الكمية",
    Quantity_Positive: "يجب أن تكون الكمية رقمًا موجبًا",
    Pur_Req_Price: "يرجى إدخال السعر",
    Price_Number: "يجب أن يكون السعر رقمًا",
    Price_Positive: "يجب أن يكون السعر موجبًا",
    Pur_Req_Description: "يرجى إدخال الوصف",
    Bank_Transfer_Error: "إيصال التحويل البنكي مطلوب",
    Support_Subject: "يرجى إدخال الموضوع",
    Image_Size_Error:
      "يجب ألا يزيد حجم الصورة عن 10 ميجابايت، ويدعم ملفات png، svg.",
    Image_Format_Error: "jpeg فقط",
    Vat_Req_Error: "الرسوم مطلوبة",
    Vat_Positive_Error: "يجب أن تكون الرسوم رقمًا موجبًا",
    Image_Unsupported_Format: "تنسيق ملف الصورة غير مدعوم.",
    Company_Status: "حالة الشركة مطلوبة",
    Image_Require_Error: "الصورة مطلوبة",
    English_Name_Required: "يرجى إدخال الاسم الإنجليزي",
    Arabic_Name_Required: "يرجى إدخال الاسم العربي",
    Urdu_Name_Required: "يرجى إدخال الاسم الأردي",
    Category_Appropriate_Error: "يرجى تحديد التصنيف المناسب",
    Measurement_Appropriate_Error: "يرجى تحديد القياس المناسب",
    Name_Error: "الاسم مطلوب",
    Overview_Error: "النظرة العامة مطلوبة",
    Descripton_Error: "الوصف مطلوب",
    Specification_Error: "المواصفات مطلوبة",
    Featured_Image_Error: "يرجى تحديد صورة واحدة كصورة مميزة",
    Unit_Name_Required_Error: "اسم الوحدة مطلوب",
    Unit_Short_Name_Required: "الاسم المختصر للوحدة مطلوب",
    Banner_Heading_Required_Error: "عنوان الشعار مطلوب",
    Banner_Sub_Heading_Required_Error: "العنوان الفرعي للشعار مطلوب",
    Button_Text_Required_Error: "نص الزر مطلوب",
    Collection_Required_Error: "المجموعة مطلوبة",
    Footer_Icon_Required_Error: "نص أيقونة التذييل مطلوب",
    Product_Count_Required_Error: "عدد المنتجات مطلوب",
    Category_Heading_Required_Error: "عنوان التصنيف مطلوب",
    Category_Sub_Heading_Required_Error: "العنوان الفرعي للتصنيف مطلوب",
    Category_Required_Error: "التصنيف مطلوبة",
    Community_Heading_Required_Error: "عنوان المجتمع مطلوب",
    Community_Sub_Heading_Required_Error: "العنوان الفرعي للمجتمع مطلوب",
    Title_Required_Error: "العنوان مطلوب",
    Purchase_Heading_Required_Error: "عنوان الشراء مطلوب",
    Purchase_Sub_Heading_Required_Error: "العنوان الفرعي للشراء مطلوب",
    Image_Heading_Required_Error: "عنوان الصورة مطلوب",
    Feature_Heading_Required_Error: "عنوان الميزة مطلوب",
    Feature_Sub_Heading_Required_Error: "عنوان الميزة مطلوب",
    Date_Required_Error: "التاريخ مطلوب",
    Product_Title_Required_Error: "عنوان المنتج مطلوب",
    Product_Sub_Title_Required_Error: "العنوان الفرعي للمنتج مطلوب",
    Price_Required_Error: "السعر مطلوب",
    Price_Number_Error: "يجب أن يكون السعر رقمًا",
    Price_Positive_Error: "يجب أن يكون السعر موجبًا",
    Rating_Required_Error: "التقييم مطلوب",
    Product_Button_Text_Required_Error: "نص زر المنتج مطلوب",
    Product_Button_Link_Required_Error: "رابط زر المنتج مطلوب",
    Offer_Heading_Required_Error: "عنوان العرض مطلوب",
    Offer_Sub_Heading_Required_Error: "العنوان الفرعي للعرض مطلوب",
    Description_Required_Error: "الوصف مطلوب",
    File_Size_Error: "حجم الملف كبير",
    Postal_Code_Alphanumeric: "يجب أن يكون الرمز البريدي أبجديًا رقميًا",
    Postal_Code_Number: "يجب أن يكون الرمز البريدي رقمًا",
    Postal_Code_Length: "يجب أن يتراوح الرمز البريدي بين 6 إلى 10",
    Date_Error: "التاريخ مطلوب",
    Date_Invalid_Error: "التاريخ غير صالح",
    Date_Passed_Error: "انتهى تاريخ النهاية",
    Units_Error: "يرجى إدخال الوحدات",
    Unit_Number_Error: "يجب أن تكون الوحدات رقمًا",
    Unit_Positive_Error: "يجب أن تكون الوحدات موجبة",
    Base_Price_Error: "يرجى إدخال السعر الأساسي",
    Base_Price_Number_Error: "يجب أن يكون السعر الأساسي رقمًا",
    Base_Price_Positive_Error: "يجب أن يكون السعر الأساسي موجبًا",
    Image_Error: "يرجى إدخال الصورة",
    Offered_Price_Error: "يرجى إدخال السعر المقدم",
    Offered_Price_Positive_Error: "يجب أن يكون السعر المقدم رقمًا موجبًا",
    Charges_Number_Error: "يجب أن يكون رقم الرسوم رقمًا",
    Charges_Positive_Error: "يجب أن يكون رقم الرسوم موجبًا",
    Subtotal_Error: "يرجى إدخال المجموع الفرعي",
    Review_Rating_Details: "تفاصيل المراجعة والتقييم",
    StoreName: "اسم المتجر",
    Ratings: "التقييمات",
    Author_Name_Error: "اسم المؤلف مطلوب",
    Title_Name_Error: "العنوان مطلوب",
    Blog_Content_Error: "محتوى المدونة مطلوب",
    Slug_Error: "الرابط المختصر مطلوب",
    Cover_Image_Error: "صورة الغلاف مطلوبة",
    Author_Image_Error: "صورة المؤلف مطلوبة",
    Page_Banner_Error: "بانر الصفحة مطلوبة",
    Page_Error: "الصفحة مطلوبة",
    Heading_Error: "العنوان مطلوب",
    Question_Error: "السؤال مطلوب",
    Answer_Error: "الإجابة مطلوبة",
    Page_Banner_Reuired_Error: "بانر الصفحة مطلوبة",
    Collection_Value_Required_Error: "قيمة المجموعة مطلوبة",
    Collecion_Text_Required_Error: "نص المجموعة مطلوب",
    Choose_Header_Required_Error: 'عنوان "لماذا نحن" مطلوب',
    Choose_Description_Required_Error: 'وصف "لماذا نحن" مطلوب',
    Team_Position_Required_Error: "وظيفة الفريق مطلوب",
    Team_Name_Required_Error: "اسم الفريق مطلوب",
    Team_Header_Required_Error: "عنوان الفريق مطلوب",
    Team_Description_Required_Error: "وصف الفريق مطلوب",
    Customer_Name_Required_Error: "اسم العميل مطلوب",
    Customer_Designation_Required_Error: "وظيفة العميل مطلوبة",
    Heading_Required_Error: "العنوان مطلوب",
    Descripton_Required_Error: "الوصف مطلوب",
    Card_Descripton_Required_Error: "وصف البطاقة مطلوب",
    Page_Required_Error: "الصفحة مطلوبة",
    Page_Title_Required_Error: "عنوان الصفحة مطلوب",
    Sub_Heading_Required_Error: "العنوان الفرعي مطلوب",
    Sub_Title_Required_Error: "العنوان الفرعي مطلوب",
    Firstname_Required_Error: "الاسم الأول مطلوب",
    Lastname_Required_Error: "الاسم الأخير مطلوب",
    Message_Required_Error: "الرسالة مطلوبة",
    Confirm_Password_Error: "تأكيد كلمة المرور غير متطابق",
    Confirm_Password_Req_Error: "تأكيد كلمة المرور مطلوب!",
    Email_Username_Error: "البريد الإلكتروني أو اسم المستخدم مطلوب",
    Card_Name_Error: "اسم البطاقة مطلوب",
    Password_Updated_Toast: "تم تحديث كلمة المرور بنجاح",
    User_Create_Toast: "تم إنشاء المستخدم بنجاح",
    Staff_Create_Toast: "تم إضافة عضو الفريق بنجاح",
    Create_Error_Toast: "فشل إنشاء المستخدم",
    Update_Toast: "تم تحديث التفاصيل الشخصية بنجاح",
    Delete_Error_Toast: "لا يُسمح بالحذف والنقل مع نفس المستخدم",
    Get_Toast: "تم عرض المستخدم بنجاح",
    Get_All_Toast: "تم عرض المستخدمين بنجاح",
    Unique_Error_Toast: "المستخدم موجود بالفعل",
    Unique_Email_Error_Toast: "البريد الإلكتروني/اسم المستخدم موجود بالفعل",
    Unique_Username_Error_Toast: "المستخدم موجود بالفعل بهذا الاسم",
    Not_Found_Toast: "المستخدم غير موجود",
    Not_Found_User_Toast: "المستخدم غير موجود",
    Catch_Response_Toast: "حدث خطأ ما!!",
    Login_Toast: "لقد قمت بتسجيل الدخول بنجاح",
    Username_Or_Password_Does_Not_Match_Toast:
      "اسم المستخدم أو كلمة المرور غير متطابقين",
    Store_Exists_Toast:
      "صاحب المتجر موجود بالفعل لاسم الشركة/رقم السجل التجاري",
    Supplier_Exists_Toast: "المورد موجود بالفعل لاسم الشركة/رقم السجل التجاري",
    Mail_Sent_Toast:
      "تم إرسال البريد الإلكتروني بنجاح. يرجى التحقق من بريدك الإلكتروني لإعادة تعيينه.",
    Profile_Updated_Toast: "تم تحديث الملف الشخصي بنجاح",
    Default_Address_Updated_Toast: "تم تحديث العنوان الافتراضي بنجاح",
    Address_Deleted_Toast: "تم حذف العنوان بنجاح",
    Address_Delete_Failed_Toast: "فشل في حذف العنوان",
    Address_Is_Default_Toast: "لا يمكن حذف العنوان الافتراضي",
    Address_Update_Failed_Taost: "فشل في تحديث العنوان",
    Profile_Update_Success_Toast: "تم تحديث الملف الشخصي بنجاح",
    Address_Fetched_Toast: "تم عرض العنوان بنجاح",
    Address_Created_Toast: "تم إنشاء العنوان بنجاح",
    User_Deleted_Toast: "تم حذف المستخدم بنجاح",
    User_Assigned_To_Branch_Toast: "لا يمكن حذف المستخدم المرتبط بفرع",
    Wrong_User_Or_Password_Toast:
      "البريد الإلكتروني الذي تحاول استخدامه غير مسجل لدينا",
    User_Does_Not_Exists_Toast:
      "المستخدم غير موجود باسم المستخدم/البريد الإلكتروني هذا",
    Password_Does_Not_Match_Toast:
      "كلمة المرور غير صحيحة. يرجى المحاولة مرة أخرى.",
    Someone_Already_Registered_Toast:
      "شخص ما قد سجل بالفعل بنفس الشركة/رقم السجل التجاري",
    User_Inactive_Toast: "المستخدم غير نشط لذا لا يمكنك تسجيل الدخول",
    Admin_Details_Fetched_Toast: "تم عرض تفاصيل المسؤول بنجاح",
    Store_Data_Updated_Toast: "تم تحديث معلومات المتجر بنجاح!",
    Store_Data_Deleted_Toast: "تم حذف معلومات المتجر بنجاح!",
    Cms_Pages_Fetched_Toast: "تم عرض صفحات إدارة المحتوى بنجاح!",
    Cms_Specific_Page_Fetched_Toast: "تم عرض صفحة إدارة المحتوى بنجاح!",
    Cms_Page_Updated_Toast: "تم تحديث صفحة إدارة المحتوى بنجاح!",
    Cms_Blogs_Fetched_Toast: "تم عرض المدونات بنجاح!",
    Cms_Specific_Page_Updated_Toast:
      "تم تحديث صفحة إدارة المحتوى المحددة بنجاح!",
    Cms_Unauthorize_Update_Request: "أنت غير مخول بالتحديث",
    Cms_Review_Fetched_Toast: "تم عرض قائمة المراجعات بنجاح!",
    Slug_Exists_Toast: "الرابط المختصر موجود بالفعل",
    Cms_Datasource_Count_Toast: "تم عرض عدد مصادر البيانات بنجاح",
    Category_Fetched_Toast: "تم عرض التصنيف بنجاح",
    Error_Toast: "مطلوب رمز للمصادقة",
    Wrong_Token_Issue_Toast: "يرجى الاتصال بالمسؤول",
    Token_Invalid_Toast: "الرمز منتهي الصلاحية!",
    Cms_Blog_Not_Found_Toast: "أنت غير مخول بالتحديث",
    Message_Sent_Toast: "تم إرسال رسالتك بنجاح",
    Fetch_Data_Toast: "تم عرض بيانات التواصل بنجاح",
    Contact_Deleted_Toast: "تم حذف رسالة الاتصال بنجاح",
    Faq_Added_Toast: "تم إضافة الأسئلة الشائعة بنجاح",
    Faq_Data_Toast: "تم عرض بيانات الأسئلة الشائعة بنجاح",
    Faq_Fetched_Toast: "تم عرض الأسئلة الشائعة بنجاح",
    Faq_Updated_Toast: "تم تحديث بيانات الأسئلة الشائعة بنجاح",
    Faq_Deleted_Toast: "تم حذف الأسئلة الشائعة بنجاح",
    Unit_Added_Toast: "تم إضافة وحدة قياس بنجاح",
    Unit_Already_Exist_Toast: "وحدة القياس موجودة بالفعل",
    All_Units_Fetched_Toast: "تم عرض جميع وحدات القياس بنجاح",
    Unit_Fetched_Toast: "تم عرض وحدة القياس بنجاح",
    Unit_Unauthorize_Update_Request_Toast: "أنت غير مخول بالتحديث",
    Unit_Updated_Toast: "تم تحديث وحدة القياس بنجاح",
    Unit_Deleted_Toast: "تم حذف وحدة القياس بنجاح",
    Unit_Already_Assigned_Toast:
      "هذه الوحدة مستخدمة مع منتجات متعددة، لذلك لا يمكنك حذفها",
    Category_Added_Toast: "تم إضافة التصنيف بنجاح",
    Category_Data_Toast: "تم عرض بيانات التصنيف بنجاح",
    Category_Updated_Toast: "تم تحديث التصنيف بنجاح",
    Category_Deleted_Toast: "تم حذف التصنيف بنجاح",
    Category_Could_Not_Find_Toast:
      "لا يمكن العثور على التصنيف في قاعدة البيانات",
    Category_Duplicated_Toast: "لا يسمح بتكرار التصنيف",
    Category_Already_Exist_Toast: "التصنيف موجود بالفعل",
    Category_Wise_Product_Toast: "تم عرض المنتج بنجاح",
    Update_Category_Inactive_Toast: "التصنيف المحدد غير نشط حاليًا",
    Category_Product_Inactive_Toast: "المنتج المحدد غير نشط",
    Category_Product_Deleted_Toast: "المنتج غير متاح",
    Category_Can_Not_Deleted_Toast:
      "هذه التصنيف مستخدم مع منتجات متعددة، لذلك لا يمكنك حذفه",
    Product_Added_Toast: "تم إضافة المنتج بنجاح",
    Product_Data_Toast: "تم عرض بيانات المنتجات بنجاح",
    Products_Fetched_Toast: "تم عرض المنتجات بنجاح",
    Products_Fetched_Failed_Toast: "فشل في عرض المنتجات",
    Product_Updated_Toast: "تم تحديث المنتج بنجاح",
    Products_Updated_Failed_Toast: "فشل في تحديث المنتج",
    Product_Deleted_Toast: "تم حذف المنتج بنجاح",
    Product_Deleted_Failed_Toast: "فشل في حذف المنتج",
    Product_Could_Not_Find_Toast: "لا يمكن العثور على المنتج في قاعدة البيانات",
    Product_Duplicated_Toast: "لا يسمح بتكرار المنتج",
    Product_Already_Exist_Toast: "المنتج موجود بالفعل بنفس التصنيف/القياس",
    Products_Add_Failed_Toast: "فشل في إضافة المنتج",
    Product_Category_Inactive_Toast: "التصنيف المحدد غير نشط حاليًا",
    Product_Measurement_Inactive_Taost: "القياس المحدد غير نشط حاليًا",
    Single_Supplier_Toast: "تم عرض المورد بنجاح",
    Failed_Single_Supplier_Toast: "فشل في عرض المورد",
    Unauthorize_Delete_Request_Taost: "أنت غير مخول بالحذف",
    Supplier_Deleted_Toast: "تم حذف المورد بنجاح",
    Supplier_Updated_Toast: "تم تحديث المورد بنجاح",
    Supplier_Data_Fetched_Toast: "تم عرض بيانات المورد بنجاح",
    Supplier_Product_Already_Exisit_Toast:
      "المنتج موجود بالفعل بسعره ومدته بنفس الوحدات",
    Supplier_Product_Fetched_Toast: "تم عرض المنتجات بنجاح",
    Supplier_Product_Added_Toast: "تم إضافة المنتج بنجاح",
    Cat_Wise_Product_Fetched_Toast: "تم عرض المنتجات حسب التصنيف بنجاح",
    Supplier_Product_Deleted_Toast: "تم حذف المنتج بنجاح",
    Single_Supplier_Product_Fetched_Toast: "تم عرض منتج واحد بنجاح",
    Supplier_Product_Updated_Toast: "تم تحديث المنتج بنجاح",
    Supplier_Product_Update_Failed_Toast: "فشل في تحديث المنتج",
    Suppier_Product_Fetched_Failed_Toast: "فشل في عرض المنتجات",
    Supplier_Product_Not_Found_Toast: "المورد ليس لديه منتجاته",
    Request_Created_Toast: "تم إرسال طلب الشراء بنجاح!",
    Purchase_Request_Fetched_Toast: "تم عرض طلبات الشراء بنجاح",
    Purchase_Request_Not_Fetched_Toast: "صاحب المتجر لا يملك طلبات الشراء",
    Purchase_Request_Deleted_Toast: "تم حذف طلب الشراء بنجاح",
    Purchase_Request_Delete_Failed_Toast: "فشل في حذف طلب الشراء",
    Purchase_Request_Status_Update_Failed_Toast:
      "فشل في تحديث حالة طلب الشراء!!",
    Purchase_Request_Status_Updated_Toast: "تم تحديث حالة طلب الشراء بنجاح!!",
    Purchase_Request_Quote_Deleted_Toast: "تم حذف العرض بنجاح",
    Purchase_Request_Quote_Rejected_Toast: "تم رفض العرض بنجاح",
    Purchase_Request_Quote_Not_Found_Toast: "لا يمكن العثور على عرض طلب الشراء",
    Purchase_Request_Fetch_Failed_Toast: "غير قادر على عرض طلب الشراء",
    Purchase_Request_Not_Found_Toast: "لا يمكن العثور على طلب الشراء",
    Purchase_Request_Quote_Deleted_Failed_Toast: "فشل في حذف عرض طلب الشراء",
    Supplier_Quote_Not_Found_Toast: "لم يتم العثور على عروض الموردين",
    Purchase_Request_Quote_Accepted_Toast: "تم قبول العرض",
    Purchase_Request_Created_Toast: "تم إنشاء تفاصيل طلب الشراء بنجاح",
    Purchase_Request_Updated_Toast: "تم تحديث تفاصيل طلب الشراء بنجاح",
    Quotes_Created_Toast: "تم إنشاء العرض بنجاح!",
    Quotes_Updated_Toast: "تم تحديث العرض بنجاح!",
    Product_Fetched_Toast: "تم عرض المنتج بنجاح!",
    Quote_Does_Not_Found_Toast: "لا يمكن العثور على العرض",
    Permission_Fetched_Toast: "تم عرض الإذن بنجاح!",
    Order_Created_Toast: "تم إنشاء الطلب بنجاح",
    Order_Updated_Toast: "تم تحديث الطلب بنجاح",
    Order_Deleted_Toast: "تم حذف الطلب بنجاح",
    Order_Fetched_Toast: "تم عرض الطلب بنجاح",
    Support_Ticket_Created_Toast: "تم إنشاء تذكرة الدعم بنجاح",
    Support_Ticket_Updated_Toast: "تم تحديث تذكرة الدعم بنجاح",
    Support_Ticket_Deleted: "تم حذف تذكرة الدعم بنجاح",
    Support_Ticket_Fetched_Toast: "تم عرض تذاكر الدعم بنجاح",
    Support_Ticket_Closed_Toast: "تم إغلاق تذكرة الدعم بنجاح",
    Support_Ticket_Send_Reply_Toast: "تم إرسال رد التذكرة بنجاح",
    Chat_Created_Toast: "تم إنشاء الدردشة بنجاح",
    Chat_Fetched_Toast: "تم عرض الدردشة بنجاح",
    PaymentCharge_Created_Toast: "تم إنشاء رسوم الدفع بنجاح",
    PaymentCharge_Updated_Toast: "تم تحديث رسوم الدفع بنجاح",
    PaymentCharge_Deleted_Toast: "تم حذف رسوم الدفع بنجاح",
    PaymentCharge_Fetched_Toast: "تم عرض رسوم الدفع بنجاح",
    Payement_Failed_Toast: "غير قادر على عرض طلب الدفع",
    Payment_Details_Not_Found_Toast: "لا يمكن العثور على رسوم الدفع",
    General_Count_Report_Fetch_Toast: "تم عرض البيانات العامة بنجاح",
    Store_Not_Exisit_Toast: "المتجر غير موجود",
    Store_Data_Fetched_Toast: "تم عرض البيانات بنجاح",
    Reviews_Fetched_Toast: "تم عرض المراجعات والتقييمات بنجاح",
    Reviews_Added_Toast: "تم إضافة المراجعات والتقييمات بنجاح",
    Reviews_Updated_Toast: "تم تحديث المراجعات والتقييمات بنجاح",
    Role_Added_Toast: "تم إنشاء الدور بنجاح",
    Role_Updated_Toast: "تم تحديث الدور بنجاح",
    Staff_Member_Added_Toast: "تم إنشاء عضو الفريق بنجاح",
    Staff_Member_Updated_Toast: "تم تحديث عضو الفريق بنجاح",
    Staff_Member_Deleted_Toast: "تم حذف عضو الفريق بنجاح",
    Role_Deleted_Toast: "تم حذف الدور بنجاح",
    Role_Already_Exists_Toast: "الدور موجود بالفعل",
    Role_Already_Assigned_Toast:
      "لا يمكن أن يكون الدور غير نشط لأنه مخصص لعضو الفريق",
    IBAN_Number_Toast: "يرجى إدخال رقم الآيبان قبل إنشاء عضو الفريق",
    Card_Added_Toast: "تم حفظ البطاقة بنجاح",
    Card_Fetched_Toast: "تم عرض البطاقات بنجاح",
    Card_Updated_Toast: "تم تحديث البطاقة بنجاح",
    Card_Deleted_Toast: "تم حذف البطاقة بنجاح",
    Unique_Card_Error_Toast: "البطاقة موجودة بالفعل",
    Card_Does_Not_Belongs_To_Customer_Toast: "البطاقة لا تنتمي إلى أي عميل",
    Failed_Card_Delete_Toast: "فشل في حذف البطاقة",
    Notification_Deleted_Toast: "تم حذف الإشعار بنجاح",
    Product_Cannot_Be_Repeated_Toast:
      "لا يمكن تكرار المنتجات بنفس الاسم / القياس",
    Cms_Updated_Toast: "تم تحديث البيانات بنجاح",
    Cms_Deleted_Toast: "تم حذف البيانات بنجاح",
    Cms_Created_Toast: "تم إنشاء البيانات بنجاح",
    Company_Inactive_Toast: "هذه الشركة غير نشطة، لذلك لا يمكنك تسجيل الدخول",
    Branch_Fetched_Toast: "تم عرض الفرع بنجاح",
    Branch_Created_Toast: "تم إنشاء الفرع بنجاح",
    Branch_Updated_Toast: "تم تحديث الفرع بنجاح",
    Branch_Deleted_Toast: "تم حذف الفرع بنجاح",
    Vat_Upadated_Toast: "تم تحديث رسوم ضريبة القيمة المضافة بنجاح",
    Vat_Get_Toast: "تم عرض رسوم ضريبة القيمة المضافة بنجاح",
    Correct_Mail_Toast: "يرجى إدخال البريد الإلكتروني الصحيح",
    Transaction_Fetched_Toast: "تم عرض تفاصيل المعاملات بنجاح",
    Weekly_Graph_Detail_Toast: "تفاصيل الرسم الأسبوعي",
    Category_Graph_Detail_Toast: "تفاصيل الرسم البياني للتصنيف",
    Mail_Send_Toast: "تم إرسال البريد بنجاح",
    Success_Toast: "تم عرض البيانات بنجاح!",
    Blog_Image_Upload_Toast: "تم تحميل صورة المدونة بنجاح",
    Purchase_Request_Detail_Not_Found_Toast:
      "لم يتم العثور على تفاصيل طلب الشراء",
    Order_Added_Toast: "تم إضافة الطلب بنجاح",
    Data_Fetched_Toast: "تم عرض البيانات بنجاح",
    Unable_To_Delete_User_Toast:
      "غير قادر على الحذف، يوجود مستخدم مستند لهذا الدور",
    User_Exist_Toast: "المستخدم موجود بالفعل",
    Record_Exist_Toast: "هذه البيانات موجودة بالفعل في سجلاتنا",
    Not_Find_Product_Toast: "لا يمكن العثور على أي منتج",
    Enter_New_Password: "أدخل كلمة مرور جديدة",
    ReEnter_New_Password: "أعد إدخال كلمة مرور جديدة",
    Password_And_Security: "كلمة المرور والأمان",
    Latest_Products: "المنتجات الأحدث",
    UOM: "القياسات",
    My_Profile: "ملفي الشخصي",
    Manage_Password: "إدارة كلمة المرور",
    Bank_Transfer_Initiated_Alert:
      "تم بدء التحويل البنكي وهو الآن قيد الموافقة من المسؤول. شكرًا لتفهمك.",
    Add_Ranking: "إضافة تصنيف",
    Edit_Ranking: "تعديل التصنيف",
    Bank_Transfer_Instruction: "تعليمات التحويل البنكي",
    Branch_Delete_Text:
      "هل تريد حقًا حذف هذه السجلات؟ سيتم إلغاء تعيين المستخدمين لهذا الفرع",
    Select_Card: "اختر البطاقة",
    Enter_Offer_Price: "أدخل سعر العرض",
    Quantity_Error_Message: "يجب أن تكون الكمية أكبر من 0",
    Quantity_Number_Error: "يجب أن تكون الكمية رقمًا",
    Quantity_Positive_Error: "يجب أن تكون الكمية موجبة",
    Requested_Price_Required_Error: "السعر الطلب مطلوب",
    Requested_Price_Number_Error: "يجب أن يكون سعر الطلب رقمًا",
    Requested_Price_Positive_Error: "يجب أن يكون سعر الطلب موجبًا",
    Quotes_Accepted_Required_Eror: "العروض المقبولة مطلوبة",
    Quantity_Required_Error: "الكمية مطلوبة",
    Payment_Mode: "طريقة الدفع",
    Approve: "موافقة",
    Decline: "رفض",
    Approved: "تمت الموافقة",
    Thank_You: "شكرًا لك",
    Received_Your_Message:
      "لقد تلقينا رسالتك وسنرد في غضون 24 ساعة. في هذه الأثناء، تأكد من متابعتنا على وسائل التواصل الاجتماعي!",
    Low_To_High: "السعر: من الأقل إلى الأعلى",
    High_To_Low: "السعر: من الأعلى إلى الأقل",
    Recently_Added: "تمت الإضافة مؤخرًا",
    All_Prices: "جميع الأسعار",
    Sort_By: "الترتيب حسب",
    Price_Range: "نطاق السعر",
    Verification_Status: "حالة التحقق",
    Activation_Status: "حالة التفعيل",
    Passed_Date: "لا يمكنك تحديد تاريخ سابق",
    Role_First_Error: "يرجى إنشاء دور أولاً",
    See_All_Notification: "حدد جميع الإشعارات",
    Offer_Price_Message: "يجب ألا يكون سعر العرض أقل من السعر الأساسي",
    Branch_Exists_Toast: "الفرع موجود بالفعل",
    Store_Already_Exists_Toast: "المتجر موجود بالفعل",
    Choose_Category: "اختر التصنيف",
    Choose_Status: "اختر الحالة",
    Choose_Country: "اختر البلد",
    Choose_State: "اختر الولاية",
    Choose_City: "اختر المدينة",
    Choose_Role: "اختر الدور",
    Select_Product: "حدد المنتج",
    Same_Category_Error: "لا يمكن تكرار نفس التصنيف",
    Enter_Vat_Charges_Error: "أدخل رسوم القيمة المضافة",
    Total_Suppliers: "إجمالي الموردين",
    Total_Store_Owners: "إجمالي أصحاب المتاجر",
    Total_Products: "إجمالي المنتجات",
    Total_Categories: "إجمالي التصنيفات",
    Total_Transactions: "إجمالي المعاملات",
    Total_BankTransfers: "إجمالي التحويلات البنكية",
    Reject: "رفض",
    Reject_Msg: "هل تريد حقًا رفض هذا الرمز",
    Enter_Current_Password: "أدخل كلمة المرور الحالية",
    Future_Date: "لا يمكن إدخال تاريخ مستقبلي",
    Enter_Payment_Id: "أدخل معرف الدفع",
    Orders_By_Date: "الطلبات حسب التاريخ",
    Product_Details: "تفاصيل المنتج",
    View_OrderDetail: "عرض تفاصيل الطلب",
    Less_than_Expiry_Date: "أقل من تاريخ الانتهاء",
    Enter_Request_ID: "أدخل معرف الطلب",
    Purchase_Request_After_Date: "تاريخ طلب الشراء",
    Search_Filter: "تصفية البحث",
    Apply: "تطبيق",
    Reset: "إعادة تعيين",
    Enter_Order_Id: "أدخل معرف الطلب",
    Enter_Amount: "أدخل المبلغ",
    Enter_Ticket_Id: "أدخل معرف التذكرة",
    Tickets_By_Date: "التذاكر حسب التاريخ",
    Enter_Purchase_Req_Id: "أدخل معرف طلب الشراء",
    Enter_Company_Name: "أدخل اسم الشركة",
    Category_Name: "اسم التصنيف",
    Enter_Supplier_Company_Name: "أدخل اسم شركة المورد",
    Enter_Store_Company_Name: "أدخل اسم شركة المتجر",
    Product_Title: "المنتجات",
    Grand_Total: "المجموع النهائي",
    Download_Quotation: "تنزيل عرض السعر",
    Enter_Quantity: "أدخل الكمية",
    Has_Offers: "يحتوي على عروض؟",
    Enter_Charges: "أدخل الرسوم",
    tooltip_another_req:
      "فعل هذا الخيار إذا كنت على ما يرام مع الحصول على منتج من علامة تجارية أخرى لهذا العرض",
    tooltip_req: "فعل هذا الخيار لنحصل على العروض للطلب بأكمله",
    Reason_for_Cancel_Order: "سبب إلغاء الطلب",
    Enter_Reason: "أدخل السبب",
    Replacement_Of_Order_Product: "استبدال منتج الطلب",
    Replace_Product: "طلب استبدال المنتج",
    Regular_Orders: "الطلبات العادية",
    Replacement_Orders: "طلبات الاستبدال",
    Reason_Error: "السبب مطلوب",
    Send_Replacement_Request: "إرسال طلب الاستبدال",
    Product_status: "حالة المنتج",
    Replacement_Status: "حالة الاستبدال",
    Can_not_select_same_product: "لا يمكن تحديد نفس المنتج",
    Send_Refund_Request: "إرسال طلب استرداد",
    Refund_Of_Order_Product: "استرداد منتج الطلب",
    Refund_Orders: "طلبات الاسترداد",
    Refund_order_detail: "تفاصيل طلب الاسترداد",
    Replacement_order_detail: "تفاصيل طلب الاستبدال",
    Select_All: "إما قبول أو رفض جميع الطلبات",
    Supplier_Status: "حالة المورد",
    Admin_Status: "حالة المسؤول",
    cancel_tooltip: "تم إلغاء الطلب بالفعل",
    delivered_tooltip: "تم تسليم الطلب لذلك لا يمكنك إلغاؤه",
    order_tooltip: "إلغاء الطلب",
    Payment_Assurance: "هل أنت متأكد أنك قمت برد المبلغ إلى المتجر؟",
    Refundable_Amount: "المبلغ القابل للاسترداد",
    Refund_Order_Id: "معرف طلب الاسترداد",
    Refund_Order_Details: "تفاصيل طلب الاسترداد",
    Refund_Status: "حالة الاسترداد",
    Accepted_for_refund: "تم قبول الطلب لهذا الاسترداد",
    Pending_for_refund: "الطلب معلق للاسترداد",
    Rejected_for_refund: "تم رفض الطلب للاسترداد",
    Replacement_Accepted_Upto: "قبول الاستبدال حتى",
    Replace_Days_Updated: "تم تحديث أيام الاستبدال",
    Admin_Refund_Status: "حالة استرداد المسؤول",
    Supplier_Refund_Status: "حالة استرداد المورد",
    Replacement_Order_Details: "تفاصيل طلب استبدال الطلب",
    Replacement_Order_Tracking: "تتبع طلب الاستبدال",
    Replacemnt_Req_Error: "الاستبدال مطلوب",
    Refundable_Req_Error: "الاسترداد مطلوب",
    Replacemnt_Positive_Error:
      "يجب أن تكون أيام قبول الاستبدال عددًا صحيحًا موجبًا",
    Refundable_Positive_Error:
      "يجب أن تكون أيام قبول الاسترداد عددًا صحيحًا موجبًا",
    Refund_Accepted_Upto: "قبول الاسترداد حتى",
    Refund_Order_Summary: "ملخص طلب الاسترداد",
    Tooltip_order_refunded: "تم استرداد المنتجات المطلوبة.",
    ord_commission: "عمولة أورد",
    bank_commission: "عمولة البنك",
    Enter_Ord_Commission: "أدخل عمولة أورد",
    Enter_Bank_Commission: "أدخل عمولة البنك",
    Ord_Commission: "عمولة أورد مطلوبة",
    Bank_Commission: "عمولة البنك مطلوبة",
    Bank_Commission_Number: "يجب أن تكون عمولة البنك رقمًا",
    Ord_Commission_Number: "يجب أن تكون عمولة أورد رقمًا",
    Admin_Refund: "استرداد المسؤول",
    Finance_Assurance: "هل أنت متأكد أنك دفعت المبلغ للمورد؟",
    Finance_Info: "معلومات المالية",
    View_Pdf: "عرض ملف PDF",
    View_Excel: "عرض ملف Excel",
    Order_Pdf: "عرض ملف PDF لجميع الطلبات",
    Order_Excel: "عرض ملف Excel لجميع الطلبات",
    Choose_Branch: "اختر الفرع",
    Select_Branch_for_Shipping: "اختر الفرع للشحن",
    Purchase_for_branch: "الشراء للفرع",
    associated_delivery_address: "عنوان التسليم المرتبط",
    Default_Bank_Commmission_Updated: "تم تحديث عمولة البنك الافتراضية",
    Default_Ord_Commmission_Updated: "تم تحديث عمولة أورد الافتراضية",
    go_to_bank_transfer: "انتقل إلى التحويل المصرفي",
    Charges: "رسوم",
    General_Settings: "الاعدادات العامة",
    Orders_After_Date: "الطلبات بعد التاريخ",
    Role_Error_ar: "اسم الدور مطلوب للغة العربية",
    Role_Error_ur: "اسم الدور مطلوب للغة الأردية",
    Role_Name_Arabic: "اسم الدور عربي",
    Role_Name_Urdu: "اسم الدور الأردية",
  },

  en: {
    Home: "Home",
    About_Us: "About Us",
    Blog: "Blog",
    Contact_Us: "Contact Us",
    place_where: "A Place Where",
    everything: "Everything",
    special: "Special",
    header: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer`,
    collection: "Collection",
    shiiping_available: "Shipping Available",
    money_back: "100% Money Back Guarantee",
    Payment: "Payment",
    payment_process: "Secured Payment Process",
    online_support: "24/7 Online Support",
    heading_section: "heading section",
    sign_in: "Sign In",
    account_details: "Please fill your detail to access your account.",
    personal_details: "Personal Details",
    total_earning: "Total Earning",
    total_product: "Total Product",
    Total_Sent_Quotes: "Total Sent Quotes",
    General_Report: "General Report",
    Dashboard: "Dashboard",
    Store: "Store",
    Cms_pages: "Cms Pages",
    Supplier: "Supplier",
    Products: "My Offers",
    Categories: "Categories",
    Products_List: "Products List",
    Unit_of_measurement: "Unit of measurement",
    Orders: "Orders",
    Support_Ticket: "Support Ticket",
    Logout: "Logout",
    Sales_Report: "Sales Report",
    Weekly_Top_Seller_Stores: "Weekly Top Seller Stores",
    Transactions: "Transactions",
    Store_Info: "Store Info",
    CMS_Pages: "CMS Pages",
    Data_Source: "Data Source",
    Supplier_Info: "Supplier Info",
    Measurement: "Measurement",
    search: "search",
    Search: "Search here...",
    SR_NO: "Sr. No.",
    Name: "Name",
    Email: "Email",
    Company_Name: "Company Name",
    Phone_Number: "Phone Number",
    Status: "Status",
    Actions: "Action",
    Title: "Title",
    Slug: "Slug",
    Cards: "Cards",
    count: "Count",
    No_of_Products: "Product Count",
    Product_name: "Product Name",
    Image: "Image",
    Unit_Name: "Unit Name",
    Unit_Short_Name: "Unit Short Name",
    Order_Id: "Order Id",
    Order_ID: "Order Id",
    Supplier_Name: "Supplier Company",
    Store_Name: "Store Company",
    Amount: "Amount",
    Date: "Date",
    Sending_Date: "Sending Date",
    Subject: "Subject",
    Ticket_ID: "Ticket Id",
    View_more: "View More",
    Edit: "Edit",
    View: "View",
    Delete: "Delete",
    Manage_Profile: "Manage Profile",
    Purchase_Request: "Purchase Request",
    Staff: "Staff",
    All_staff: "All staff",
    All_roles: "All roles",
    Branches: "Branches",
    chat: "chat",
    Chat: "Chat",
    Notification: "Notification",
    Purchase_History: "My Orders",
    Recent_Orders: "Recent Orders",
    Purchase_Requests: "Purchase Requests",
    Edit_Purchase_Requests: "Edit Purchase Requests",
    ID: "ID",
    Payment_status: "Payment Status",
    Request_ID: "Request ID",
    Requested_Quantity: "Requested Quantity",
    Requested_Price: `Requested Price`,
    Options: "Options",
    Username: "Username",
    CR_Number: "CR Number",
    Phone: "Phone Number",
    Tax_Number: "Tax Number",
    IBAN_number: "IBAN Number",
    Photo: "Photo",
    Saved_Address: "Saved Address",
    Security: "Security",
    UPDATE_DETAILS: "UPDATE DETAILS",
    Your_password: "Your password",
    Add_New_Address: "Add New Address",
    Update_Password: "Update Password",
    Add_Product: "Add Product",
    Showing: "Showing",
    entries: "entries",
    to: "to",
    of: "of",
    Add_Staff_Member: "Add Staff Member",
    about_description: "about description",
    Create_Staff: "Create Staff",
    Edit_Staff: "Edit Staff",
    Add_new_Permission: "Add new Permission",
    All_Roles: "All Roles",
    Cancel: "Cancel",
    Close: "Close",
    Are_you_sure: "Are you sure",
    DeleteMsg: "Do you really want to delete these records?",
    Alternate_product: "Alternate product",
    Offered_Quantity: "Offered Quantity",
    Offered_Price: "Offered Price",
    Offered_deal_at: "Offered Deal At",
    Select: "Select",
    Role: "Role ",
    Save: "Save",
    Role_Name: "Role Name",
    Edit_Products: "Edit Product",
    View_All_Products: "View All Products",
    Delete_Products: "Delete Products",
    Add_Products: "Add Products",
    Manage_Products_Req: "Manage Products Req.",
    Send_Quotes: "Send Quote",
    View_Orders: "View Orders",
    Assigned_Orders: "Assigned Orders",
    Manage_Status: "Manage Status",
    Manage_Staff: "Manage Staff",
    Add_staff: "Add Staff",
    View_All_staff: "View All Staff",
    Delete_staff: "Delete Staff",
    Edit_staff: "Edit Staff",
    Add_Roles: "Add Roles",
    Edit_Roles: "Edit Roles",
    Delete_Roles: "Delete Roles",
    View_Roles: "View all Roles",
    Request_Purchase: "Request Purchase",
    Manage_Branches: "Manage Branches",
    Add_Branches: "Add Branches",
    View_All_Branches: "View All Branches",
    Edit_Branches: "Edit Branches",
    Delete_Branches: "Delete Branches",
    Ticket: "Ticket",
    Add_Ticket: "Add Ticket",
    View_All_support_tickets: "View All Support Tickets",
    Cancel_Order: "Cancel Order",
    create_Orders: "Create Orders",
    Create_Role: "Create Role",
    Update_Role: "Update Role",
    Create_Branch: "Create Branch",
    Branch_Name: "Branch Name",
    Address: "Address",
    Postal_Code: "Postal Code",
    Country: "Country",
    State: "State",
    State_Province: "State Province",
    City: "City",
    Assign_users: "Assign users",
    Choose: "Choose",
    Delete_Purchase_Request: "Delete Purchase Request",
    Track_Purchase_Request: "Track Purchase Request",
    Create_Purchase_Request: "Create Purchase Request",
    Checkout_Quote: "Checkout Quote",
    Permissions: "Permissions",
    All_Branches: "All Branches",
    Add_Branch: "Add Branch",
    Edit_Branch: "Edit Branch",
    Upload_File: "Upload File",
    CREATE_A_TICKET: "CREATE A TICKET",
    Create_A_Ticket: "Create A Ticket",
    Create_Ticket: "Create Ticket",
    choose_file: "choose file",
    View_Supplier_Info: "View Supplier Info",
    Edit_Supplier_Info: "Edit Supplier Info",
    Add_Supplier_Info: "Add Supplier Info",
    Profile_Image: "Profile Image",
    Submit: "Submit",
    Go_to_supplier_list: "Go to supplier list",
    Page: "Page",
    Blog_Page_Banner: "Blog Page Banner",
    Heading: "Heading",
    Blog_Heading: "Blog Heading",
    Blog_Description: "Blog Description",
    Remove: "Remove",
    Blogs_Section: "Blogs Section",
    Save_Changes: "Save Changes",
    Email_address: "Email address",
    Select_Category_Image: "Category Image",
    Category: "Category",
    Product_Name: "Product Name",
    Product_Overview: "Product Overview",
    Product_Description: "Product Description",
    Product_Specification: "Product Specification",
    Featured_image: "Featured image",
    Add_Product_Image: "Add Product Image",
    Go_to_Product_List: "Go to Product List",
    Add_Measurement: "Add Measurement",
    Pending_Purchase_Requests: "Pending Purchase Requests",
    Total_Products_Ordered: "Total Products Ordered",
    Total_Available_Offers: "Total Available Offers",
    Default_Shipping_Address: "Default Shipping Address",
    Shipping_Address: "Shipping Address",
    Shipping: "Shipping",
    Created_Date: "Created Date",
    Number_of_products: "Product Count",
    Branch: "Branch",
    Staff_Member: "Staff Member",
    Expiry_Date: "Expiry Date",
    Total: "Total",
    chatMsg: "Please click any chat to start the conversation...",
    Requests_Expiry: "Requests Expiry",
    Receive_offers_for_the_entire_request:
      "Receive offers for the entire request",
    another_brand:
      "Check this if you are okay to get another brand for better offer",
    Quantity: "Quantity",
    Subtotal: "Subtotal",
    Description: "Description",
    Product_Image: "Product Image",
    Product_Images: "Product Images",
    Offer_Duration: "Offer Duration",
    Units: "Units",
    Unit_Property: "Unit Property",
    Offer_Valid_Till: "Offer Valid Till",
    Password: "Password",
    My_Account: "My Account",
    Privacy_Policy: "Privacy Policy",
    Terms_Conditions: "Terms & Conditions",
    Popular_Categories: "Popular Categories",
    View_all: "View all",
    Enter_unit_name: "Enter unit name",
    Enter_unit_short_name: "Enter unit short name",
    Enter_category_name: "Enter category name",
    Enter_product_name: "Enter product name",
    Enter_CompanyName: "Enter Company Name",
    Enter_CR_Number: "Enter CR Number",
    Enter_Tax_Number: "Enter Tax Number",
    Enter_IBAN_number: "Enter IBAN number",
    Product_over: "Enter product overview",
    Product_desc: "Enter product description",
    Product_spec: "Enter product specification",
    Faq_heading: "Enter heading",
    Faq_subHeading: "Enter sub heading",
    Page_banner: "Enter page banner",
    About_heading: "Enter heading",
    About_subHeading: "Enter sub heading",
    About_buttonText: "Enter text for button",
    About_butttonLink: "Enter link for button",
    About_collectionValue: "Enter collection value",
    About_collectionText: "Enter text for collection",
    About_choose_heading: "Enter heading",
    About_choose_desc: "Enter description",
    About_card_heading: "Enter heading",
    About_card_position: "Enter text for position",
    About_card_description: "Enter description",
    About_card_name: "Enter text for name",
    Enter_Heading: "Enter heading",
    Enter_description: "Enter description",
    Page_title: "Enter page title",
    Subheading: "Enter sub heading",
    Support_title: "Enter support title",
    Enter_title: "Enter title",
    Enter_subTitle: "Enter sub title",
    Footer_text: "Enter text for footer",
    Product_count: "Enter count for product",
    community_description: "Enter description for community",
    Community_title: "Enter title for community",
    Enter_date: "Enter date",
    Price: "Product Price",
    Rating: "Rating",
    Author_name: "Enter Author name",
    Blog_title: "Enter blog title",
    Enter_Slug: "Enter slug",
    Blog_content: "Enter blog content",
    Designation: "Enter your designation",
    Enter_Customer_name: "Enter customer name",
    Customer_name: "Customer name",
    Enter_reviews: "Enter your reviews",
    Enter_question: "Enter question",
    Enter_answer: "Enter answer",
    Go_to_store_list: "Go To Store List",
    Enter_Username: "Enter Username",
    Enter_Email: "Enter Email",
    Enter_Phone: "Enter Phone",
    Enter_Password: "Enter Password",
    Enter_Rolename: "Enter Rolename",
    Enter_Branch: "Enter Branch",
    Enter_Address: "Enter Address",
    Enter_Date: "Enter Date",
    Gram: "Gram",
    Enter_Description: "Enter Description",
    Enter_Subject: "Enter Subject",
    Type_your_reply: "Type your reply",
    Enter_Overview: "Enter Overview",
    Enter_Units: "Enter Units",
    Enter_Unitproperty: "Enter Unitproperty",
    Enter_Price: "Enter Price",
    Enter_Baseprice: "Enter Baseprice",
    Enter_Status: "Enter Status",
    Enter_Specification: "Enter Specification",
    Forgot_Password: "Forgot Password",
    Add_new_Product: "Add new Product",
    Add_new_Category: "Add new Category",
    Remember_me: "Remember me",
    Measurements: "Measurements",
    Edit_Measurement: " Edit Measurement",
    Edit_Store_Info: "Edit Store Info",
    English: "English",
    Urdu: "Urdu",
    Arabic: "Arabic",
    Sub_heading: "Sub heading",
    Button_Text: "Button Text",
    Button_Link: "Button Link",
    Card_Heading: "Card Heading",
    Card_Description: "Card Description",
    Card_Name: "Card Name",
    Card_Position: "Card Position",
    Go_to_Blogs_Section: "Go to Blogs Section",
    Go_to_Reviews_Section: "Go to Reviews Section",
    Go_to_Faq_Section: "Go to Faq Section",
    subHeading: "Sub Heading",
    Faq: "Faq",
    Contact_Us_Section: "Contact Us",
    PageTitle: "Page Title",
    Title_For_Support: "Support Title",
    Card_Title: "Card Title",
    Card_SubTitle: "Card Sub Title",
    Review_Section: "Review Section",
    Review_Heading: "Review heading",
    Review_Description: "Review Description",
    Banner_Section: "Banner Section",
    Collection_Text: "Collection Text",
    Footer_Icon_Text: "Footer Icon Text",
    Product_Count: "Product Count",
    Category_Section: "Category Section",
    Category_Image_Text: "Category Image Text",
    Add_Category: "Add Category",
    Community_Network_Section: "Community Network Section",
    Network_Heading: "Network Heading",
    Network_SubHeading: "Network Sub heading",
    Community_Title: "Community Title",
    Community_Description: "Community Description",
    Purchase_Request_Section: "Purchase Request Section",
    Dynamic_Image_Section: "Dynamic Image Section",
    Image_Heading: "Image Heading",
    Image_SubHeading: "Image Sub heading",
    Featured_Product_Section: "Featured Product Section",
    SubTitle: "Sub Title",
    Add_Featured_Product_Section: "Add Featured Product Section",
    Offer_Section: "Offer Section",
    About_Blog_Section: "About Blog Section",
    Faq_Section: "Faq Section",
    Review_Card: "Add Review Card",
    Manage_Reviews: "Manage Reviews",
    Add_Review: "Add Review",
    Edit_Review: "Edit Review",
    Customer_Name: "Customer Name",
    Create_Question: "Create Question",
    Manage_Questions: "Manage Questions",
    Edit_Question: "Edit Question",
    Question: "Question",
    Answer: "Answer",
    About_Blogs_Section: "About Blogs Section",
    Reviews_Section: "Reviews Section",
    FAQ_Section: "FAQ Section",
    Our_Team: "Our Team",
    Why_to_Choose_Us: "Why to Choose Us",
    Collection_Value: "Collection Value",
    Edit_Category: "Edit Category",
    View_Category: "View Category",
    Go_to_Category_List: "Go to Category List",
    View_Product: "View Product",
    Manage_Blogs: "Manage Blogs",
    Create_Blog: "Create Blog",
    No_Blogs_Found: "No Blogs Found",
    Customer_Designation: "Customer Designation",
    Author_Name: "Author Name",
    Blog_Title: "Blog Title",
    Blog_Content: "Blog Content",
    Blog_Cover_Photo: "Blog Cover Photo",
    Blog_Author_Photo: "Blog Author Photo",
    Edit_Blog: "Edit Blog",
    Password_Recovery: "Password Recovery",
    notice:
      "Please enter your email address below. You will receive a link to reset your password",
    admin_notice: " No worries, we'll send you verification code in your mail",
    Back: "Back",
    Ranking_Level: "Ranking Level",
    Subtotal_Charges: "Subtotal + Charges",
    Offered_Count: "Offered Count",
    Supplier_ID: "Supplier ID",
    Request_Summary: "Request Summary",
    Requested_Date: "Requested Date",
    Products_Request: "Products Request",
    Total_Quotes: "Total Quotes",
    Requested_Status: "Requested Status",
    Requested_Expiry: "Requested Expiry",
    closed_req: "This Purchase Request has been closed!",
    expired_req: "This Purchase Request has been expired!",
    checkout_msg: "Are you sure you want to checkout ?",
    close_msg: "Are you sure you want to close this request?",
    no_quotes: "No Supplier has quote on this request",
    Checkout: "Checkout",
    Close_Request: "Close Request",
    EXPIRED: "EXPIRED",
    COMPLETED: "COMPLETED",
    NEW: "NEW",
    QUOTED: "QUOTED",
    ACTIVE: "ACTIVE",
    REJECTED: "REJECTED",
    Update: "Update",
    Selected_Product: "Selected Product",
    Shipping_Info: "Shipping Info",
    Confirmation: "Confirmation",
    Return_To_Shop: "Return To Shop",
    Payable_amount: "Payable amount",
    Continue_To_Shipping: "Continue To Shipping",
    Continue_To_Payment: "Continue To Payment",
    Set_as_Default: "Set as Default",
    Edit_Address: "Edit Address",
    placed: "Your Order Has been placed",
    payment_fail: "Opps your payment is failed ,you can still make it again!!",
    Order_Summary: "Order Summary",
    Item: "Item",
    Card_payment: "Card payment",
    Thank: "Thank You For Your Order",
    copy: " A copy of your order summary has been sent to",
    Order_Details: "Order Details",
    Order_date: "Order Date",
    Order_Status: "Order Status",
    Total_order_amount: "Grand Total",
    issue: "Opps Some Issue Occcured",
    Flat_shipping_rate: "Flat shipping rate",
    Return_To_Payment: "Return To Payment",
    BACK_TO_HOMEPAGE: "BACK TO HOMEPAGE",
    Purchase_History_Of_payment: "Purchase History",
    Payment_method: "Payment Method",
    Allow_Purchase_Request: "Allow Purchase Request",
    Overview: "Overview",
    Base_Price: "Base Price",
    Specification: "Specification",
    CREATE_PRODUCT: "CREATE PRODUCT",
    Add_PRODUCT: "Add Product",
    Open: "Open",
    Quoted: "Quoted",
    Closed: "Closed",
    Product: "Product",
    Other_brand_product: "Other brand product",
    Qty: "Qty",
    subtotal: "subtotal",
    charges: "Charges (If Any)",
    Update_quotes: "Update Quote",
    Order_Code: "Order Code",
    final: "Please check for the final quantity",
    Enter_quantity: "Enter quantity",
    Enter_requested_price: "Enter requested price:",
    Send_Request: "Send Request",
    Share_this_product: "Share this product",
    other_offer: "Other supplier Offers for this product",
    approx: "Approx",
    View_Details: "View Details",
    Quantity_Needed: "Quantity Needed",
    Request_Price: "Request Price/Per Qty",
    Subtotal_Price: "Subtotal Price",
    Quotes_Accepted_Upto: "Quotes Accepted Upto",
    No_Data_Found: "No Data Found",
    Type_Your_Reply: "Type your reply",
    Send_Reply: "SEND REPLY",
    Accepted: "ACCEPTED",
    Packed: "PACKAGED",
    In_Transit: "IN TRANSIT",
    Delivered: "DELIVERED",
    Delivery_Date: "Delivery Date",
    Additional_Notes: "Additional Notes",
    Invoice: "Invoice",
    Download_Invoice: "Download Invoice",
    Number: "Number",
    Total_Price: "Total Price",
    Back_To_HomePage: "Back To HomePage",
    email: "Email",
    Registration_Date: "Registraion Date",
    Rank: "Rank",
    Tax: "Tax",
    Delivery_Type: "Delivery Type",
    price: "Price",
    Enter_Postal_Code: "Enter postal code",
    Enter_Phone_Number: "Enter phone number",
    Review: "Review",
    Buy_Now: "Buy Now",
    currency: "SAR",
    Useful_links: "Useful links",
    Help: "Help",
    S_S: "ORD, Inc",
    Days: "Days",
    Hours: "Hours",
    Minutes: "Minutes",
    Seconds: "Seconds",
    Supplier_Details: "Supplier Details",
    Store_Details: "Store Details",
    Create: "Create",
    Catgory_No_Product:
      "This category has no other product please contact admin",
    All_Quotes_Required:
      "You need to provide quotes for all the requested products for this purchase request",
    One_Quote_Required:
      "You need to provide quotes for atleast 1  requested products for this purchase request",
    Mada: "MADA",
    Visa: "VISA",
    Mastercard: "MASTERCARD",
    Total_Amount_Received: "TOTAL AMOUNT RECEIVED",
    Amount_Received_From: "TOTAL AMOUNT RECEIVED FROM",
    Vat_Percentage: "Vat Percentage",
    Text: "Text",
    Category_Heading: "Category Heading",
    Enter_Subheading: "Enter Sub-heading...",
    Footer_Text: "Enter text for footer icon",
    Enter_Category_Heading: "Enter heading for category",
    Enter_product_count: "Enter product count",
    Enter_Category_SubHeading: "Enter sub heading for category",
    Enter_Heading_Network: "Enter heading for network",
    Enter_SubHeading_Network: "Enter sub heading for network",
    Enter_SubHeading_Community: "Enter sub heading for community",
    Enter_Heading_Image: "Enter heading for image",
    Enter_SubHeading_Image: "Enter sub heading for image",
    Alert: "Alert",
    Not_Found: "We couldn’t find the page you’re looking for",
    Page_Not_Found: "Page not found!",
    Sign_Up: "Sign Up",
    Confirm_Password: "Confirm Password",
    Have_Account: "already have an account?",
    Email_Username: "Email or Username",
    Enter_Email_Username: "Enter Email or Username",
    Not_Have_Account: "Don’t have an account?",
    Type_Your_Message: "Type Your Message...",
    Category_English_Name: "English Name",
    Category_Ar_Name: "Arabic Name",
    Category_Ur_Name: "Urdu Name",
    Select_Category: "Select Category",
    SEE_MORE: "VIEW MORE",
    Saved_Card_Details: "Saved Card Details",
    Add_New_Card_Detail: "Add New Card Detail",
    Direct_Bank_Transfer: "Direct Bank Transfer",
    No_Product_Found: "No Product Found",
    Bank_Transfer: "Bank Transfer",
    View_All_Blogs: "View All Blogs",
    User: "Users",
    Collection: "Collections",
    Read_More: "Read More",
    Blog_Details: "Blog Details",
    Share_Post: "Share Post",
    Follow_Me: "Follow Me",
    Enter_Firstname: "Enter your first name",
    Enter_Lastname: "Enter your last name",
    Enter_Your_Email: "Enter your email",
    Enter_Your_Phone: "Enter your phone",
    Enter_Message: "Enter message",
    Close_Ticket: "Close Ticket",
    Success: "Success",
    Failed: "Failed",
    Rating_And_Review: "Review and Rating",
    Reviews: "Reviews",
    Bank_transfer: "Bank Transfer",
    completed_req: "This Purchase Request has been completed!",
    Add_Review_And_Rating: "Add Review And Rating",
    Edit_Review_And_Rating: "Edit Review And Rating",
    Upload_Transfer_Receipt: "Upload Transfer Receipt",
    Transfer_Payment_Amount:
      "Transfer the payment amount to the following IBAN (Bank Name)",
    Order_Remain_Pending:
      "Please note that your order will remain pending until the payment is received.",
    Successful_Payment:
      "Once you have completed the bank transfer, please upload the transfer receipt. This will serve as proof of successful payment.",
    Make_Transfer: "Make the Bank Transfer",
    Vat_Tax: "VAT Tax",
    Add_Card: "Add Card",
    Add_Card_Details: "Add Card Details",
    First_Name: "First Name",
    Payment_Id: "Payment ID",
    Purchase_Request_Id: "Purchase Request Id",
    Vat_Charges: "VAT Charges",
    requested_amount: "Requested Amount",
    No_Order_Found: "No Order Found",
    No_Category_Found: "No Category Found",
    Review_Rating: "Feedback",
    Bank_Transfer_Request: "Bank Transfer Request",
    Please_verify_document: "Bank Transfer Document Verification",
    Store_Company: "Store Company",
    Supplier_Company: "Supplier Company",
    Created_By: "Created By",
    Store_Id: "Store Id",
    Go_with_new_card: "Go with new card",
    Payment_with_saved_card: "Payment with saved card",
    Get_In_Touch: "Get In Touch",
    Firstname: "Firstname",
    Lastname: "Lastname",
    Message: "Message",
    Get_In_Touch_Details: "Get In Touch Details",
    Make_Address_Default: "Mark as Default",
    you_want_to_logout: "you want to logout",
    Assigned_Role: "Assigned Role",
    Approx: "Approx. ",
    Delete_Notification_Alert: "Please select atleast 1 Notification.",
    Edited: "(Edited)",
    Is_Active: "Is Active",
    Check_email: "Check Your Email",
    Please_Check_Email:
      "Please check the email address associated with your username",
    Reset_Link: "for the link to reset your password",
    Dont_receive_Email: "Didn’t Receive an email?",
    Resend_Email: "Resend Email",
    back_to_sign_in: "Back To Sign In",
    Set_New_Password: "Set New Password",
    Your_New_Password:
      "Your new password must be different to previously used passwords.",
    Reset_Password: "Reset Password",
    Tooltip_Refresh: "Click here to refresh",
    Username_Error: "Please enter your username",
    Email_Error: "Please enter an email address",
    Valid_Email: "Please enter a valid email address",
    Cr_Number_Error: "Please enter CR-Number",
    Cr_Alphanumeric: "CR-Number must be alphanumeric",
    Cr_Positive: "CR-Number must be positive",
    Cr_Length: "CR-Number must be exactly 10 characters long",
    Tax_Number_Error: "Tax number must be a number",
    Tax_Positive: "Tax number must be positive",
    Tax_Length: "Tax number should be exactly 9 characters long.",
    Comapny_Name_Error: "Please enter Company name",
    Phone_Error: "Please enter phone number",
    Phone_Number_Error: "Phone number must be a number",
    Phone_Positive: "Phone number must be positive",
    Phone_Length: "Phone numbers should be 6 to 14 characters long.",
    Iban_Number_Error: "Please enter IBAN number",
    Iban_Alphanumeric: "IBAN number must be alphanumeric",
    Iban_Positive: "IBAN number must be positive",
    Iban_Length: "IBAN number must be exactly 22 characters long",
    Password_Error: "Please enter your password",
    Password_Length: "Password must be at least 8 characters long",
    Password_Creds:
      "Password include atleast one uppercase letter, special character, and digit",
    Address_Data: "Address is required",
    Address_Country: "Country is required",
    Address_State: "State is required",
    Address_City: "City is required",
    Address_Postal_code: "postal_code is required",
    Staff_Role: "Please enter role",
    Staff_Status: "Status is required",
    Role_Error: "Please enter Role-name",
    Role_Status: "Please enter status",
    Branch_Error: "Please enter Branch name",
    Brach_User: "At least one user must be assigned to the branch",
    Reviews_Error: "Reviews is required",
    Pur_Req_Expiry: "Please enter expiry-date",
    Pur_Req_Category: "Please enter Category",
    Pur_Req_Product: "Please enter Product",
    Pur_Req_Quantity: "Please enter Quantity",
    Quantity_Positive: "Quantity must be a positive number",
    Pur_Req_Price: "Please enter Price",
    Price_Number: "Price must be a number",
    Price_Positive: "Price must be a positive number",
    Pur_Req_Description: "Please enter Description",
    Bank_Transfer_Error: "bank transfer receipt is required",
    Support_Subject: "Please enter subject",
    Image_Size_Error: "Image size should not be greater than 10 MB",
    Image_Format_Error: "Only jpeg , png , svg files are supported.",
    Vat_Req_Error: "Charges is required",
    Vat_Positive_Error: "Charges must be a positive number",
    Image_Unsupported_Format: "Image file has unsupported format.",
    Company_Status: "Company status is required",
    Image_Require_Error: "Image is a required field",
    English_Name_Required: "Please enter English Name",
    Arabic_Name_Required: "Please enter Arabic Name",
    Urdu_Name_Required: "Please enter Urdu Name",
    Category_Appropriate_Error: "Please select appropriate category",
    Measurement_Appropriate_Error: "Please select appropriate measurement",
    Name_Error: "Name is required",
    Overview_Error: "Overview is required",
    Descripton_Error: "Description is required",
    Specification_Error: "Specification is required",
    Featured_Image_Error: "Please select any one image as featured image",
    Unit_Name_Required_Error: "Unit Name is required",
    Unit_Short_Name_Required: "Unit Short Name is required",
    Banner_Heading_Required_Error: "Banner heading  is required",
    Banner_Sub_Heading_Required_Error: "Banner sub heading is required",
    Button_Text_Required_Error: "Button text  is required",
    Collection_Required_Error: "Collection  is required",
    Footer_Icon_Required_Error: "Footer icon text  is required",
    Product_Count_Required_Error: "Product count is required",
    Category_Heading_Required_Error: "Category heading  is required",
    Category_Sub_Heading_Required_Error: "Category sub heading  is required",
    Category_Required_Error: "Category is required",
    Community_Heading_Required_Error: "Community heading  is required",
    Community_Sub_Heading_Required_Error: "community Subheading  is required",
    Title_Required_Error: "Title  is required",
    Purchase_Heading_Required_Error: "Purchase heading  is required",
    Purchase_Sub_Heading_Required_Error: "Purchase  sub heading  is required",
    Image_Heading_Required_Error: "Image heading  is required",
    Feature_Heading_Required_Error: "Feature heading  is required",
    Feature_Sub_Heading_Required_Error: "Feature heading  is required",
    Date_Required_Error: "Date is required",
    Product_Title_Required_Error: "Product title required",
    Product_Sub_Title_Required_Error: "Product sub title is required",
    Price_Required_Error: "Price is required",
    Price_Number_Error: "Price must be a number",
    Price_Positive_Error: "Price must be positive",
    Rating_Required_Error: "Rating is required",
    Product_Button_Text_Required_Error: "Product button text is required",
    Product_Button_Link_Required_Error: "Product button link is required",
    Offer_Heading_Required_Error: "Offer heading  is required",
    Offer_Sub_Heading_Required_Error: "Offer sub heading  is required",
    Description_Required_Error: "Description  is required",
    File_Size_Error: "File size is large",
    Postal_Code_Alphanumeric: "postal_code must be alphanumeric",
    Postal_Code_Number: "postal code must be a number",
    Postal_Code_Length: "Postal code should be 6 to 10 characters long.",
    Date_Error: "Date is required",
    Date_Invalid_Error: "Date is invalid",
    Date_Passed_Error: "End date has passed",
    Units_Error: "Please enter Units",
    Unit_Number_Error: "Units must be a number",
    Unit_Positive_Error: "Units must be positive",
    Base_Price_Error: "Please Enter Base Price",
    Base_Price_Number_Error: "Base Price must be a number",
    Base_Price_Positive_Error: "Base Price must be positive",
    Image_Error: "Please Enter Image",
    Offered_Price_Error: "Please enter Offered Price",
    Offered_Price_Positive_Error: "Offered Price must be a positive number",
    Charges_Number_Error: "charges number must be  number",
    Charges_Positive_Error: "charges number must be positive",
    Subtotal_Error: "Please enter Subtotal",
    Review_Rating_Details: "Review-Rating Details",
    StoreName: "Store-name",
    Ratings: "Ratings",
    Author_Name_Error: "Author name is required",
    Title_Name_Error: "Title name is required",
    Blog_Content_Error: "Blog content is required",
    Slug_Error: "Slug is required",
    Cover_Image_Error: "Cover Image is required",
    Author_Image_Error: "Author Image is required",
    Page_Banner_Error: "Page banner is required",
    Page_Error: "Page is required",
    Heading_Error: "heading is required",
    Question_Error: "Question  is required",
    Answer_Error: "Answer is required",
    Page_Banner_Reuired_Error: "Page Banner is required",
    Collection_Value_Required_Error: "Collection value is required",
    Collecion_Text_Required_Error: "Collection text is required",
    Choose_Header_Required_Error: "Choose Us Header required",
    Choose_Description_Required_Error: "Choose Us Description required",
    Team_Position_Required_Error: "Our Team postition required",
    Team_Name_Required_Error: "Our Team name required",
    Team_Header_Required_Error: "Our Team Header required",
    Team_Description_Required_Error: "Our Team Description required",
    Customer_Name_Required_Error: "Customer Name is required",
    Customer_Designation_Required_Error: "Customer Designation is required",
    Heading_Required_Error: "Heading is required",
    Descripton_Required_Error: "Description is required",
    Card_Descripton_Required_Error: "Card Description is required",
    Page_Required_Error: "Page is required",
    Page_Title_Required_Error: "Page Title  is required",
    Sub_Heading_Required_Error: "Sub Heading is required",
    Sub_Title_Required_Error: "Sub Title is required",
    Firstname_Required_Error: "Firstname is required",
    Lastname_Required_Error: "Lastname is required",
    Message_Required_Error: "Message is required",
    Confirm_Password_Error: "Confirm password does not match",
    Confirm_Password_Req_Error: "Confirm Password is required!",
    Email_Username_Error: "Email or Username is required",
    Card_Name_Error: "Card Name is required",
    Password_Updated_Toast: "Password Updated Successfully",
    User_Create_Toast: "User Created Successfully",
    Staff_Create_Toast: "Staff Member Added Successfully",
    Create_Error_Toast: "User Creation Failed",
    Update_Toast: "Personal Details Updated Successfully",
    Delete_Error_Toast: "Delete And Transfer Is Not Allowed With Same User",
    Get_Toast: "User Fetched Successfully",
    Get_All_Toast: "Users Fetched Successfully",
    Unique_Error_Toast: "User Is Already Exist",
    Unique_Email_Error_Toast: "Email/username Already Exist",
    Unique_Username_Error_Toast: "User Is Already Exist On This Username",
    Not_Found_Toast: "User Not Found",
    Not_Found_User_Toast: "User Does Not Exist",
    Catch_Response_Toast: "Something Went Wrong!!",
    Login_Toast: "You Have Successfully Logged In",
    Username_Or_Password_Does_Not_Match_Toast:
      "Username Or Password Does Not Match",
    Store_Exists_Toast:
      "Store Owner Already Exist For The Compnay name/Cr-Number",
    Supplier_Exists_Toast:
      "Supplier Already Exist For The Compnay name/Cr-Number",
    Mail_Sent_Toast:
      "Mail sent successfully. Please see your mail to reset it.",
    Profile_Updated_Toast: "Profile Updated Successfully",
    Default_Address_Updated_Toast: "Default Address Updated Successfully",
    Address_Deleted_Toast: "Address Deleted Successfully",
    Address_Delete_Failed_Toast: "Failed to Delete Address",
    Address_Is_Default_Toast: "Default Address Cannot Be Deleted",
    Address_Update_Failed_Taost: "Failed To Upadate Address",
    Profile_Update_Success_Toast: "Profile Updated Successfully",
    Address_Fetched_Toast: "Address Fetched Successfully",
    Address_Created_Toast: "Address Created Successfully",
    User_Deleted_Toast: "User Deleted Successfully",
    User_Assigned_To_Branch_Toast:
      "User Associated With Branch Cannot Be Deleted",
    Wrong_User_Or_Password_Toast:
      "The email you are trying to use is not registered with us",
    User_Does_Not_Exists_Toast: "User Does Not Exists On This Username/Email",
    Password_Does_Not_Match_Toast: "Invalid Password. Please Try Again.",
    Someone_Already_Registered_Toast:
      "Someone Has Already Registered With Same Company/Cr_Number",
    User_Inactive_Toast: "User Is Inactive So You Can Not Logged In",
    Admin_Details_Fetched_Toast: "Admin Details Fetched Successfully",
    Store_Data_Updated_Toast: "Store Info Updated Successfully!",
    Store_Data_Deleted_Toast: "Store Info Deleted Successfully!",
    Cms_Pages_Fetched_Toast: "Cms Pages Fetched Successfully!",
    Cms_Specific_Page_Fetched_Toast: "Cms Page Fetched Successfully!",
    Cms_Page_Updated_Toast: "Cms Page Updated Successfully!",
    Cms_Blogs_Fetched_Toast: "Blogs Fetched Successfully!",
    Cms_Specific_Page_Updated_Toast: "Specific Cms Page Updated Successfully!",
    Cms_Unauthorize_Update_Request: "You Are Not Authorize To Update",
    Cms_Review_Fetched_Toast: "Review List Fetched Successfully!",
    Slug_Exists_Toast: "Slug Already Exists",
    Cms_Datasource_Count_Toast: "Datasource Count Fetched Successfully",
    Category_Fetched_Toast: "Category Fetched Successfully",
    Error_Toast: "A Token Is Required For Authentication",
    Wrong_Token_Issue_Toast: "Please Contact Admin",
    Token_Invalid_Toast: "Token Expired!",
    Cms_Blog_Not_Found_Toast: "You Are Not Authorize To Update",
    Message_Sent_Toast: "Your Message Has Been Successfully Sent",
    Fetch_Data_Toast: "Get In Touch Data Fetched Successfully",
    Contact_Deleted_Toast: "Contact Message Deleted Successfully",
    Faq_Added_Toast: "Faq Added Successfully",
    Faq_Data_Toast: "Faq Data Fetched Successfully",
    Faq_Fetched_Toast: "Faq Fetched Successfully",
    Faq_Updated_Toast: "Faq Data Updated Successfully",
    Faq_Deleted_Toast: "Faq Deleted Successfully",
    Unit_Added_Toast: "Unit Of Measurement Added Successfully",
    Unit_Already_Exist_Toast: "Unit Of Measurement Already Exist",
    All_Units_Fetched_Toast: "All Units Of Measurement Fetched Successfully",
    Unit_Fetched_Toast: "Unit Of Measurement Fetched Successfully",
    Unit_Unauthorize_Update_Request_Toast: "You Are Not Authorize To Update",
    Unit_Updated_Toast: "Unit Of Measurement Updated Successfully",
    Unit_Deleted_Toast: "Unit Of Measurement Deleted Successfully",
    Unit_Already_Assigned_Toast:
      "This Measurement Unit Is Used With Multiple Products, So You Cannot Delete It",
    Category_Added_Toast: "Category Added Successfully",
    Category_Data_Toast: "Category Data Fetched Successfully",
    Category_Updated_Toast: "Category Updated Successfully",
    Category_Deleted_Toast: "Category Deleted Successfully",
    Category_Could_Not_Find_Toast: "Category Cannot Be Found In Database",
    Category_Duplicated_Toast: "Category Duplication Is Not Allowed",
    Category_Already_Exist_Toast: "Category Already Exist",
    Category_Wise_Product_Toast: "Product Fetched Successfully ",
    Update_Category_Inactive_Toast: "Selected Category Is currently Inactive",
    Category_Product_Inactive_Toast: "Selected Product Is Inactive",
    Category_Product_Deleted_Toast: "Product Is Unavailable",
    Category_Can_Not_Deleted_Toast:
      "This Category Is Used With Multiple Products, So You Cannot Delete It",
    Product_Added_Toast: "Product Added Successfully",
    Product_Data_Toast: "Products Data Fetched Successfully",
    Products_Fetched_Toast: "Products Fetched Successfully",
    Products_Fetched_Failed_Toast: "Failed To Fetched The Products",
    Product_Updated_Toast: "Product Updated Successfully",
    Products_Updated_Failed_Toast: "Failed To Update Product",
    Product_Deleted_Toast: "Product Deleted Successfully",
    Product_Deleted_Failed_Toast: "Failed to Delete The Product",
    Product_Could_Not_Find_Toast: "Product Cannot Be Found In Database",
    Product_Duplicated_Toast: "Product Duplication Is Not Allowed",
    Product_Already_Exist_Toast:
      "Product Already Exist With Same Category/Measurement",
    Products_Add_Failed_Toast: "Failed To Add Product",
    Product_Category_Inactive_Toast: "Selected Category Is Currently Inactive",
    Product_Measurement_Inactive_Taost:
      "Selected Measurement Is Currently Inactive",
    Single_Supplier_Toast: "Supplier Fetched Successfully",
    Failed_Single_Supplier_Toast: "Failed To Fetch Supplier",
    Unauthorize_Delete_Request_Taost: "You Are Not Authorize To Delete",
    Supplier_Deleted_Toast: "Supplier Deleted Successfully",
    Supplier_Updated_Toast: "Supplier Updated Successfully",
    Supplier_Data_Fetched_Toast: "Supplier Data Fetched Successfully",
    Supplier_Product_Already_Exisit_Toast:
      "Product Already Has It's Price And Duration With Same Units",
    Supplier_Product_Fetched_Toast: "Products Fetched Successfully",
    Supplier_Product_Added_Toast: "Product Added Successfully",
    Cat_Wise_Product_Fetched_Toast:
      "Category Wise Poducts Fetched Successfully",
    Supplier_Product_Deleted_Toast: "Product Deleted Succesfully",
    Single_Supplier_Product_Fetched_Toast:
      "Single Product Fetched Successfully",
    Supplier_Product_Updated_Toast: "Product Updated Successfully",
    Supplier_Product_Update_Failed_Toast: "Failed To Update Product",
    Suppier_Product_Fetched_Failed_Toast: "Products Fetching Failed",
    Supplier_Product_Not_Found_Toast: "Supplier Does Not Have It's Products",
    Request_Created_Toast: "Purchase Request Send Successfully !",
    Purchase_Request_Fetched_Toast: "Purchase Requests Fetched Successfully",
    Purchase_Request_Not_Fetched_Toast:
      "Store Owner Does Not Have The Purchase Requests",
    Purchase_Request_Deleted_Toast: "Purchase Request Deleted Successfully",
    Purchase_Request_Delete_Failed_Toast:
      "Failed To Delete The Purchase Request",
    Purchase_Request_Status_Update_Failed_Toast:
      "Failed To Update The Status Of Purchase Request!!",
    Purchase_Request_Status_Updated_Toast:
      "Purchase Request Status Updated Successfully!!",
    Purchase_Request_Quote_Deleted_Toast: "Quote Deleted Successfully",
    Purchase_Request_Quote_Rejected_Toast: "Quote Rejected Successfully",
    Purchase_Request_Quote_Not_Found_Toast:
      "Purchase Request Quote Could Not Find",
    Purchase_Request_Fetch_Failed_Toast: "Unable To Fetch Purchase Request",
    Purchase_Request_Not_Found_Toast: "Purchase Request Not Found",
    Purchase_Request_Quote_Deleted_Failed_Toast:
      "Failed To Delete The Purchase Request Quote",
    Supplier_Quote_Not_Found_Toast: "No Supplier Quotes Found",
    Purchase_Request_Quote_Accepted_Toast: "Quoted Has Been Accepeted",
    Purchase_Request_Created_Toast:
      "Purchase Request Details Has Been Created Successfully",
    Purchase_Request_Updated_Toast:
      "Purchase Request Details Has Been Updated Successfully",
    Quotes_Created_Toast: "Quote Created Successfully!",
    Quotes_Updated_Toast: "Quote Updated Successfully!",
    Product_Fetched_Toast: "Product Fetched Successfully!",
    Quote_Does_Not_Found_Toast: "Quote Does Not Found",
    Permission_Fetched_Toast: "Permission Fetched Successfully!",
    Order_Created_Toast: "Order Created Successfully",
    Order_Updated_Toast: "Order Updated Successfully",
    Order_Deleted_Toast: "Order Deleted Successfully",
    Order_Fetched_Toast: "Order Fetched Successfully",
    Support_Ticket_Created_Toast: "Support Ticket Created Successfully",
    Support_Ticket_Updated_Toast: "Support Ticket Updated Successfully",
    Support_Ticket_Deleted: "Support Ticket Deleted Successfully",
    Support_Ticket_Fetched_Toast: "Support Tickets Fetched Successfully",
    Support_Ticket_Closed_Toast: "Support Ticket Closed Successfully",
    Support_Ticket_Send_Reply_Toast: "Reply Of The Ticket Sent Successfully",
    Chat_Created_Toast: "Chat Created Successfully",
    Chat_Fetched_Toast: "Chat Fetched Successfully",
    PaymentCharge_Created_Toast: "PaymentCharge Created Successfully",
    PaymentCharge_Updated_Toast: "PaymentCharge Updated Successfully",
    PaymentCharge_Deleted_Toast: "PaymentCharge Deleted Successfully",
    PaymentCharge_Fetched_Toast: "PaymentCharge Fetched Successfully",
    Payement_Failed_Toast: "Unable To Fetch The Payment Request",
    Payment_Details_Not_Found_Toast: "Payment Charge Could Not Find",
    General_Count_Report_Fetch_Toast: "General Data Fetched Successfully",
    Store_Not_Exisit_Toast: "Store Doesnot Exist",
    Store_Data_Fetched_Toast: "Data Fetched Successfully",
    Reviews_Fetched_Toast: "Reviews And Rating Fetched Successfully",
    Reviews_Added_Toast: "Reviews And Rating Added Successfully",
    Reviews_Updated_Toast: "Reviews And Rating Updated Successfully",
    Role_Added_Toast: "Role Created Successfully",
    Role_Updated_Toast: "Role Updated Successfully",
    Staff_Member_Added_Toast: "Staff Member Created Successfully",
    Staff_Member_Updated_Toast: "Staff Member Updated Successfully",
    Staff_Member_Deleted_Toast: "Staff Member Deleted Successfully",
    Role_Deleted_Toast: "Role Deleted Successfully",
    Role_Already_Exists_Toast: "Role Already Exists",
    Role_Already_Assigned_Toast:
      "Role Can Not Be InActive Because It Is Assigned To Staff-Member",
    IBAN_Number_Toast:
      "Please Enter your IBAN Number Before Creating The Staff Member",
    Card_Added_Toast: "Card Saved Successfully",
    Card_Fetched_Toast: "Cards Fetched Successfully",
    Card_Updated_Toast: "Card Updated Successfully",
    Card_Deleted_Toast: "Card Deleted Successfully",
    Unique_Card_Error_Toast: "Card Already Exists",
    Card_Does_Not_Belongs_To_Customer_Toast:
      "Card Does Not Belong To Any Customer",
    Failed_Card_Delete_Toast: "Failed To Delete The Card",
    Notification_Deleted_Toast: "Notification Deleted Successfully",
    Product_Cannot_Be_Repeated_Toast:
      "Products Can Not Be Repeated With Same Name / Measurement",
    Cms_Updated_Toast: "Data Has Been Updated Successfully",
    Cms_Deleted_Toast: "Data has Been Deleted Successfully",
    Cms_Created_Toast: "Data has Been Created Successfully",
    Company_Inactive_Toast:
      "This Company Is Inactive , so You Cannot Logged In",
    Branch_Fetched_Toast: "Branch Fetched Successfully",
    Branch_Created_Toast: "Branch Created Successfully",
    Branch_Updated_Toast: "Branch Updated Successfully",
    Branch_Deleted_Toast: "Branch Deleted Successfully",
    Vat_Upadated_Toast: "VAT Charges Updated Successfully",
    Vat_Get_Toast: "VAT Charges Has Been Got Successfully",
    Correct_Mail_Toast: "Please Enter Correct Email",
    Transaction_Fetched_Toast:
      "Transaction Details Has Been Fetched Successfully",
    Weekly_Graph_Detail_Toast: "Weekly Graph Details",
    Category_Graph_Detail_Toast: "Category Graph Details",
    Mail_Send_Toast: "Mail Sent Successfully",
    Success_Toast: "Successfully Fetched!",
    Blog_Image_Upload_Toast: "Blog Image Uploaded Successfully",
    Purchase_Request_Detail_Not_Found_Toast:
      "Purchase Request Details Not Found",
    Order_Added_Toast: "Order Added Successfully",
    Data_Fetched_Toast: "Data Fetched Successfully",
    Unable_To_Delete_User_Toast:
      "Unable To Delete, Users Are Assigned To This Role",
    User_Exist_Toast: "User Already Exists",
    Record_Exist_Toast: "This Data Already Exists In Our Records",
    Not_Find_Product_Toast: "Cannot Find Any Product",
    Enter_New_Password: "Enter New Password",
    ReEnter_New_Password: "Re-Enter New Password",
    Password_And_Security: "Password And Security",
    Latest_Products: "Latest products",
    UOM: "Messurements",
    My_Profile: "My Profile",
    Manage_Password: "Manage Password",
    Bank_Transfer_Initiated_Alert:
      "The bank transfer has been initiated and is now pending approval from the admin. Thank you for your understanding.",
    Add_Ranking: "Add Ranking",
    Edit_Ranking: "Edit Ranking",
    Bank_Transfer_Instruction: "Bank Transfer Instruction",
    Branch_Delete_Text:
      "Do you really want to delete this records? branch user will be unsigned to this branch",
    Select_Card: "Select Card",
    Enter_Offer_Price: "Enter Offer Price",
    Quantity_Error_Message: "Quantity should be greater than 0",
    Quantity_Number_Error: "Quantity must be a number",
    Quantity_Positive_Error: "Quantity must be positive",
    Requested_Price_Required_Error: "Request Price is required",
    Requested_Price_Number_Error: "Request Price must be a number",
    Requested_Price_Positive_Error: "Request Price must be positive",
    Quotes_Accepted_Required_Eror: "Quotes accepted  is required",
    Quantity_Required_Error: "Quantity is required",
    Payment_Mode: "Payment Mode",
    Approve: "Approve",
    Decline: "Decline",
    Approved: "Approved",
    Thank_You: "Thank You",
    Received_Your_Message:
      "We’ve received your message and will respond within 24 hours. In the meantime, make sure to follow us on socials!",
    Low_To_High: "Price : Low To High",
    High_To_Low: "Price : High To Low",
    Recently_Added: "Recently Added",
    All_Prices: "All Prices",
    Sort_By: "Sort By",
    Price_Range: "Price Range",
    Verification_Status: "Verification Status",
    Activation_Status: "Activation Status",
    Passed_Date: "You can not select Past Date",
    Role_First_Error: "Please create role first",
    See_All_Notification: "Select All Notification",
    Offer_Price_Message: "Offer Price should not be less than base price",
    Branch_Exists_Toast: "Branch already exists",
    Store_Already_Exists_Toast: "Store already exist",
    Choose_Category: "Choose Category",
    Choose_Status: "Choose Status",
    Choose_Country: "Choose Country",
    Choose_State: "Choose State",
    Choose_City: "Choose City",
    Choose_Role: "Choose Role",
    Select_Product: "Select Product",
    Same_Category_Error: "same category can not be repeated",
    Enter_Vat_Charges_Error: "Enter vat charges",
    Total_Suppliers: "Total Suppliers",
    Total_Store_Owners: "Total Store Owners",
    Total_Products: "Total Products",
    Total_Categories: "Total Categories",
    Total_Transactions: "Total Transactions",
    Total_BankTransfers: "Total Bank-Transfers",
    Reject: "Reject",
    Reject_Msg: "Do you really want to reject this code",
    Enter_Current_Password: "Enter Current Password",
    Future_Date: "Cannot enter Future date",
    Enter_Payment_Id: "Enter payment Id",
    Orders_By_Date: "Orders By Date",
    Product_Details: "Product Details",
    View_OrderDetail: "View Order Details",
    Less_than_Expiry_Date: "Less than Expiry Date",
    Enter_Request_ID: "Enter Request ID",
    Purchase_Request_After_Date: "Purchase Request Date",
    Search_Filter: "Search Filter",
    Apply: "Apply",
    Reset: "Reset",
    Enter_Order_Id: "Enter OrderId",
    Enter_Amount: "Enter Amount",
    Enter_Ticket_Id: "Enter TicketId",
    Tickets_By_Date: "Tickets By Date",
    Enter_Purchase_Req_Id: "Enter PurchaseRequestId",
    Enter_Company_Name: "Enter Company Name",
    Category_Name: "Category Name",
    Enter_Supplier_Company_Name: "Enter Supplier Company Name",
    Enter_Store_Company_Name: "Enter Store Company Name",
    Product_Title: "Products",
    Grand_Total: "Grand Total",
    Download_Quotation: "Download Quotation",
    Enter_Quantity: "Enter Quantity",
    Has_Offers: "Has offers?",
    Enter_Charges: "Enter Charges",
    tooltip_another_req:
      "Check This, If you are okay to get any other brand product for this offer",
    tooltip_req: "Check This we get offers for the Entire Request",
    Reason_for_Cancel_Order: "Reason for Cancel Order",
    Enter_Reason: "Enter Reason",
    Replacement_Of_Order_Product: "Replacement Of Order Product",
    Replace_Product: "Product Replacement Request",
    Regular_Orders: "Regular Orders",
    Replacement_Orders: "Replacement Orders",
    Reason_Error: "Reason is required",
    Send_Replacement_Request: "Send Replacement Request",
    Product_status: `Product Status`,
    Replacement_Status: "Replacement Status",
    Can_not_select_same_product: "Can not select same product",
    Send_Refund_Request: "Send Refund Request",
    Refund_Of_Order_Product: "Refund Of Order Product",
    Refund_Orders: "Refund Orders",
    Refund_order_detail: "Refund order detail",
    Replacement_order_detail: "Replacement order detail",
    Select_All: "Either Accept or reject all request",
    Supplier_Status: "Supplier Status",
    Admin_Status: "Admin Status",
    cancel_tooltip: "Order is already Cancelled",
    delivered_tooltip: "Order is delivered so you can not cancel it",
    order_tooltip: "Cancel Order",
    Payment_Assurance:
      "Are you sure you have refunded the amount to the store?",
    Refundable_Amount: "Refundable Amount",
    Refund_Order_Id: "Refund Order-Id",
    Refund_Order_Details: "Refund Order Details",
    Refund_Status: "Refund Status",
    Accepted_for_refund: "This order has been accepted for refund",
    Pending_for_refund: "This order is pending for refund",
    Rejected_for_refund: "This order is rejected for refund",
    Replacement_Accepted_Upto: "Replacement Accepted Upto",
    Replace_Days_Updated: "Replace Days Updated",
    Admin_Refund_Status: "Admin Refund Status",
    Supplier_Refund_Status: "Supplier Refund Status",
    Replacement_Order_Details: "Order Replacement Request Details",
    Replacement_Order_Tracking: "Order Replacement Tracking",
    Replacemnt_Req_Error: "Replacemnt is Required",
    Refundable_Req_Error: "Refundable is Required",
    Replacemnt_Positive_Error:
      "Replacement accepted days must be a positive integer number",
    Refundable_Positive_Error:
      "Refund accepted days must be a positive integer number",
    Refund_Accepted_Upto: "Refund Accepted Upto",
    Refund_Order_Summary: "Refund Order Summary",
    Tooltip_order_refunded: "The requested products have been refunded.",
    ord_commission: "Ord Commission",
    bank_commission: "Bank Commission",
    Enter_Ord_Commission: "Enter Ord Commission",
    Enter_Bank_Commission: "Enter Bank Commission",
    Ord_Commission: "Ord Commission is required",
    Bank_Commission: "Bank Commission is required",
    Bank_Commission_Number: "Bank Commission is Number",
    Ord_Commission_Number: "Ord Commission is Number",
    Admin_Refund: "Admin Refund",
    Finance_Assurance: "Are you sure you have paid the amount to the supplier?",
    Finance_Info: "Finance Info",
    View_Pdf: "View Pdf",
    View_Excel: "View Excel",
    Order_Pdf: "Pdf view for all orders",
    Order_Excel: "Excel view for all orders",
    Choose_Branch: "Choose Branch",
    Select_Branch_for_Shipping: "Select Branch for Shipping",
    Purchase_for_branch: "Purchase for branch",
    associated_delivery_address: "Associated Delivery Address",
    Default_Bank_Commmission_Updated: "Default Bank Commmission Updated",
    Default_Ord_Commmission_Updated: "Default Ord Commmission Updated",
    go_to_bank_transfer: "GO to Bank Transfer",
    Charges: "Charges",
    General_Settings: "General Settings",
    Orders_After_Date: "Orders After Date",
    Role_Error_ar: "Please enter Role-name for arabic",
    Role_Error_ur: "Please enter role-name for urdu",
    Role_Name_Arabic: "Role Name Arabic",
    Role_Name_Urdu: "Role Name Urdu",
  },

  ur: {
    Community_title: "خاص",
    Home: "خاص",
    About_Us: "خاص",
    Blog: "خاص",
    Contact_Us: "خاص",
    place_where: "ایک ایسی جگہ جہاں",
    everything: "كل شيء",
    special: "خاص",
    header: `لوريم إيبسوم هو ببساطة نص وهمي لصناعة الطباعة والتنضيد. كان لوريم إيبسوم هو النص الوهمي القياسي في الصناعة منذ عام 1500 ، عندما كانت طابعة غير معروفة`,
    collection: "مجاميع",
    shiiping_available: "الشحن متاح",
    money_back: "100٪ ضمان استعادة الاموال",
    Payment: "خاص",
    payment_process: "عملية دفع آمنة",
    online_support: "24/7 الدعم عبر الإنترنت",
    heading_section: " عن إس آند إس",
    about_description:
      "بالنسبة لأسلوبنا الحالي، فإن متعة بعض الذين يتبعون ما قيل أقل إرضاءً للعقل. إن طريقتنا الحاضرة للغاية، بالنسبة للبعض، هي متعة أولئك الذين يتبعون ما قيل سيكونون أقل إرضاءً لقلوبهم. بالنسبة لأسلوبنا الحالي، فإن متعة بعض الذين يتبعون ما قيل أقل إرضاءً للعقل. بالنسبة لأسلوبنا الحالي، فإن متعة بعض الذين يتبعون ما قيل أقل إرضاءً للعقل",
    sign_in: "سائن ان کریں",
    account_details:
      "براہ کرم اپنے اکاؤنٹ تک رسائی حاصل کرنے کے لئے اپنی تفصیلات پر کریں۔",
    personal_details: "ذاتی تفصیلات",
    total_earning: "کل آمدنی",
    total_product: "مجموعی مصنوعات",
    Total_Sent_Quotes: "خاص",
    General_Report: "جنرل رپورٹ",
    Dashboard: "ڈیش بورڈ",
    Store: "اسٹور",
    Cms_pages: "سی ایم ایس صفحات",
    Supplier: "سپلائر",
    Products: "مصنوعات",
    Categories: "اقسام",
    Products_List: "مصنوعات کی فہرست",
    Unit_of_measurement: "پیما ئش کا یونٹ",
    Orders: "احکامات",
    Support_Ticket: "سپورٹ ٹکٹ",
    Logout: "خاص",
    Sales_Report: "خاص",
    Weekly_Top_Seller_Stores: "خاص",
    Transactions: "خاص",
    Store_Info: "خاص",
    CMS_Pages: "خاص",
    Data_Source: "خاص",
    Supplier_Info: "خاص",
    Measurement: "خاص",
    search: "خاص",
    Search: "خاص",
    SR_NO: "خاص",
    Name: "خاص",
    Email: "خاص",
    Company_Name: "خاص",
    Phone_Number: "خاص",
    Status: "خاص",
    Actions: "خاص",
    Title: "خاص",
    Slug: "خاص",
    Cards: "خاص",
    count: "خاص",
    No_of_Products: "خاص",
    Product_name: "خاص",
    Image: "خاص",
    Unit_Name: "خاص",
    Unit_Short_Name: "خاص",
    Order_Id: "خاص",
    Order_ID: "خاص",
    Supplier_Name: "خاص",
    Store_Name: "خاص",
    Amount: "خاص",
    Date: "خاص",
    Sending_Date: "خاص",
    Subject: "خاص",
    Ticket_ID: "خاص",
    View_more: "خاص",
    Edit: "ترمیم",
    View: "دیکھیں",
    Delete: "حذف کریں۔",
    Manage_Profile: "خاص",
    Purchase_Request: "خاص",
    Staff: "خاص",
    All_staff: "خاص",
    All_roles: "خاص",
    Branches: "خاص",
    chat: "خاص",
    Chat: "خاص",
    Notification: "خاص",
    Purchase_History: "خاص",
    Recent_Orders: "خاص",
    Purchase_Requests: "خاص",
    Edit_Purchase_Requests: "خاص",
    ID: "خاص",
    Payment_status: "خاص",
    Request_ID: "خاص",
    Requested_Quantity: "خاص",
    Requested_Price: "خاص",
    Options: "خاص",
    Username: "خاص",
    CR_Number: "خاص",
    Phone: "خاص",
    Tax_Number: "خاص",
    IBAN_number: "خاص",
    Photo: "خاص",
    Saved_Address: "خاص",
    Security: "خاص",
    UPDATE_DETAILS: "خاص",
    Your_password: "خاص",
    Add_New_Address: "خاص",
    Update_Password: "خاص",
    Add_Product: "خاص",
    Showing: "دکھا رہا ہے۔",
    entries: "اندراجات",
    to: "خاص",
    of: "خاص",
    Add_Staff_Member: "عرض",
    Create_Staff: "عرض",
    Edit_Staff: "عرض",
    Add_new_Permission: "عرض",
    All_Roles: "عرض",
    Cancel: "عرض",
    Close: "خاص",
    Are_you_sure: "خاص",
    DeleteMsg: "خاص",
    Alternate_product: "خاص",
    Offered_Quantity: "خاص",
    Offered_Price: "خاص",
    Offered_deal_at: "خاص",
    Select: "خاص",
    Role: "عرض",
    Save: "عرض",
    Role_Name: "عرض",
    Edit_Products: "خاص",
    View_All_Products: "خاص",
    Delete_Products: "خاص",
    Add_Products: "خاص",
    Manage_Products_Req: "خاص",
    Send_Quotes: "خاص",
    View_Orders: "خاص",
    Assigned_Orders: "خاص",
    Manage_Status: "خاص",
    Manage_Staff: "عرض",
    Add_staff: "عرض",
    View_All_staff: "عرض",
    Delete_staff: "عرض",
    Edit_staff: "عرض",
    Add_Roles: "عرض",
    Edit_Roles: "خاص",
    Delete_Roles: "عرض",
    View_Roles: "عرض",
    Request_Purchase: "خاص",
    Manage_Branches: "عرض",
    Add_Branches: "عرض",
    View_All_Branches: "عرض",
    Edit_Branches: "خاص",
    Delete_Branches: "عرض",
    Ticket: "خاص",
    Add_Ticket: "خاص",
    View_All_support_tickets: "خاص",
    Cancel_Order: "عرض",
    create_Orders: "عرض",
    Create_Role: "عرض",
    Update_Role: "عرض",
    Create_Branch: "عرض",
    Branch_Name: "عرض",
    Address: "عرض",
    Postal_Code: "خاص",
    Country: "عرض",
    State: "خاص",
    State_Province: "عرض",
    City: "عرض",
    Assign_users: "عرض",
    Choose: "خاص",
    Delete_Purchase_Request: "خاص",
    Track_Purchase_Request: "خاص",
    Create_Purchase_Request: "خاص",
    Checkout_Quote: "خاص",
    Permissions: "عرض",
    All_Branches: "عرض",
    Add_Branch: "عرض",
    Edit_Branch: "عرض",
    Upload_File: "خاص",
    CREATE_A_TICKET: "خاص",
    Create_A_Ticket: "خاص",
    Create_Ticket: "خاص",
    choose_file: "خاص",
    View_Supplier_Info: "خاص",
    Edit_Supplier_Info: "خاص",
    Add_Supplier_Info: "خاص",
    Profile_Image: "عرض",
    Submit: "عرض",
    Go_to_supplier_list: "خاص",
    Page: "عرض",
    Blog_Page_Banner: "عرض",
    Blog_Heading: "عرض",
    Blog_Description: "عرض",
    Remove: "عرض",
    Blogs_Section: "عرض",
    Save_Changes: "عرض",
    Email_address: "خاص",
    Select_Category_Image: "خاص",
    Category: "خاص",
    Product_Name: "خاص",
    Product_Overview: "خاص",
    Product_Description: "خاص",
    Product_Specification: "خاص",
    Featured_image: "خاص",
    Add_Product_Image: "خاص",
    Go_to_Product_List: "خاص",
    Add_Measurement: "خاص",
    Pending_Purchase_Requests: "خاص",
    Total_Products_Ordered: "خاص",
    Total_Available_Offers: "خاص",
    Default_Shipping_Address: "خاص",
    Shipping_Address: "خاص",
    Shipping: "خاص",
    Created_Date: "خاص",
    Number_of_products: "خاص",
    Branch: "خاص",
    Staff_Member: "خاص",
    Expiry_Date: "خاص",
    Total: "خاص",
    chatMsg: "خاص",
    Requests_Expiry: "عرض",
    Receive_offers_for_the_entire_request: "عرض",
    another_brand: "عرض",
    Quantity: "عرض",
    Subtotal: "عرض",
    Description: "عرض",
    Product_Image: "عرض",
    Product_Images: "خاص",
    Offer_Duration: "خاص",
    Units: "خاص",
    Unit_Property: "خاص",
    Offer_Valid_Till: "عرض",
    Password: "عرض",
    My_Account: "خاص",
    Privacy_Policy: "خاص",
    Terms_Conditions: "خاص",
    Popular_Categories: "خاص",
    View_all: "خاص",
    Enter_unit_name: "خاص",
    Enter_unit_short_name: "خاص",
    Enter_category_name: "خاص",
    Enter_product_name: "خاص",
    Enter_CompanyName: "خاص",
    Enter_CR_Number: "خاص",
    Enter_Tax_Number: "خاص",
    Enter_IBAN_number: "خاص",
    Product_over: "خاص",
    Product_desc: "خاص",
    Product_spec: "خاص",
    Faq_heading: "خاص",
    Faq_subHeading: "خاص",
    Page_banner: "خاص",
    About_heading: "خاص",
    About_subHeading: "خاص",
    About_buttonText: "خاص",
    About_butttonLink: "خاص",
    About_collectionValue: "خاص",
    About_collectionText: "خاص",
    About_choose_heading: "خاص",
    About_choose_desc: "خاص",
    About_card_heading: "خاص",
    About_card_position: "خاص",
    About_card_description: "خاص",
    About_card_name: "خاص",
    Heading: "عرض",
    Enter_description: "عرض",
    Page_title: "خاص",
    Subheading: "عرض",
    Support_title: "خاص",
    Enter_title: "خاص",
    Enter_subTitle: "خاص",
    Footer_text: "خاص",
    Product_count: "خاص",
    community_description: "عرض",
    Enter_date: "عرض",
    Price: "عرض",
    Rating: "عرض",
    Author_name: "خاص",
    Blog_title: "خاص",
    Enter_Slug: "خاص",
    Blog_content: "خاص",
    Designation: "عرض",
    Enter_Customer_name: "خاص",
    Customer_name: "خاص",
    Enter_reviews: "خاص",
    Enter_question: "خاص",
    Enter_answer: "خاص",
    Go_to_store_list: "خاص",
    Enter_Username: "خاص",
    Enter_Email: "خاص",
    Enter_Phone: "خاص",
    Enter_Password: "عرض",
    Enter_Rolename: "خاص",
    Enter_Branch: "خاص",
    Enter_Address: "خاص",
    Enter_Date: "خاص",
    Gram: "خاص",
    Enter_Description: "خاص",
    Enter_Subject: "خاص",
    Type_your_reply: "خاص",
    Enter_Overview: "خاص",
    Enter_Units: "خاص",
    Enter_Unitproperty: "خاص",
    Enter_Price: "خاص",
    Enter_Baseprice: "خاص",
    Enter_Status: "خاص",
    Enter_Specification: "خاص",
    Forgot_Password: "خاص",
    Add_new_Product: "خاص",
    Add_new_Category: "خاص",
    Remember_me: "خاص",
    Enter_Heading: "خاص",
    Measurements: "خاص",
    Edit_Measurement: "خاص",
    Edit_Store_Info: "خاص",
    English: "عرض",
    Urdu: "عرض",
    Arabic: "عرض",
    Sub_heading: "خاص",
    Button_Text: "عرض",
    Button_Link: "عرض",
    Card_Heading: "خاص",
    Card_Description: "عرض",
    Card_Name: "خاص",
    Card_Position: "خاص",
    Go_to_Blogs_Section: "عرض",
    Go_to_Faq_Section: "عرض",
    Go_to_Reviews_Section: "عرض",
    subHeading: "عرض",
    Faq: "عرض",
    Contact_Us_Section: "عرض",
    PageTitle: "عرض",
    Title_For_Support: "عرض",
    Card_Title: "عرض",
    Card_SubTitle: "عرض",
    Review_Section: "عرض",
    Review_Heading: "عرض",
    Review_Description: "عرض",
    Banner_Section: "عرض",
    Collection_Text: "عرض",
    Footer_Icon_Text: "عرض",
    Product_Count: "عرض",
    Category_Section: "عرض",
    Category_Image_Text: "عرض",
    Add_Category: "عرض",
    Community_Network_Section: "عرض",
    Network_Heading: "عرض",
    Network_SubHeading: "عرض",
    Community_Title: "عرض",
    Community_Description: "عرض",
    Purchase_Request_Section: "عرض",
    Dynamic_Image_Section: "عرض",
    Image_Heading: "عرض",
    Image_SubHeading: "عرض",
    Featured_Product_Section: "عرض",
    SubTitle: "عرض",
    Add_Featured_Product_Section: "عرض",
    Offer_Section: "عرض",
    About_Blog_Section: "عرض",
    Faq_Section: "عرض",
    Review_Card: "عرض",
    Manage_Reviews: "عرض",
    Add_Review: "عرض",
    Edit_Review: "عرض",
    Customer_Name: "عرض",
    Create_Question: "عرض",
    Manage_Questions: "عرض",
    Edit_Question: "عرض",
    Question: "عرض",
    Answer: "عرض",
    About_Blogs_Section: "خاص",
    Reviews_Section: "خاص",
    FAQ_Section: "خاص",
    Our_Team: "خاص",
    Why_to_Choose_Us: "خاص",
    Collection_Value: "خاص",
    Edit_Category: "خاص",
    View_Category: "خاص",
    Go_to_Category_List: "خاص",
    View_Product: "خاص",
    Manage_Blogs: "خاص",
    Create_Blog: "خاص",
    No_Blogs_Found: "خاص",
    Customer_Designation: "خاص",
    Author_Name: "خاص",
    Blog_Title: "خاص",
    Blog_Content: "خاص",
    Blog_Cover_Photo: "خاص",
    Blog_Author_Photo: "خاص",
    Edit_Blog: "خاص",
    Password_Recovery: "خاص",
    notice: "خاص",
    admin_notice: "خاص",
    Back: "خاص",
    Ranking_Level: "خاص",
    Subtotal_Charges: "خاص",
    Offered_Count: "خاص",
    Supplier_ID: "خاص",
    Request_Summary: "خاص",
    Requested_Date: "خاص",
    Products_Request: "خاص",
    Total_Quotes: "خاص",
    Requested_Status: "خاص",
    Requested_Expiry: "خاص",
    closed_req: "خاص",
    expired_req: "خاص",
    checkout_msg: "خاص",
    close_msg: "خاص",
    no_quotes: "خاص",
    Checkout: "خاص",
    Close_Request: "خاص",
    EXPIRED: "خاص",
    COMPLETED: "خاص",
    NEW: "خاص",
    QUOTED: "خاص",
    ACTIVE: "خاص",
    REJECTED: "خاص",
    Update: "خاص",
    Selected_Product: "خاص",
    Shipping_Info: "خاص",
    Confirmation: "خاص",
    Return_To_Shop: "خاص",
    Payable_amount: "خاص",
    Continue_To_Shipping: "خاص",
    Continue_To_Payment: "خاص",
    Set_as_Default: "خاص",
    Edit_Address: "خاص",
    placed: "خاص",
    payment_fail: "خاص",
    Order_Summary: "خاص",
    Item: "خاص",
    Card_payment: "خاص",
    Thank: "خاص",
    copy: "خاص",
    Order_Details: "خاص",
    Order_date: "خاص",
    Order_Status: "خاص",
    Total_order_amount: "خاص",
    issue: "خاص",
    Flat_shipping_rate: "خاص",
    Return_To_Payment: "خاص",
    BACK_TO_HOMEPAGE: "خاص",
    Purchase_History_Of_payment: "خاص",
    Payment_method: "خاص",
    Allow_Purchase_Request: "خاص",
    Overview: "خاص",
    Base_Price: "خاص",
    Specification: "خاص",
    CREATE_PRODUCT: "خاص",
    Add_PRODUCT: "خاص",
    Open: "خاص",
    Quoted: "خاص",
    Closed: "خاص",
    Product: "خاص",
    Other_brand_product: "خاص",
    Qty: "خاص",
    subtotal: "خاص",
    charges: "خاص",
    Update_quotes: "خاص",
    Order_Code: "خاص",
    final: "خاص",
    Enter_quantity: "خاص",
    Enter_requested_price: "خاص",
    Send_Request: "خاص",
    Share_this_product: "خاص",
    other_offer: "خاص",
    approx: "خاص",
    View_Details: "خاص",
    Quantity_Needed: "خاص",
    Request_Price: "خاص",
    Subtotal_Price: "خاص",
    Quotes_Accepted_Upto: "خاص",
    No_Data_Found: "خاص",
    Type_Your_Reply: "خاص",
    Send_Reply: "خاص",
    Accepted: "خاص",
    Packed: "خاص",
    In_Transit: "خاص",
    Delivered: "خاص",
    Delivery_Date: "خاص",
    Additional_Notes: "خاص",
    Invoice: "خاص",
    Download_Invoice: "خاص",
    Number: "خاص",
    Total_Price: "خاص",
    Back_To_HomePage: "خاص",
    email: "خاص",
    Registration_Date: "خاص",
    Rank: "خاص",
    Tax: "خاص",
    Delivery_Type: "خاص",
    price: "خاص",
    Enter_Postal_Code: "خاص",
    Enter_Phone_Number: "خاص",
    Review: "خاص",
    Buy_Now: "خاص",
    currency: "خاص",
    Useful_links: "خاص",
    Help: "خاص",
    S_S: "خاص",
    Days: "خاص",
    Hours: "خاص",
    Minutes: "خاص",
    Seconds: "خاص",
    Supplier_Details: "خاص",
    Store_Details: "خاص",
    Create: "خاص",
    Catgory_No_Product: "خاص",
    All_Quotes_Required: "خاص",
    One_Quote_Required: "خاص",
    Mada: "خاصhh",
    Visa: "خاصh",
    Mastercard: "خاصq",
    Total_Amount_Received: "خاص",
    Amount_Received_From: "خاص",
    Vat_Percentage: "خاص",
    Text: "خاص",
    Category_Heading: "خاص",
    Enter_Subheading: "خاص",
    Footer_Text: "خاص",
    Enter_Category_Heading: "خاص",
    Enter_product_count: "خاص",
    Enter_Category_SubHeading: "خاص",
    Enter_Heading_Network: "خاص",
    Enter_SubHeading_Network: "خاص",
    Enter_SubHeading_Community: "خاص",
    Enter_Heading_Image: "خاص",
    Enter_SubHeading_Image: "خاص",
    Alert: "خاص",
    Not_Found: "خاص",
    Page_Not_Found: "خاص",
    Sign_Up: "خاص",
    Confirm_Password: "خاص",
    Have_Account: "خاص",
    Email_Username: "خاص",
    Enter_Email_Username: "خاص",
    Not_Have_Account: "خاص",
    Type_Your_Message: "خاص",
    Category_English_Name: "خاص",
    Category_Ar_Name: "خاص",
    Category_Ur_Name: "خاص",
    Select_Category: "خاص",
    SEE_MORE: "خاص",
    Saved_Card_Details: "خاص",
    Add_New_Card_Detail: "خاص",
    Direct_Bank_Transfer: "خاص",
    No_Product_Found: "خاص",
    Bank_Transfer: "خاص",
    View_All_Blogs: "خاص",
    User: "خاص",
    Collection: "خاص",
    Read_More: "خاص",
    Blog_Details: "خاص",
    Share_Post: "خاص",
    Follow_Me: "خاص",
    Enter_Firstname: "خاص",
    Enter_Lastname: "خاص",
    Enter_Your_Email: "خاص",
    Enter_Your_Phone: "خاص",
    Enter_Message: "خاص",
    Close_Ticket: "خاص",
    Success: "خاص",
    Failed: "خاص",
    Rating_And_Review: "خاص",
    Reviews: "خاص",
    Bank_transfer: "خاص",
    completed_req: "This Purchase Request has been completed!",
    Add_Review_And_Rating: "خاص",
    Edit_Review_And_Rating: "خاص",
    Upload_Transfer_Receipt: "خاص",
    Transfer_Payment_Amount: "خاص",
    Order_Remain_Pending: "خاص",
    Successful_Payment: "خاص",
    Make_Transfer: "خاص",
    Vat_Tax: "خاص",
    Add_Card: "خاص",
    Add_Card_Details: "کارڈ کی تفصیلات شامل کریں",
    First_Name: "پہلا نام",
    Payment_Id: "خاص",
    Purchase_Request_Id: "خاص",
    Vat_Charges: "خاص",
    requested_amount: "خاص",
    No_Order_Found: "خاص",
    No_Category_Found: "خاص",
    Review_Rating: "خاص",
    Bank_Transfer_Request: "خاص",
    Please_verify_document: "خاص",
    Store_Company: "اسٹور کمپنی",
    Supplier_Company: "شركة المتجر",
    Created_By: "بنائی گئی",
    Store_Id: "اسٹور آئی ڈی",
    Go_with_new_card: "نئے کارڈ کے ساتھ جائیں",
    Payment_with_saved_card: "الدفع بالبطاقة المحفوظة",
    Get_In_Touch: "رابطے میں رہنا",
    Firstname: "پہلا نام",
    Lastname: "آخری نام",
    Message: "پیغام",
    Get_In_Touch_Details: "الحصول على تفاصيل الاتصال",
    Make_Address_Default: "جعل العنوان الافتراضي",
    you_want_to_logout: "آپ لاگ آؤٹ کرنا چاہتے ہیں۔",
    Assigned_Role: "تفویض کردہ کردار",
    Approx: ".تقریبا",
    Delete_Notification_Alert: "براہ کرم کم از کم 1 اطلاع منتخب کریں۔",
    Is_Active: "ایکٹو ہے۔",
    Edited: "(خاص)",
    Check_email: "اپنا ای میل چیک کریں",
    Please_Check_Email:
      "براہ کرم اپنے صارف نام سے وابستہ ای میل ایڈریس چیک کریں",
    Reset_Link: "اپنا پاس ورڈ ری سیٹ کرنے کے لنک کے لئے",
    Dont_receive_Email: "کوئی ای میل موصول نہیں ہوا؟",
    Resend_Email: "ای میل دوبارہ بھیجیں",
    back_to_sign_in: "سائن ان کرنے کے لئے واپس جائیں",
    Set_New_Password: "نیا پاس ورڈ سیٹ کریں",
    Your_New_Password:
      "آپ کا نیا پاس ورڈ پہلے استعمال شدہ پاس ورڈ سے مختلف ہونا چاہئے۔",
    Reset_Password: "پاس ورڈ ری سیٹ کریں",
    Tooltip_Refresh: "ریفریش کرنے کے لیے یہاں کلک کریں۔",
    Username_Error: "خاص",
    Email_Error: "خاص",
    Valid_Email: "خاص",
    Cr_Number_Error: "خاص",
    Cr_Alphanumeric: "خاص",
    Cr_Positive: "خاص",
    Cr_Length: "خاص",
    Tax_Number_Error: "خاص",
    Tax_Positive: "خاص",
    Tax_Length: "خاص",
    Comapny_Name_Error: "خاص",
    Phone_Error: "خاص",
    Phone_Number_Error: "خاص",
    Phone_Positive: "خاص",
    Phone_Length: "خاص",
    Iban_Number_Error: "خاص",
    Iban_Alphanumeric: "خاص",
    Iban_Positive: "خاص",
    Iban_Length: "خاص",
    Password_Error: "خاص",
    Password_Length: "خاص",
    Password_Creds: "خاص",
    Address_Data: "خاص",
    Address_Country: "خاص",
    Address_State: "خاص",
    Address_City: "خاص",
    Address_Postal_code: "خاص",
    Staff_Role: "خاص",
    Staff_Status: "خاص",
    Role_Error: "خاص",
    Role_Status: "خاص",
    Branch_Error: "خاص",
    Brach_User: "خاص",
    Reviews_Error: "خاص",
    Pur_Req_Expiry: "خاص",
    Pur_Req_Category: "خاص",
    Pur_Req_Product: "خاص",
    Pur_Req_Quantity: "خاص",
    Quantity_Positive: "خاص",
    Pur_Req_Price: "خاص",
    Price_Number: "خاص",
    Price_Positive: "خاص",
    Pur_Req_Description: "خاص",
    Bank_Transfer_Error: "خاص",
    Support_Subject: "خاص",
    Image_Size_Error: "خاص",
    Image_Format_Error: "خاص",
    Vat_Req_Error: "خاص",
    Vat_Positive_Error: "خاص",
    Image_Unsupported_Format: "خاص",
    Company_Status: "خاص",
    Image_Require_Error: "خاص",
    English_Name_Required: "خاص",
    Arabic_Name_Required: "خاص",
    Urdu_Name_Required: "خاص",
    Category_Appropriate_Error: "خاص",
    Measurement_Appropriate_Error: "خاص",
    Name_Error: "خاص",
    Overview_Error: "خاص",
    Descripton_Error: "خاص",
    Specification_Error: "خاص",
    Featured_Image_Error: "خاص",
    Unit_Name_Required_Error: "خاص",
    Unit_Short_Name_Required: "خاص",
    Banner_Heading_Required_Error: "خاص",
    Banner_Sub_Heading_Required_Error: "خاص",
    Button_Text_Required_Error: "خاص",
    Collection_Required_Error: "خاص",
    Footer_Icon_Required_Error: "خاص",
    Product_Count_Required_Error: "خاص",
    Category_Heading_Required_Error: "خاص",
    Category_Sub_Heading_Required_Error: "خاص",
    Category_Required_Error: "خاص",
    Community_Heading_Required_Error: "خاص",
    Community_Sub_Heading_Required_Error: "خاص",
    Title_Required_Error: "خاص",
    Purchase_Heading_Required_Error: "خاص",
    Purchase_Sub_Heading_Required_Error: "خاص",
    Image_Heading_Required_Error: "خاص",
    Feature_Heading_Required_Error: "خاص",
    Date_Required_Error: "خاص",
    Product_Title_Required_Error: "خاص",
    Product_Sub_Title_Required_Error: "خاص",
    Price_Required_Error: "خاص",
    Price_Number_Error: "خاص",
    Price_Positive_Error: "خاص",
    Rating_Required_Error: "خاص",
    Product_Button_Text_Required_Error: "خاص",
    Product_Button_Link_Required_Error: "خاص",
    Offer_Heading_Required_Error: "خاص",
    Offer_Sub_Heading_Required_Error: "خاص",
    Description_Required_Error: "خاص",
    Feature_Sub_Heading_Required_Error: "خاص",
    File_Size_Error: "خاص",
    Postal_Code_Alphanumeric: "خاص",
    Postal_Code_Number: "خاص",
    Postal_Code_Length: "خاص",
    Date_Error: "خاص",
    Date_Invalid_Error: "خاص",
    Date_Passed_Error: "خاص",
    Units_Error: "خاص",
    Unit_Number_Error: "خاص",
    Unit_Positive_Error: "خاص",
    Base_Price_Error: "خاص",
    Base_Price_Number_Error: "خاص",
    Base_Price_Positive_Error: "خاص",
    Image_Error: "خاص",
    Offered_Price_Error: "خاص",
    Offered_Price_Positive_Error: "خاص",
    Charges_Number_Error: "خاص",
    Charges_Positive_Error: "خاص",
    Subtotal_Error: "خاص",
    Review_Rating_Details: "خاص",
    StoreName: "خاص",
    Ratings: "خاص",
    Author_Name_Error: "خاص",
    Title_Name_Error: "خاص",
    Blog_Content_Error: "خاص",
    Slug_Error: "خاص",
    Cover_Image_Error: "خاص",
    Author_Image_Error: "خاص",
    Page_Banner_Error: "خاص",
    Page_Error: "خاص",
    Heading_Error: "خاص",
    Question_Error: "خاص",
    Answer_Error: "خاص",
    Page_Banner_Reuired_Error: "خاص",
    Collection_Value_Required_Error: "خاص",
    Collecion_Text_Required_Error: "خاص",
    Choose_Header_Required_Error: "خاص",
    Choose_Description_Required_Error: "خاص",
    Team_Position_Required_Error: "خاص",
    Team_Name_Required_Error: "خاص",
    Team_Header_Required_Error: "خاص",
    Team_Description_Required_Error: "خاص",
    Customer_Name_Required_Error: "خاص",
    Customer_Designation_Required_Error: "خاص",
    Heading_Required_Error: "خاص",
    Descripton_Required_Error: "خاص",
    Card_Descripton_Required_Error: "خاص",
    Page_Required_Error: "خاص",
    Page_Title_Required_Error: "خاص",
    Sub_Heading_Required_Error: "خاص",
    Sub_Title_Required_Error: "خاص",
    Firstname_Required_Error: "خاص",
    Lastname_Required_Error: "خاص",
    Message_Required_Error: "خاص",
    Confirm_Password_Error: "خاص",
    Confirm_Password_Req_Error: "خاص",
    Email_Username_Error: "خاص",
    Card_Name_Error: "خاص",
    Password_Updated_Toast: "پاسورڈ بہتری کے ساتھ کامیابی سے اپ ڈیٹ ہوا",
    User_Create_Toast: "صارف کامیابی سے بنایا گیا",
    Staff_Create_Toast: "اسٹاف ممبر کامیابی سے شامل ہوا",
    Create_Error_Toast: "صارف بنانے میں ناکامی",
    Update_Toast: "ذاتی ڈیٹا کامیابی سے اپ ڈیٹ ہوگئی",
    Delete_Error_Toast: "ڈیلیٹ اور ٹرانسفر ایک ہی صارف کے ساتھ ممکن نہیں ہے",
    Get_Toast: "صارف کامیابی سے حاصل ہوگیا",
    Get_All_Toast: "صارفانہ کامیابی سے حاصل ہوگیا",
    Unique_Error_Toast: "صارف پہلے ہی موجود ہے",
    Unique_Email_Error_Toast: "ای میل/صارف کا نام پہلے ہی موجود ہے",
    Unique_Username_Error_Toast: "صارف پہلے ہی اس یوزر نام پر موجود ہے",
    Not_Found_Toast: "صارف نہیں ملا",
    Not_Found_User_Toast: "صارف اس یوزر نام/ای میل پر موجود نہیں ہے",
    Catch_Response_Toast: "کچھ چیز گلٹ ہو گئی ہے!!",
    Login_Toast: "آپ نے کامیابی سے لاگ ان کیا ہے",
    Username_Or_Password_Does_Not_Match_Toast:
      "یوزر نام یا پاسورڈ میل نہیں کھاتا",
    Store_Exists_Toast:
      "کمپنی کے نام/سی آر نمبر کے لئے اسٹور مالک پہلے ہی موجود ہے",
    Supplier_Exists_Toast:
      "کمپنی کے نام/سی آر نمبر کے لئے سپلائر پہلے ہی موجود ہے",
    Mail_Sent_Toast:
      "میل کامیابی سے بھیجا گیا ہے۔ براہ کرم میل دیکھیں تاکہ آپ اسے ری سیٹ کر سکیں۔",
    Profile_Updated_Toast: "پروفائل کامیابی سے اپ ڈیٹ ہو گئی ہے",
    Default_Address_Updated_Toast: "ڈیفالٹ پتہ کامیابی سے اپ ڈیٹ ہو گیا ہے",
    Address_Deleted_Toast: "پتہ کامیابی سے حذف ہوا",
    Address_Delete_Failed_Toast: "پتہ حذف کرنے میں ناکامی",
    Address_Is_Default_Toast: "ڈیفالٹ پتہ حذف نہیں کیا جا سکتا",
    Address_Update_Failed_Taost: "پتہ اپ ڈیٹ کرنے میں ناکامی ہوئی",
    Profile_Update_Success_Toast: "پروفائل کامیابی سے اپ ڈیٹ ہو گئی ہے",
    Address_Fetched_Toast: "پتہ کامیابی سے حاصل ہوا",
    Address_Created_Toast: "پتہ کامیابی سے بنایا گیا",
    User_Deleted_Toast: "صارف کامیابی سے حذف ہوگیا",
    User_Assigned_To_Branch_Toast:
      "شاخ سے منسلک شدہ صارف کو حذف نہیں کیا جا سکتا",
    Wrong_User_Or_Password_Toast:
      "آپ جو ای میل استعمال کر رہے ہیں وہ ہمارے ساتھ رجسٹر ہوا ہوا نہیں ہے",
    User_Does_Not_Exists_Toast: "صارف کا یوزر نام/ای میل یہاں موجود نہیں ہے",
    Password_Does_Not_Match_Toast: "غلط پاسورڈ۔ براہ کرم دوبارہ کوشش کریں۔",
    Someone_Already_Registered_Toast:
      "کسی نے پہلے ہی اسی کمپنی/سی آر نمبر کے ساتھ رجسٹر کر لیا ہے",
    User_Inactive_Toast: "صارف غیر فعال ہے لہذا آپ لاگ ان نہیں ہو سکتے",
    Admin_Details_Fetched_Toast: "ایڈمن کی تفصیلات کامیابی سے حاصل ہوئیں",
    Store_Data_Updated_Toast: "اسٹور کی معلومات کامیابی سے اپ ڈیٹ ہو گئی ہیں!",
    Store_Data_Deleted_Toast: "استور کی معلومات کامیابی سے حذف ہو گئی ہیں!",
    Cms_Pages_Fetched_Toast: "سی ایم ایس صفحات کامیابی سے حاصل ہو گئے ہیں!",
    Cms_Specific_Page_Fetched_Toast: "سی ایم ایس صفحہ کامیابی سے حاصل ہوا ہے!",
    Cms_Page_Updated_Toast: "سی ایم ایس صفحہ کامیابی سے اپ ڈیٹ ہ",
    Cms_Blogs_Fetched_Toast: "بلاگز کامیابی سے حاصل ہوگئے ہیں!",
    Cms_Specific_Page_Updated_Toast: "خاص Cms صفحہ کامیابی سے اپ ڈیٹ ہوا ہے!",
    Cms_Unauthorize_Update_Request: "آپ کو اپ ڈیٹ کرنے کی اجازت نہیں ہے",
    Cms_Review_Fetched_Toast: "ریویو لسٹ کامیابی سے حاصل ہوگئی ہے!",
    Slug_Exists_Toast: "اسلگ پہلے ہی موجود ہے",
    Cms_Datasource_Count_Toast: "ڈیٹاسورس کاؤنٹ کامیابی سے حاصل ہوا ہے",
    Category_Fetched_Toast: "کیٹیگری کامیابی سے حاصل ہوگئی ہے",
    Error_Toast: "توثیق کے لئے ایک ٹوکن ضروری ہے",
    Wrong_Token_Issue_Toast: "براہ کرم ایڈمن سے رابطہ کریں",
    Token_Invalid_Toast: "ٹوکن میعاد ختم ہو گئا ہے!",
    Cms_Blog_Not_Found_Toast: "آپ کو اپ ڈیٹ کرنے کی اجازت نہیں ہے",
    Message_Sent_Toast: "آپ کا پیغام کامیابی سے بھیجا گیا ہے",
    Fetch_Data_Toast: "ٹچ ڈیٹا کامیابی سے حاصل ہوا ہے",
    Contact_Deleted_Toast: "رابطہ پیغام کامیابی سے حذف ہوا",
    Faq_Added_Toast: "Faq کامیابی سے شامل ہوگیا",
    Faq_Data_Toast: "Faq ڈیٹا کامیابی سے حاصل ہوا ہے",
    Faq_Fetched_Toast: "Faq کامیابی سے حاصل ہوا ہے",
    Faq_Updated_Toast: "Faq ڈیٹا کامیابی سے اپ ڈیٹ ہوا ہے",
    Faq_Deleted_Toast: "Faq کامیابی سے حذف ہوگیا",
    Unit_Added_Toast: "یونٹ آف میجرمنٹ کامیابی سے شامل ہوگیا",
    Unit_Already_Exist_Toast: "یونٹ آف میجرمنٹ پہلے ہی موجود ہے",
    All_Units_Fetched_Toast: "تمام یونٹس آف میجرمنٹ کامیابی سے حاصل ہو گئی ہیں",
    Unit_Fetched_Toast: "یونٹ آف میجرمنٹ کامیابی سے حاصل ہوا ہے",
    Unit_Unauthorize_Update_Request_Toast: "آپ کو اپ ڈیٹ کرنے کی اجازت نہیں ہے",
    Unit_Updated_Toast: "یونٹ آف میجرمنٹ کامیابی سے اپ ڈیٹ ہوا ہے",
    Unit_Deleted_Toast: "یونٹ آف میجرمنٹ کامیابی سے حذف ہوگیا",
    Unit_Already_Assigned_Toast:
      "اس یونٹ کا استعمال مختلف مصنوعات کے ساتھ ہو رہا ہے، لہذا آپ اسے حذف نہیں کر سکتے",
    Category_Added_Toast: "کیٹیگری کامیابی سے شامل ہوگئی",
    Category_Data_Toast: "کیٹیگری ڈیٹا کامیابی سے حاصل ہوگئی ہے",
    Category_Updated_Toast: "کیٹیگری کامیابی سے اپ ڈیٹ ہوگئی",
    Category_Deleted_Toast: "کیٹیگری کامیابی سے حذف ہوگئی",
    Category_Could_Not_Find_Toast: "کیٹیگری ڈیٹا بیان ہونے والی نہیں ہے",
    Category_Duplicated_Toast: "کیٹیگری کی ڈپلیکیشن کی اجازت نہیں ہے",
    Category_Already_Exist_Toast: "کیٹیگری پہلے ہی موجود ہے",
    Category_Wise_Product_Toast: "پروڈکٹ کامیابی سے حاصل ہوگئی ہے ",
    Update_Category_Inactive_Toast: "منتخب کردہ کیٹیگری فی الحال غیر ف",
    Category_Product_Inactive_Toast: "منتخب پروڈکٹ غیر فعال ہے",
    Category_Product_Deleted_Toast: "پروڈکٹ دستیاب نہیں ہے",
    Category_Can_Not_Deleted_Toast:
      "یہ کیٹیگری متعدد پروڈکٹس کے ساتھ استعمال ہو رہی ہے، لہذا آپ اسے حذف نہیں کر سکتے",
    Product_Added_Toast: "پروڈکٹ کامیابی سے شامل ہوگئی ہے",
    Product_Data_Toast: "پروڈکٹس ڈیٹا کامیابی سے حاصل ہوگیا ہے",
    Products_Fetched_Toast: "پروڈکٹس کامیابی سے حاصل ہوگئی ہیں",
    Products_Fetched_Failed_Toast: "پروڈکٹس کو حاصل کرنے میں ناکامی",
    Product_Updated_Toast: "پروڈکٹ کامیابی سے اپ ڈیٹ ہوگئی ہے",
    Products_Updated_Failed_Toast: "پروڈکٹ کو اپ ڈیٹ کرنے میں ناکامی",
    Product_Deleted_Toast: "پروڈکٹ کامیابی سے حذف ہوگئی ہے",
    Product_Deleted_Failed_Toast: "پروڈکٹ کو حذف کرنے میں ناکامی",
    Product_Could_Not_Find_Toast: "پروڈکٹ ڈیٹا بیس میں موجود نہیں ہے",
    Product_Duplicated_Toast: "پروڈکٹ کی نقل ممکن نہیں ہے",
    Product_Already_Exist_Toast:
      "پروڈکٹ پہلے ہی موجود ہے (مشابہ کیٹیگری/اندازا)",
    Products_Add_Failed_Toast: "پروڈکٹ شامل کرنے میں ناکامی",
    Product_Category_Inactive_Toast: "منتخب کیٹیگری فی الحال غیر فعال ہے",

    Product_Measurement_Inactive_Taost: "منتخب میپارمنٹ فی الحال غیر فعال ہے",
    Single_Supplier_Toast: "سپلائر کامیابی سے حاصل ہوگیا",
    Failed_Single_Supplier_Toast: "سپلائر حاصل کرنے میں ناکام ہوگیا",
    Unauthorize_Delete_Request_Taost: "آپ کو حذف کرنے کی اجازت نہیں ہے",
    Supplier_Deleted_Toast: "سپلائر کامیابی سے حذف ہوگیا",
    Supplier_Updated_Toast: "سپلائر کامیابی سے اپ ڈیٹ ہوا",
    Supplier_Data_Fetched_Toast: "سپلائر ڈیٹا کامیابی سے حاصل ہوگیا",
    Supplier_Product_Already_Exisit_Toast:
      "پروڈکٹ میپارمنٹ اور مدت کے ساتھ پہلے ہی موجود ہے",
    Supplier_Product_Fetched_Toast: "پروڈکٹس کامیابی سے حاصل ہوگئی ہیں",
    Supplier_Product_Added_Toast: "پروڈکٹ کامیابی سے شامل ہوگئی",
    Cat_Wise_Product_Fetched_Toast:
      "کیٹیگری وائز پروڈکٹس کامیابی سے حاصل ہوگئی ہیں",
    Supplier_Product_Deleted_Toast: "پروڈکٹ کامیابی سے حذف ہوگیا",
    Single_Supplier_Product_Fetched_Toast: "سنگل پروڈکٹ کامیابی سے حاصل ہوگیا",
    Supplier_Product_Updated_Toast: "پروڈکٹ کامیابی سے اپ ڈیٹ ہوگیا",
    Supplier_Product_Update_Failed_Toast: "پروڈکٹ کا اپ ڈیٹ ناکام ہوگیا",
    Suppier_Product_Fetched_Failed_Toast: "پروڈکٹس حاصل کرنے میں ناکام ہوگئے",
    Supplier_Product_Not_Found_Toast: "سپلائر کا کوئی پروڈکٹ موجود نہیں ہے",
    Request_Created_Toast: "خریداری کی درخواست کامیابی سے بھیجی گئی!",
    Purchase_Request_Fetched_Toast:
      "خریداری کی درخواستیں کامیابی سے حاصل ہوگئی ہیں",
    Purchase_Request_Not_Fetched_Toast:
      "اسٹور مالک کے پاس خریداری کی درخواستیں نہیں ہیں",
    Purchase_Request_Deleted_Toast: "خریداری کی درخواست کامیابی سے حذف ہوگئی",
    Purchase_Request_Delete_Failed_Toast:
      "خریداری کی درخواست کو حذف کرنے میں ناکامی",
    Purchase_Request_Status_Update_Failed_Toast:
      "خریداری کی درخواست کی حالت کا اپ ڈیٹ کرنے میں ناکامی",
    Purchase_Request_Status_Updated_Toast:
      "خریداری کی درخواست کی حالت کامیابی سے اپ ڈیٹ ہوگئی",
    Purchase_Request_Quote_Deleted_Toast: "کوٹ کامیابی سے حذف ہوگیا",
    Purchase_Request_Quote_Rejected_Toast: "کوٹ کامیابی سے مسترد ہوگیا",
    Purchase_Request_Quote_Not_Found_Toast:
      "خریداری کی درخواست کوٹ نہیں مل سکا",
    Purchase_Request_Fetch_Failed_Toast:
      "خریداری کی درخواست حاصل کرنے میں ناکام ہوگئی",
    Purchase_Request_Not_Found_Toast: "خریداری کی درخواست نہیں ملی",
    Purchase_Request_Quote_Deleted_Failed_Toast:
      "خریداری کی درخواست کوٹ کو حذف کرنے میں ناکامی",
    Supplier_Quote_Not_Found_Toast: "کوئی سپلائر کوٹس نہیں ملے",
    Purchase_Request_Quote_Accepted_Toast: "کوٹ قبول ہوگیا ہے",
    Purchase_Request_Created_Toast:
      "خریداری کی درخواست کی تفصیلات کامیابی سے بنائی گئی ہیں",
    Purchase_Request_Updated_Toast:
      "خریداری کی درخواست کی تفصیلات کامیابی سے اپ ڈیٹ ہوگئی ہیں",
    Quotes_Created_Toast: "کوٹ کامیابی سے بنایا گیا ہے!",
    Quotes_Updated_Toast: "کوٹ کامیابی سے اپ ڈیٹ ہوگیا ہے!",
    Product_Fetched_Toast: "پروڈکٹ کامیابی سے حاصل ہوگئی ہے!",
    Quote_Does_Not_Found_Toast: "کوٹ نہیں ملا",
    Permission_Fetched_Toast: "اجازتیں کامیابی سے حاصل ہوگئی ہیں!",
    Order_Created_Toast: "آرڈر کامیابی سے بنایا گیا ہے",
    Order_Updated_Toast: "آرڈر کامیابی سے اپ ڈیٹ ہوگیا ہے",
    Order_Deleted_Toast: "آرڈر کامیابی سے حذف ہوگیا ہے",
    Order_Fetched_Toast: "آرڈر کامیابی سے حاصل ہوگیا ہے",
    Support_Ticket_Created_Toast: "سپورٹ ٹکٹ کامیابی سے بنایا گیا ہے",
    Support_Ticket_Updated_Toast: "سپورٹ ٹکٹ کامیابی سے اپ ڈیٹ ہوگیا ہے",
    Support_Ticket_Deleted: "سپورٹ ٹکٹ کامیابی سے حذف ہوگیا ہے",
    Support_Ticket_Fetched_Toast: "سپورٹ ٹکٹ کامیابی سے حاصل ہوگئی ہیں",
    Support_Ticket_Closed_Toast: "سپورٹ ٹکٹ کامیابی سے بند ہوگیا ہے",
    Support_Ticket_Send_Reply_Toast: "ٹکٹ کا جواب کامیابی سے بھیجا گیا ہے",
    Chat_Created_Toast: "چیٹ کامیابی سے بنائی گئی ہے",
    Chat_Fetched_Toast: "چیٹ کامیابی سے حاصل ہوگئی ہے",
    PaymentCharge_Created_Toast: "ادائیگی چارج کامیابی سے بنایا گیا ہے",
    PaymentCharge_Updated_Toast: "ادائیگی چارج کامیابی سے اپ ڈیٹ ہوگیا ہے",
    PaymentCharge_Deleted_Toast: "ادائیگی چارج کامیابی سے حذف ہوگیا ہے",
    PaymentCharge_Fetched_Toast: "ادائیگی چارج کامیابی سے حاصل ہوگیا ہے",
    Payement_Failed_Toast: "ادائیگی کا درخواست حاصل کرنے میں ناکام ہوگئی",
    Payment_Details_Not_Found_Toast: "ادائیگی چارج نہیں مل سکا",
    General_Count_Report_Fetch_Toast: "عام ڈیٹا کامیابی سے حاصل ہوگا",
    Store_Not_Exisit_Toast: "اسٹور موجود نہیں ہے",
    Store_Data_Fetched_Toast: "ڈیٹا کامیابی سے حاصل ہوگا",
    Reviews_Fetched_Toast: "تشہیر اور ریٹنگ کامیابی سے حاصل ہوگئی ہیں",
    Reviews_Added_Toast: "تشہیر اور ریٹنگ کامیابی سے شامل ہوگئی ہے",
    Reviews_Updated_Toast: "تشہیر اور ریٹنگ کامیابی سے اپ ڈیٹ ہوگئی ہے",
    Role_Added_Toast: "رول کامیابی سے بنایا گیا ہے",
    Role_Updated_Toast: "رول کامیابی سے اپ ڈیٹ ہوگیا ہے",
    Staff_Member_Added_Toast: "اسٹاف ممبر کامیابی سے بنایا گیا ہے",
    Staff_Member_Updated_Toast: "اسٹاف ممبر کامیابی سے اپ ڈیٹ ہوگیا ہے",
    Staff_Member_Deleted_Toast: "اسٹاف ممبر کامیابی سے حذف ہوگیا ہے",
    Role_Deleted_Toast: "رول کامیابی سے حذف ہوگیا ہے",
    Role_Already_Exists_Toast: "رول پہلے ہی موجود ہے",
    Role_Already_Assigned_Toast:
      "رول غیر فعال نہیں ہوسکتا کیونکہ یہ اسٹاف ممبر کو دینا ہے",
    IBAN_Number_Toast: "براہ کرم اپنا IBAN نمبر درج کریں پہلے ہی",
    Card_Added_Toast: "کارڈ کامیابی سے محفوظ ہوگیا ہے",
    Card_Fetched_Toast: "کارڈس کامیابی سے حاصل ہوگئے ہیں",
    Card_Updated_Toast: "کارڈ کامی",
    Card_Deleted_Toast: "کارڈ کامیابی سے حذف ہوگیا ہے",
    Unique_Card_Error_Toast: "کارڈ پہلے ہی موجود ہے",
    Card_Does_Not_Belongs_To_Customer_Toast: "کارڈ کسی گاہک کا نہیں ہے",
    Failed_Card_Delete_Toast: "کارڈ کو حذف کرنے میں ناکامی",
    Notification_Deleted_Toast: "اطلاعیہ کامیابی سے حذف ہوگیا ہے",
    Product_Cannot_Be_Repeated_Toast:
      "لا يمكن تكرار المنتجات بنفس الاسم / القياسالأردية",
    Cms_Updated_Toast: "ڈیٹا کو کامیابی سے اپ ڈیٹ کیا گیا ہے ",
    Cms_Deleted_Toast: "ڈیٹا کو کامیابی سے اپ ڈیٹ کیا گیا ہے",
    Cms_Created_Toast: "ڈیٹا کو کامیابی سے اپ ڈیٹ کیا گیا ہے",
    Company_Inactive_Toast: "ڈیٹا کو کامیابی سے اپ ڈیٹ کیا گیا ہے",
    Branch_Fetched_Toast: "خاص",
    Branch_Created_Toast: "خاص",
    Branch_Updated_Toast: "خاص",
    Branch_Deleted_Toast: "خاص",
    Vat_Upadated_Toast: "VAT چارجز کو کامیابی سے اپ ڈیٹ کیا گیا ہے",
    Vat_Get_Toast: "VAT چارجز کامیابی سے حاصل ہو گئے ہیں",
    Correct_Mail_Toast: "براہ کرم ایک درست ای میل درج کریں",
    Transaction_Fetched_Toast:
      "ٹرانزیکشن کی تفصیلات کامیابی سے حاصل ہو گئی ہیں",
    Weekly_Graph_Detail_Toast: "ہفتے کی گراف تفصیلات",
    Category_Graph_Detail_Toast: "کیٹیگری گراف تفصیلات",
    Mail_Send_Toast: "میل کامیابی سے بھیجا گیا ہے",
    Success_Toast: "کامیابی سے حاصل ہو گیا!",
    Blog_Image_Upload_Toast: "بلاگ کی تصویر کامیابی سے اپ لوڈ ہو گئی ہے",
    Purchase_Request_Detail_Not_Found_Toast:
      "خریداری کی درخواست کی تفصیلات نہیں ملیں",
    Order_Added_Toast: "آرڈر کامیابی سے شامل ہو گیا ہے",
    Data_Fetched_Toast: "ڈیٹا کامیابی سے حاصل ہو گیا ہے",
    Unable_To_Delete_User_Toast:
      "اس رول کو دینے والے صارفین کو حذف کرنا ممکن نہیں ہے",
    User_Exist_Toast: "صارف پہلے ہی موجود ہے",
    Record_Exist_Toast: "یہ ڈیٹا ہمارے ریکارڈز میں پہلے ہی موجود ہے",
    Not_Find_Product_Toast: "کوئی مصنوعہ نہیں مل سکا",
    Enter_New_Password: "بنجاح",
    ReEnter_New_Password: "بنجاح",
    Password_And_Security: "بنجاح",
    Latest_Products: "أحدث المنتجات",
    UOM: "بنجاح",
    My_Profile: "میرا پروفائل",
    Manage_Password: "پاس ورڈ کا انتظام کریں",
    Bank_Transfer_Initiated_Alert:
      "بینک ٹرانسفر شروع کر دیا گیا ہے اور اب ایڈمن سے منظوری زیر التواء ہے۔ آپ کی سمجھ کے لیے آپ کا شکریہ۔",
    Edit_Ranking: "درجہ بندی میں ترمیم کریں۔",
    Add_Ranking: "درجہ بندی شامل کریں۔",
    Bank_Transfer_Instruction: "بینک ٹرانسفر کی ہدایات",
    Branch_Delete_Text:
      "کیا آپ واقعی اس ریکارڈ کو حذف کرنا چاہتے ہیں؟ برانچ استعمال کرنے والے کو اس برانچ میں غیر دستخط کر دیا جائے گا",
    Select_Card: "کارڈ منتخب کریں",
    Enter_Offer_Price: "پیشکش کی قیمت درج کریں",
    Quantity_Error_Message: "مقدار 0 سے زیادہ ہونی چاہیے۔",
    Quantity_Number_Error: "مقدار کو عدد ہونا چاہئے",
    Quantity_Positive_Error: "مقدار مثبت ہونا چاہئے",
    Requested_Price_Required_Error: "درخواست شدہ قیمت درخواست کردہ ہے",
    Requested_Price_Number_Error: "درخواست شدہ قیمت کو عدد ہونا چاہئے",
    Requested_Price_Positive_Error: "درخواست شدہ قیمت مثبت ہونا چاہئے",
    Quotes_Accepted_Required_Eror: "قیمتیں قبول کرنا لازمی ہے",
    Quantity_Required_Error: "مقدار درخواست کی جاتی ہے",
    Payment_Mode: "ادائیگی کا طریقہ",
    Approve: "منظور کرنا",
    Decline: "کمی",
    Approved: "منظورشدہ",
    Thank_You: "شکریہ",
    Received_Your_Message:
      "ہم نے آپ کا پیغام موصول کیا ہے اور 24 گھنٹوں کے اندر جواب دیا جائے گا۔ اس دوران، یہ یقینی بنائیں کہ آپ ہمیں سوشل میڈیا پر فالو کر رہے ہیں!",
    Low_To_High: "درجہ بندی شامل کریں۔",
    High_To_Low: "درجہ بندی شامل کریں۔",
    Recently_Added: "درجہ بندی شامل کریں۔",
    All_Prices: "درجہ بندی شامل کریں۔",
    Sort_By: "کریں",
    Price_Range: "درجہ بندی شامل کریں",
    Verification_Status: "بندی",
    Activation_Status: "بندی",
    Passed_Date: "بندی",
    Role_First_Error: "براہ کرم پہلے کردار بنائیں",
    See_All_Notification: "تمام نوٹیفکیشن دیکھیں",
    Offer_Price_Message: "پیشکش کی قیمت بنیادی قیمت سے کم نہیں ہونی چاہیے۔",
    Branch_Exists_Toast: "دیکھیں",
    Store_Already_Exists_Toast: "دیکھیں",
    Choose_Category: "زمرہ منتخب کریں",
    Choose_Status: "حالت منتخب کریں",
    Choose_Country: "ملک منتخب کریں",
    Choose_State: "صوبہ منتخب کریں",
    Choose_City: "شہر منتخب کریں",
    Choose_Role: "کردار منتخب کریں",
    Select_Product: "پروڈکٹ منتخب کریں",
    Same_Category_Error: "اسی زمرے کو دہرایا نہیں جا سکتا",
    Enter_Vat_Charges_Error: "وٹ چارجز درج کریں۔",
    Total_Suppliers: "اختر",
    Total_Store_Owners: "اختر",
    Total_Products: "اختر",
    Total_Categories: "اختر",
    Total_Transactions: "اختر",
    Total_BankTransfers: "اختر",
    Reject_Msg: "کیا آپ واقعی اس اقتباس کو رد کرنا چاہتے ہیں؟",
    Reject: "رد کرنا",
    Enter_Current_Password: "يرفض",
    Future_Date: "مستقبل کی تاریخ درست نہیں ڈالی جا سکتی ہے",
    Enter_Payment_Id: "ادائیگی کا شناختی نمبر درج کریں",
    Orders_By_Date: "تاریخ کے بعد کے آرڈرز",
    Product_Details: "يرفض",
    View_OrderDetail: "يرفض",
    Less_than_Expiry_Date: "ختم ہونے کے قریب تر",
    Enter_Request_ID: "درخواست کا شناختی نمبر درج کریں",
    Purchase_Request_After_Date: "درخواست",
    Search_Filter: "درخواست",
    Apply: "لگائیں",
    Reset: "ری سیٹ",
    Enter_Order_Id: "سیٹ",
    Enter_Amount: "يرفض",
    Enter_Ticket_Id: "سیٹ",
    Tickets_By_Date: "شناختی",
    Enter_Purchase_Req_Id: "شناختی",
    Enter_Company_Name: "ہونے",
    Category_Name: "شناختی",
    Enter_Supplier_Company_Name: "إعادة",
    Enter_Store_Company_Name: "إعادة",
    Product_Title: "إعادة",
    Grand_Total: "مجموعی عدد",
    Download_Quotation: "اقتباس ڈاؤن لوڈ کریں۔",
    Enter_Quantity: "مقدار درج کریں۔",
    Has_Offers: "الاقتباس",
    Enter_Charges: "مجموعی",
    tooltip_another_req: "الاقتباس",
    tooltip_req: "الاقتباس",
    Reason_for_Cancel_Order: "الاقتباس",
    Enter_Reason: "الاقتباس",
    Replacement_Of_Order_Product: "اختر",
    Replace_Product: "اختر",
    Regular_Orders: "اختر",
    Replacement_Orders: "اختر",
    Reason_Error: "اختر",
    Send_Replacement_Request: "اختر",
    Product_status: `مصنوعات کی حیثیت`,
    Replacement_Status: `تبدیلی کی حیثیت`,
    Can_not_select_same_product: "حالة",
    Send_Refund_Request: "حالة",
    Refund_Of_Order_Product: "حالة",
    Refund_Orders: "مصنوعات",
    Refund_order_detail: "اختر",
    Replacement_order_detail: "اختر",
    Select_All: "اختر",
    Supplier_Status: "اختر",
    Admin_Status: "اختر",
    cancel_tooltip: "اختر",
    delivered_tooltip: "اختر",
    order_tooltip: "اختر",
    Payment_Assurance: "اختر",
    Refundable_Amount: "اختر",
    Refund_Order_Id: "اختر",
    Refund_Order_Details: "رقم کی واپسی کے آرڈر کی تفصیلات",
    Refund_Status: "رقم کی واپسی کی حیثیت",
    Accepted_for_refund: "یہ آرڈر ریفنڈ کے لیے قبول کر لیا گیا ہے۔",
    Pending_for_refund: "یہ آرڈر ریفنڈ کے لیے زیر التوا ہے۔",
    Rejected_for_refund: "یہ آرڈر ریفنڈ کے لیے مسترد کر دیا گیا ہے۔",
    Replacement_Accepted_Upto: "تک کی تبدیلی قبول کر لی گئی۔",
    Replace_Days_Updated: "اپ ڈیٹ کردہ دنوں کو تبدیل کریں۔",
    Admin_Refund_Status: "ایڈمن ریفنڈ اسٹیٹس",
    Supplier_Refund_Status: "سپلائر کی رقم کی واپسی کی حیثیت",
    Replacement_Order_Details: "آرڈر کی تبدیلی کی درخواست کی تفصیلات",
    Replacement_Order_Tracking: "آرڈر کی تبدیلی سے باخبر رہنا",
    Replacemnt_Req_Error: "بدلہ درخواست میں خرابی",
    Refundable_Req_Error: "واپسی درخواست میں خرابی",
    Replacemnt_Positive_Error:
      "بدلہ قبول شدہ دنوں کو مثبت عدد صحیح ہونا چاہئے۔",
    Refundable_Positive_Error:
      "واپسی قبول شدہ دنوں کو مثبت عدد صحیح ہونا چاہئے۔",
    Refund_Accepted_Upto: "تک کی رقم کی واپسی قبول کی گئی۔",
    Refund_Order_Summary: "رقم کی واپسی کے آرڈر کا خلاصہ",
    Tooltip_order_refunded: "درخواست کردہ مصنوعات کو واپس کر دیا گیا ہے۔",
    ord_commission: "آرڈ کمیشن",
    bank_commission: "بینک کمیشن",
    Enter_Ord_Commission: "آرڈ کمیشن درکار ہے",
    Enter_Bank_Commission: "بینک کمیشن درکار ہے",
    Ord_Commission: "آرڈ کمیشن درکار ہے",
    Bank_Commission: "بینک کمیشن درکار ہے",
    Bank_Commission_Number: "بینک کمیشن کونمبر ہونا چاہئے",
    Ord_Commission_Number: "آرڈ کمیشن کونمبر ہونا چاہئے",
    Admin_Refund: "ایڈمن ریفنڈ",
    Finance_Assurance: "استرداد",
    Finance_Info: "استرداد",
    View_Pdf: "پی ڈی ایف دیکھیں",
    View_Excel: "ایکسل دیکھیں",
    Order_Pdf: "اكسل",
    Order_Excel: "اكسل",
    Choose_Branch: "شاخہ منتخب کریں",
    Select_Branch_for_Shipping: "شحن کے لئے شاخہ منتخب کریں",
    Purchase_for_branch: "شاخہ کے لئے خرید",
    associated_delivery_address: "متعلقہ ڈیلیوری کا پتہ",
    Default_Bank_Commmission_Updated: "ڈیفالٹ بینک کمیشن کو اپ ڈیٹ کر دیا گیا۔",
    Default_Ord_Commmission_Updated: "ڈیفالٹ آرڈر کمیشن کو اپ ڈیٹ کر دیا گیا۔",
    go_to_bank_transfer: "بینک ٹرانسفر پر جائیں۔",
    Charges: "چارجز",
    General_Settings: "عام ترتیبات",
    Orders_After_Date: "تاریخ کے بعد آرڈرز",
    Role_Error_ar: "عربی کے لیے کردار کا نام درکار ہے۔",
    Role_Error_ur: "اردو کے لیے کردار کا نام درکار ہے۔",
    Role_Name_Arabic:"اسم الدور عربي",
    Role_Name_Urdu:"کردار کا نام عربی"
  },
};
