export const PUBLIC_NAVIGATION = {
    login: "login",
    signup: "signup",
    forgotPassword: "forgot-password",
    checkEmail: "check-email",
    passwordSuccess: "password-success",
    resetPassword: "reset-password/:token",
    home: "",
    about: "about-us",
    contactUs: "contact-us",
    blog: "blog",
    blogDetail: "blog/:uuid",
    adminLogin: "admin",
    error: "error",
    adminResetPassword: "admin/reset-password/:token",
    adminForgotPassword: "admin/forgot-password",
    notFound: "/page-not-found",
    adminCheckYourMail: "admin/check-email",
}

export const USER_NAVIGATION = {
    dashboard: "dashboard",
    manageProfile: "manage-profile",
    products: "products",
    supplierAddProduct: "products/add",
    supplierEditProduct: "products/edit/:productId",
    storeProductListing: "/product/listing",
    categoryProductListing: "/product/all-products",
    checkoutProcess: "/checkout-process/:mergeId",
    storeProductDetail: "/product/details/:mergeId",
    storePurchaseRequestDetail: "/purchase-request",
    storePurchaseRequestAdd: "/purchase-request/add",
    storeCategoryListing: "/product/categoryList",
    storePurchaseRequestView: "/purchase-request/view/:purchaseReqId",
    supplierPurchaseRequestListing: "/purchase-request",
    supplierPurchaseRequestView:
        "/purchase-request/view/:selectedTab/:requestId",
    Staff: "/staff",
    StaffRoles: "/roles",
    AddRoles: "/roles/add",
    EditRoles: "/roles/add/:roleId",
    Branch: "/branches",
    storeSupportTicket: "/support-ticket",
    storeSupportTicketView: "/support-ticket/view/:ticketId",
    supplierOrderDetails: "/orders",
    supplierViewOrderDetails: "/orders/view/:orderId",
    supplierReplaceOrderDetail: "/replacement-orders",
    supplierReplaceViewOrderDetails: "/replace-orders/view/:orderId",
    supplierSupportTicket: "/support-ticket",
    supplierSupportTicketView: "/support-ticket/view/:ticketId",
    storeChat: "/chat",
    supplierChat: "/chat",
    storePurchaseRequestEdit: "/purchase-request/edit/:purchaseReqId",
    storPurchaseHistoryList: "/purchase-history",
    storeOrderReplacement: "/replacement-orders",
    storeOrederReplacement: "/replacement-orders/:orderId",
    storPurchaseHistoryOrderDetail: "/purchase-history/:orderId",
    storeNotification: "/notification",
    supplierNotification: "/notification",
    memberNotification: "/notification",
    viewParticularChat: "/chat/:chatId",
    bankTransferOrder: "bank-transfer-order",
    ViewBankTransferOrder: "bank-transfer-order/:orderId",
    ReviewRating: "/ratings",
    ViewReviewRating: "/ratings/:ratingId",
    savedCard: "/saved-card",
    storeOrderRefund: "/refund-orders",
    storeViewOrderRefund: "/refund-orders/:orderId",
    supplierOrderRefund: "/refund-orders",
    supplierViewOrderRefund: "/refund-orders/view/:orderId",
    supplierFinance: "/finance",
    supplierFinancePdfview:"/finance/pdfView/:financeId",
    supplierFinanceExcelview:"/finance/excelView/:financeId",
}

export const ADMIN_NAVIGATION = {
    adminDashboard: "admin/admin-dashboard",
    adminStore: "admin/store",
    adminSupplier: "admin/supplier",
    adminSupplierView: "admin/supplier/:mode/:supplierId",
    adminSupplierEdit: "admin/supplier/:mode/:supplierId",
    adminCMS: "admin/cms-pages",
    adminManageProfile: "admin/manage-profile",
    adminCMSView: "admin/cms-pages/:page",
    adminCMSReviewCardList: "admin/cms-pages/review-list",
    adminAddCMSReviewCard: "admin/cms-pages/review-list/add",
    adminEditCMSReviewCard: "admin/cms-pages/review/edit/:reviewId",
    adminCMSBlogListing: "admin/cms-pages/blog-list",
    adminCMSBlogCreate: "admin/cms-pages/blog-create",
    adminCMSBlogEdit: "admin/cms-pages/blog-edit/:blogId",
    adminCMSFaqCreate: "admin/cms-pages/faq-create",
    adminCMSFaqEdit: "admin/cms-pages/faq-edit/:faqId",
    adminCMSFaqListing: "admin/cms-pages/faq-list",
    adminStoreEdit: "admin/store/:mode/:storeId",
    adminStoreView: "admin/store/:mode/:storeId",
    adminCategories: "admin/categories",
    adminEditCategory: "admin/categories/edit-category/:categoryId",
    adminViewCategory: "admin/categories/view-category/:categoryId",
    adminAddCategory: "admin/categories/add-category",
    adminUnitOfMeasurement: "admin/measurement",
    adminAddUnitOfMeasurement: "admin/measurement/add",
    adminEditUnitOfMeasurement: "admin/measurement/edit/:unitId",
    adminProducts: "admin/products",
    adminAddProducts: "admin/products/add-product",
    adminEditProducts: "admin/products/edit-product/:productId",
    adminViewProducts: "admin/products/view-product/:productId",
    adminSupportTicket: "admin/support-ticket",
    adminSupportTicketView: "admin/support-ticket/view/:ticketId",
    adminOrders: "admin/orders",
    adminRefundOrders: "admin/refund-orders",
    adminOrderView: "admin/orders/view-order/:orderId",
    adminRefundOrderView: "admin/refund-orders/view-order/:orderId",
    adminNotification: "admin/notification",
    adminPayment: "admin/payment",
    adminBankTransfer: "admin/bank-transfer",
    adminBankTransferDetails: "admin/bank-transfer/view/:orderId",
    adminFinance: "admin/finance",
    adminFinanceView:"admin/finance/view/:supplierId",
    adminGetInTouch: "admin/get-in-touch",
    managePassword: "admin/manage-password",
    adminFinancePdfview:"/admin/finance/pdfView/:financeId",
    adminFinanceExcelview:"/admin/finance/excelView/:financeId",
    adminSettings:"/admin/settings",
}
