import "../../assets/css/style.css";
const GlobalLoader = () => {
  return (
    <div className="flex justify-center w-full h-[100vh]">
      <svg
        className="svg-outline-wrap"
        viewBox="0 0 94 33"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16.11 32.175C13.17 32.175 10.47 31.485 8.01 30.105C5.55 28.725 3.6 26.82 2.16 24.39C0.72 21.93 0 19.155 0 16.065C0 13.005 0.72 10.26 2.16 7.83C3.6 5.37 5.55 3.45001 8.01 2.07001C10.47 0.690007 13.17 0 16.11 0C19.08 0 21.78 0.690007 24.21 2.07001C26.67 3.45001 28.605 5.37 30.015 7.83C31.455 10.26 32.175 13.005 32.175 16.065C32.175 19.155 31.455 21.93 30.015 24.39C28.605 26.82 26.67 28.725 24.21 30.105C21.75 31.485 19.05 32.175 16.11 32.175ZM16.11 26.55C18 26.55 19.665 26.13 21.105 25.29C22.545 24.42 23.67 23.19 24.48 21.6C25.29 20.01 25.695 18.165 25.695 16.065C25.695 13.965 25.29 12.135 24.48 10.575C23.67 8.985 22.545 7.77001 21.105 6.93001C19.665 6.09001 18 5.67 16.11 5.67C14.22 5.67 12.54 6.09001 11.07 6.93001C9.63 7.77001 8.505 8.985 7.695 10.575C6.885 12.135 6.48 13.965 6.48 16.065C6.48 18.165 6.885 20.01 7.695 21.6C8.505 23.19 9.63 24.42 11.07 25.29C12.54 26.13 14.22 26.55 16.11 26.55ZM53.062 31.86L46.132 19.62H43.162V31.86H36.862V0.449997H48.652C51.082 0.449997 53.152 0.885005 54.862 1.755C56.572 2.595 57.847 3.75 58.687 5.22C59.557 6.66 59.992 8.28 59.992 10.08C59.992 12.15 59.392 14.025 58.192 15.705C56.992 17.355 55.207 18.495 52.837 19.125L60.352 31.86H53.062ZM43.162 14.895H48.427C50.137 14.895 51.412 14.49 52.252 13.68C53.092 12.84 53.512 11.685 53.512 10.215C53.512 8.775 53.092 7.66499 52.252 6.88499C51.412 6.07499 50.137 5.67 48.427 5.67H43.162V14.895ZM76.67 0.449997C79.97 0.449997 82.865 1.09499 85.355 2.38499C87.875 3.67499 89.81 5.52 91.16 7.92C92.54 10.29 93.23 13.05 93.23 16.2C93.23 19.35 92.54 22.11 91.16 24.48C89.81 26.82 87.875 28.635 85.355 29.925C82.865 31.215 79.97 31.86 76.67 31.86H65.69V0.449997H76.67ZM76.445 26.505C79.745 26.505 82.295 25.605 84.095 23.805C85.895 22.005 86.795 19.47 86.795 16.2C86.795 12.93 85.895 10.38 84.095 8.55C82.295 6.69 79.745 5.75999 76.445 5.75999H71.99V26.505H76.445Z"
          className="svg-outline"
        />
      </svg>
    </div>
  );
};
export default GlobalLoader;
