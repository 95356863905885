import { SidebarType } from "../types/generalTypes";

export const memeberSidebarDetails: SidebarType[] = [
  {
    name: "Dashboard",
    icon: "icon-dashboard",
    path: "/dashboard",
  },
  {
    name: "Manage Profile",
    icon: "icon-manage-profile",
    path: "/manage-profile",
  },

  {
    name: "Notification",
    icon: "icon-notification",
    path: "/notification",
  },
];

